.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;

	width: 100%;

	border-bottom: 1px solid $color-grey-2;

	box-shadow: 0 4px 16px rgba($color-black, 0.4);

	background-color: $color-grey-dark;
}

.header__container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.header__logo-block {
	align-self: flex-start;
	flex-shrink: 0;
	text-decoration: none;
	margin-right: 58px;
	user-select: none;

	height: 79px;
	display: flex;
	& picture {
		width: 100%;
		height: 100%;
	}

	& img,
    & svg {
		max-width: 100%;
		height: 100%;
		width: auto;
		object-fit: contain;
		-webkit-transform: translate3d(0,0,0);
	}

	@include mediaMax($lg) {
		height: 60px;
		margin-left: -10px;
		margin-right: 32px;
	}

	@include mediaMax($md) {
		height: 52px;
		margin-right: 14px;
	}
	@include mediaMax($sm) {
		height: 39px;
		margin-right: 15px;
		margin-left: 1px;
		overflow: hidden;

		& img,
		& svg {
			max-width: none;
			height: 50px;
			position: relative;
			top: -6px;
		}
	}
}

.apple {
	.header__logo-block {

		& .apple-logo {
			display: block;
		}
	}
}

.header__menu {
	width: 100%;

	display: flex;
	justify-content: flex-start;

	@include mediaMax($sm) {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 100;

		width: 260px;
		max-width: 100%;
		padding-top: 54px;
		padding-bottom: 22px;

		flex-direction: column;
		justify-content: flex-start;

		background-color: $color-grey-dark;
		box-shadow: 0 4px 16px $color-black;

		-ms-touch-action: none;
		touch-action: none;
		-webkit-overflow-scrolling: touch;

		transform: translateX(100%);
		transition: transform 0.3s ease-in-out;
	}
}

.header__menu-wrapper {
	width: 100%;

	display: flex;
	justify-content: space-between;

	@include mediaMax($sm) {
		flex-direction: column;
		justify-content: flex-start;
		margin-bottom: 20px;

		height: 100%;
		overflow-y: auto;
	}
}

.header__nav {
	flex-shrink: 1;
}

.header__nav-list {
	@include list-reset();
	margin: 0 -10px;

	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	@include mediaMax($sm) {
		flex-direction: column;
		margin: 0;
	}
}

.header__lang {
	flex-shrink: 1;
	@include list-reset();
	margin: 0 -8px 0 25px;

	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	user-select: none;

	& li {
		display: flex;
	}

	& .header__link {
		text-transform: none;
		font-weight: 400;
	}

	@include mediaMax($sm) {
		flex-direction: column;
		justify-content: flex-start;
		margin: 4px 0 0;

		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 24px;
			right: 24px;
			height: 1px;

			background-color: $color-white;
			opacity: 0.1;
		}

		& .header__link {
			font-size: 12px;
		}
	}
}

.header__nav-menu {
	margin: 0 10px;
	display: flex;
	flex-wrap: wrap;
	height: auto;

	&:hover .header__nav-sub-menu {
		display: block;
	}

	@include mediaMax($md) {
		margin: 0;
	}

	@include mediaMax($sm) {
		margin: 0;

		.header__nav-sub-menu {
			display: block;
			height: 0;
			transition: height 0.3s linear;
		}
	}
}

.header__link {
	position: relative;

	width: auto;
	padding: 30px 11px 29px;

	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	text-decoration: none;
	color: $color-white;
	user-select: none;

	opacity: 0.7;

	@include mediaMax($lg) {
		padding: 20px 11px 19px;
	}

	@include mediaMax($md) {
		padding: 16px 10px 15px;
		font-size: 10px;
	}

	@include mediaMax($sm) {
		width: 100%;
		padding: 10px 24px;
	}

	&:hover {
		opacity: 1;
	}

	&:focus {
		outline: none;
	}

	&:active {
		opacity: 0.9;

		@include mediaMax($sm) {
			opacity: 1;
			background-color: rgba($color-white, 0.1);
		}
	}

	&.active {
		opacity: 1;

		@include mediaMax($sm) {
			opacity: 1;
			background-color: rgba($color-white, 0.1);
		}

		&::after {
			content: "";
			position: absolute;
			bottom: -1px;
			left: 11px;
			right: 11px;
			z-index: 1;

			height: 4px;

			background-color: $color-red-1;
			box-shadow: 0 8.4878px 17.869px rgba(255, 59, 0, 0.2), 0 4.75819px 10.0172px rgba(255, 59, 0, 0.18),
				0 2.52704px 5.32008px rgba(255, 59, 0, 0.12), 0 1.05156px 2.21381px rgba(255, 59, 0, 0.1);

			@include mediaMax($sm) {
				top: 0;
				bottom: 0;
				left: 0;
				right: auto;
				height: 100%;
				width: 3px;
			}
		}
	}
}

.header__nav-sub-menu {
	display: none;
}

.sub-menu {
	position: fixed;
	top: 79px;
	left: 0;
	right: 0;
	z-index: 50;

	max-height: calc(100vh - 80px);
	overflow-y: auto;

	background-color: $color-grey-dark;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.5);

	@include mediaMax($lg) {
		top: 59px;
	}

	@include mediaMax($md) {
		top: 51px;
	}

	@include mediaMax($sm) {
		width: 100%;

		position: static;
		max-height: none;
		box-shadow: none;
		background-color: transparent;
	}
}

.sub-menu__container {
	max-width: 1236px;
	padding-top: 32px;
	padding-bottom: 42px;

	@include mediaMax($lg) {
		max-width: 100%;
		padding-left: 126px;
	}

	@include mediaMax($md) {
		padding-left: 94px;
		padding-top: 15px;
		padding-bottom: 20px;
	}

	@include mediaMax($sm) {
		width: 100%;
		padding: 0;
	}
}

.sub-menu__list {
	@include list-reset();
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@include mediaMax($sm) {
		align-items: stretch;
	}

	& li {
		margin-top: 16px;

		@include mediaMax($md) {
			margin-top: 0;
		}

		&:first-child {
			margin-top: 0;
		}
	}
}

.sub-menu__link {
	padding: 10px 20px 10px 0;

	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	text-decoration: none;
	color: $color-white;

	opacity: 0.7;

	&:hover {
		opacity: 1;
	}

	&:active {
		opacity: 0.9;
	}

	@include mediaMax($md) {
		padding: 10px 24px 10px 34px;

		font-size: 10px;
	}
}

.icon-link {
	display: flex;
	justify-content: flex-start;
	user-select: none;

	& svg {
		fill: currentColor;
		align-self: flex-start;
		margin-right: 12px;
	}

	.icon-link__value {
		font: inherit;
		color: inherit;
	}
}

.header__buttons-block {
	display: none;

	@include mediaMax($sm) {
		display: flex;
		padding: 0 12px 0 18px;
		margin-top: auto;

		flex-shrink: 0;

		& .btn-wrapper {
			overflow: hidden;

			& .btn-arrow-red {
				width: 89%;
				display: flex;
				justify-content: center;
			}

			&:hover,
			&:active {
				& .btn-arrow-red {
					width: 92%;
				}
			}
		}
	}
}

.header__menu-mobile {
	display: none;
	margin-left: auto;

	flex-direction: row;
	align-items: flex-start;

	@include mediaMax($sm) {
		display: flex;
		margin-right: -4px;

		position: relative;
		z-index: 105;

		& .header__lang {
			margin-top: 0;
		}
	}

	& .header__link {
		padding: 9px 10px 12px;

		font-size: 12px;
		line-height: 18px;
	}
}

.header__burger {
	margin-left: 10px;
}
@include mediaMax($sm) {
	.menu-open {
		.header__menu-mobile {
			& .header__lang {
				display: none;
			}
		}

		.header__menu {
			transform: translateX(0);
		}

		.header__burger {
			span {
				&:nth-child(2) {
					opacity: 0;
					transform: translateX(50%);
					transition: all 0.3s ease-in-out;
				}

				&:first-child {
					top: 50%;
					transform: translateY(-50%) rotate(-45deg);
					transition: all 0.3s ease-in-out;
				}

				&:last-child {
					bottom: 50%;
					transform: translateY(50%) rotate(45deg);
					transition: all 0.3s ease-in-out;
				}
			}
		}
	}
}
