.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 100;

	display: none;
	justify-content: center;
	align-items: center;
	padding: 30px 20px;

	@include mediaMax($sm) {
		padding: 0;
	}

	&--open {
		display: flex;
	}
}

.modal__container {
	position: relative;

	display: flex;
	flex-direction: column;
	width: 760px;
	max-width: 100%;
	max-height: 100%;
	padding: 36px 0 32px 0;

	background: $color-grey-dark;

	box-shadow: 0 12px 32px rgba(0, 0, 0, 0.5);

	@include mediaMax($sm) {
		padding: 15px 0 24px;
	}
}

.modal__close {
	position: absolute;
	right: 16px;
	top: 12px;
	z-index: 10;

	width: 30px;
	height: 30px;
	color: $color-white;

	border: 0;
	background-color: transparent;

	cursor: pointer;

	@include mediaMax($sm) {
		position: relative;
		top: 0;
		right: 0;
		flex-shrink: 0;
		align-self: flex-start;
	}

	&:after,
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;

		width: 18px;
		height: 2px;

		color: inherit;
		background-color: currentColor;
	}

	&:after {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:before {
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	&:hover {
		opacity: 0.8;
	}

	&:focus {
		outline: none;
	}
}

.modal__header {
	margin: 0 0 14px;
	padding: 0 44px 0 36px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@include mediaMax($sm) {
		padding: 0 16px;
	}

	@include mediaMax($sm) {
		margin: 0 0 37px;
	}
}

.modal__title {
	margin: 0;
	max-width: calc(100% - 40px);

	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: $color-white;

	user-select: none;

	&--small {
		font-size: 20px;
		line-height: 24px;

		@include mediaMax($sm) {
			font-size: 18px;
			line-height: 22px;
		}
	}

	@include mediaMax($sm) {
		font-size: 20px;
		line-height: 24px;
	}
}

.modal__content {
	width: 100%;
	min-height: 30px;
	padding: 10px 44px 0 36px;

	max-height: 100%;

	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;

	@include mediaMax($sm) {
		padding: 0 16px;
	}

	&::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
}

.form--modal {
}
