.error {
	padding: 174px 0px 259px 0px;
	@include mediaMax($lg) {
		padding: 80px 0px 115px 0px;
	}
	@include mediaMax($md) {
		padding: 60px 0px 85px 0px;
	}
	@include mediaMax($sm) {
		padding: 24px 0px 20px 0px;
	}
	&__container {
		display: flex;
		@include mediaMax($sm) {
			display: block;
		}
	}

	&__info {
		flex: 1 1 auto;
		padding: 0px 168px 0px 0px;
		@include mediaMax($lg) {
			padding: 0px 25px 0px 0px;
		}
		@include mediaMax($md) {
			padding: 0px 37px 0px 0px;
		}
		@include mediaMax($sm) {
			padding: 0px 0px 0px 0px;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 80px;
		line-height: 100%;
		letter-spacing: $ls;
		color: $color-white;
		margin: 0px 0px 42px 0px;
		@include mediaMax($lg) {
			margin: 0px 0px 32px 0px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 16px 0px;
		}
	}

	&__text {
		font-size: 24px;
		line-height: 28px;
		letter-spacing: $ls;
		color: $color-white;
		margin: 0px 0px 83px 0px;
		width: 391px;
		@include mediaMax($lg) {
			margin: 0px 0px 91px 0px;
		}
		@include mediaMax($md) {
			width: 259px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 13px 0px;
		}
	}

	&__btn {
		display: inline-flex;
		.btn-arrow-red {
			justify-content: flex-start;
		}
	}

	&__number {
		flex: 0 1 1016px;
		display: inline-flex;
		align-items: center;
		margin: -53px -178px -215px 0px;
		img {
			width: 100%;
		}
		@include mediaMax($lg) {
			min-width: 465px;
			margin: 0px calc(-100vw / 2 + 100% / 2) 0px 0px;
		}
		@include mediaMax($md) {
			min-width: 365px;
		}
		@include mediaMax($sm) {
			min-width: 350px;
			flex: 1 1 100%;
			margin: 0px 0px 84px 0px;
		}
	}
}
