.join {
	padding: 136px 0px 126px 0px;
	position: relative;
	@include mediaMax($lg) {
		padding: 60px 0px 88px 0px;
	}
	@include mediaMax($sm) {
		padding: 24px 0px 23px 0px;
	}
	&__container {
		position: relative;
		z-index: 2;
	}

	&__title {
		margin: 0px 0px 16px 0px;
		max-width: 679px;
		@include mediaMax($sm) {
			margin: 0px 0px 8px 0px;
			max-width: none;
		}
	}

	&__text {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-white;
		margin: 0px 0px 56px 0px;
		max-width: 560px;
		user-select: none;
		@include mediaMax($sm) {
			font-size: 12px;
			line-height: 16px;
			max-width: none;
			margin: 0px 0px 109px 0px;
		}
	}

	&__btn {
		width: 305px;
		display: inline-flex;
		&:hover {
			width: 315px;
		}
		.btn-arrow-red {
			width: 95.5%;
			justify-content: flex-start;
		}
		@include mediaMax($sm) {
			width: 285px;
			&:hover {
				width: 293px;
			}
			.btn-arrow-red {
				width: 94%;
			}
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(270deg, #161825 1.25%, rgba(22, 24, 37, 0) 81.21%),
				linear-gradient(0deg, rgba(21, 78, 119, 0.2), rgba(21, 78, 119, 0.2));
			transform: matrix(-1, 0, 0, 1, 0, 0);
		}
	}
}
