.modal-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 120;

  display: block;
  width: 100%;

  background-color: #1D2031;
  border-top: 1px solid rgba($color-white, 0.25);

  max-height: 80%;
  overflow-y: auto;

  &--error,
  &--success {
    .modal-alert__icon {
      display: block;

    }
  }

  &--success {
    .modal-alert__icon {
      background-color: #40CD5F;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 9px;

        border-right: 2px solid #1D2031;
        border-bottom: 2px solid #1D2031;

        transform: translate(-50%, -50%) rotate(45deg);
      }

    }
  }

  &--error{
    .modal-alert__icon {
      background-color: #FF1812;

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 2px;

        background-color: #1D2031;
      }

      &::after{
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

.modal-alert__container {
  padding-top: 23px;
  padding-bottom: 24px;
  display: flex;
  justify-content: center;

  @include mediaMax($lg) {
    max-width: calc(100% - 100px);
  }

  @include mediaMax($sm) {
    padding: 16px 16px 24px;
    max-width: none;
  }
}

.modal-alert__close {
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 10;

  width: 30px;
  height: 30px;
  color: $color-white;

  border: 0;
  background-color: transparent;

  cursor: pointer;

  @include mediaMax($sm) {
    top: 16px;
    right: 16px ;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;

    width: 18px;
    height: 2px;

    color: inherit;
    background-color: currentColor;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
}
.modal-alert__content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  @include mediaMax($sm) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.modal-alert__text {
  margin: 0;

  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;

  color: $color-white;
}

.modal-alert__icon {
  display: none;

  position: relative;
  width: 32px;
  height: 32px;

  align-self: flex-start;
  flex-shrink: 0;
  margin-right: 16px;

  border-radius: 50%;

  @include mediaMax($sm) {
    margin-right: 0;
    margin-bottom: 7px;
  }
}