.buttons-ui {
	list-style: none;
	padding: 50px;
	margin: 0;
	display: flex;
	flex-direction: column;
	li {
		display: inline-flex;
		margin: 0px 0px 40px 0px;
		background-color: $color-grey-dark;
	}
}

.btn {
	display: inline-flex;
	align-items: center;
	text-decoration: none;
}
.btn-inner {
	@include btn-inner();
	color: $color-white;
	position: relative;
	z-index: 2;
	user-select: none;
}
.btn-icon {
	transition: all 0.5s ease;
	z-index: 2;
}
.btn-red {
	background-color: $color-red-1;
	padding: 16px 38px 16px 24px;
	position: relative;
	@include box-shadow-red();
	.btn-icon {
		margin: 0px 12px 0px 0px;
	}
	.btn-inner {
		font-size: 16px;
		line-height: calc(24 / 16) * 1;
	}
	&:after {
		content: "";
		@include border-white();
		opacity: 0.4;
		position: absolute;
		top: -4px;
		left: -4px;
		z-index: 2;
		width: calc(100% - 3px);
		height: calc(100% - 3px);
		transition: all 0.5s ease;
	}
	&:hover {
		box-shadow: none;
		&:after {
			top: 0px;
			left: 0px;
		}
	}
	@include btn-red();
}

.btn-arrow {
	border-right: none;
	position: relative;
	border-left: 1px solid $color-border-light;
	&:before {
		content: "";
		position: absolute;
		top: 0px;
		right: 0px;
		width: calc(100% - 1px);
		height: calc(50% - 1px);
		transform: skewX(27deg);
		transform-origin: right top;
		background-color: transparent;
		border-right: 1px solid $color-border-light;
		border-top: 1px solid $color-border-light;
		transition: all 0.5s ease;
	}
	&:after {
		content: "";
		position: absolute;
		bottom: 0px;
		right: 0px;
		width: calc(100% - 1px);
		height: calc(50% - 1px);
		transform: skewX(-27deg);
		transform-origin: right bottom;
		background-color: transparent;
		border-right: 1px solid $color-border-light;
		border-bottom: 1px solid $color-border-light;
		transition: all 0.5s ease;
	}
	&_bg {
		background-color: $color-red-1;
		@include box-shadow-red();
		&:before,
		&:after {
			background-color: $color-red-1;
			border-top: none;
			border-bottom: none;
			height: 50%;
		}
		&:before {
			top: 0.5px;
		}
		&:after {
			bottom: 0.5px;
		}
		&:hover {
			&:before,
			&:after {
				border-top: none !important;
				border-bottom: none !important;
			}
		}
	}
	.btn-inner {
		font-size: 20px;
		line-height: calc(24/20) * 1;

		@include mediaMax($sm) {
			font-size: 16px;
		}
	}
}

.btn-wrapper {
	width: 274px;
	transition: all 0.5s ease;
	text-decoration: none;
	position: relative;
	.btn-icon-red {
		transition: all 0.5s ease;
		position: absolute;
		top: 0;
		right: 0px;
		bottom: 0;
		width: auto;
		height: 100%;
	}
	&_red {
		.btn-arrow {
			border-color: $color-red-1;
			&:before,
			&:after {
				border-color: $color-red-1;
			}
		}
		&:hover {
			.btn-arrow {
				border-color: $color-red-2;
			}
			.btn-arrow_bg {
				background-color: $color-red-2;
				box-shadow: none;
			}
		}
		&:active {
			.btn-arrow {
				border-color: $color-red-3;
			}
			.btn-arrow_bg {
				background-color: $color-red-3;
			}
		}
	}
	&_hv {
		&:hover {
			width: 286px;
			.btn-arrow-red {
				width: 93%;
			}
			.btn-icon-red {
				fill: $color-red-2;
			}
			.btn-arrow {
				&:before {
					border-right: 1px solid $color-red-2;
					background-color: $color-red-2;
				}
				&:after {
					border-right: 1px solid $color-red-2;
					background-color: $color-red-2;
				}
			}
		}
		&:active {
			.btn-icon-red {
				fill: $color-red-3;
			}
			.btn-arrow {
				&:before {
					border-right: 1px solid $color-red-3;
					background-color: $color-red-3;
				}
				&:after {
					border-right: 1px solid $color-red-3;
					background-color: $color-red-3;
				}
			}
		}
	}
	&_transparent {
		overflow: hidden;
		.btn-arrow {
			padding: 0;
			border: none;
			&:before,
			&:after {
				display: none;
			}
		}
		.btn__arrow {
			width: auto;
		}
		.btn-arrow-red {
			width: 93%;
		}
		.btn-inner {
			width: calc(100% - 17px);
			display: flex;
			align-items: center;
			border: 1px solid $color-border-light;
			border-right: none;
			padding: 3px 21px 6px 21px;
			min-height: 60px;
			span {
				padding: 0px 20px 0px 0px;
			}
		}
		.btn-arrow-red {
			width: 94%;
		}
		.btn__arrow-fill {
			fill: transparent;
			transition: all 0.5s ease;
		}
		.btn__arrow-stroke {
			stroke: $color-border-light;
			transition: all 0.5s ease;
		}
		&:hover {
			.btn-arrow {
				width: 99%;
			}
			.btn-inner {
				background-color: $color-red-2;
				border-color: $color-red-2;
			}
			.btn__arrow-fill {
				fill: $color-red-2;
			}
			.btn__arrow-stroke {
				stroke: $color-red-2;
			}
		}
		&:active {
			.btn-arrow {
				width: 98%;
			}
			.btn-inner {
				background-color: $color-red-3;
				border-color: $color-red-3;
			}
			.btn__arrow-fill {
				fill: $color-red-3;
			}
			.btn__arrow-stroke {
				stroke: $color-red-3;
			}
		}
	}
	&_white {
		overflow: hidden;
		.btn__arrow-fill {
			fill: transparent;
			transition: all 0.5s ease;
		}
		.btn__arrow-stroke {
			stroke: $color-border-light;
			transition: all 0.5s ease;
		}
		.btn {
			.btn__arrow {
				width: auto;
			}
		}
		&:hover {
			.btn-inner {
				color: $color-grey-dark;
				border-color: $color-btn-white-hover;
			}
			.btn-icon {
				fill: $color-grey-dark;
				z-index: 2;
			}
			.btn-inner {
				background-color: $color-btn-white-hover;
				// &:before {
				// 	border-right: 1px solid $color-btn-white-hover;
				// 	border-top: 1px solid $color-btn-white-hover;
				// 	background-color: $color-btn-white-hover;
				// }
				// &:after {
				// 	border-right: 1px solid $color-btn-white-hover;
				// 	border-bottom: 1px solid $color-btn-white-hover;
				// 	background-color: $color-btn-white-hover;
				// }
			}
			.btn__arrow-fill {
				fill: $color-btn-white-hover;
			}
			.btn__arrow-stroke {
				stroke: $color-btn-white-hover;
			}
		}
		&:active {
			.btn-inner {
				background-color: $color-btn-white-press;
				color: $color-grey-dark;
				border-color: $color-btn-white-press;
			}
			.btn-icon {
				fill: $color-grey-dark;
				z-index: 2;
			}
			.btn__arrow-fill {
				fill: $color-btn-white-press;
			}
			.btn__arrow-stroke {
				stroke: $color-btn-white-press;
			}
		}
	}
}

.btn-arrow-red {
	width: 88%;
	position: relative;
	transition: all 0.5s ease;
	padding: 3px 21px 6px;
	min-height: 60px;
	@include mediaMax($sm) {
		min-height: 50px;
	}
}

.btn-arrow-base {
	width: 91%;
	position: relative;
	.btn-icon {
		transition: all 0.5s ease;
		position: absolute;
		top: 50%;
		transform: translate(0%, -50%);
		z-index: 2;
		right: 0;
	}

	@include mediaMax($sm) {
		.btn-inner {
			font-size: 16px;
			line-height: 24px;
		}
		&:before {
			transform: skewX(35deg);
		}
		&:after {
			transform: skewX(-35deg);
		}
	}
}

.btn-transparent {
	.btn-inner {
		font-size: 20px;
		line-height: calc(24/20) * 1;
		@include mediaMax($sm) {
			font-size: 14px;
			line-height: 24px;
		}
	}
	.btn-icon {
		margin: 2.5px 0px 0px 20px;
		transition: all 0.5s ease;
	}
	&:hover {
		.btn-icon {
			stroke: $color-red-2;
		}
		.btn-inner {
			color: $color-btn-white-hover;
		}
	}
	&:active {
		.btn-icon {
			stroke: $color-red-3;
		}
		.btn-inner {
			color: $color-btn-white-press;
		}
	}
}

.btn-rect {
	position: relative;
	filter: drop-shadow(0px 15.8755px 33.4221px rgba(255, 255, 255, 0.172525))
		drop-shadow(0px 8.4878px 17.869px rgba(255, 255, 255, 0.143066))
		drop-shadow(0px 4.75819px 10.0172px rgba(255, 255, 255, 0.12))
		drop-shadow(0px 2.52704px 5.32008px rgba(255, 255, 255, 0.0969343))
		drop-shadow(0px 1.05156px 2.21381px rgba(255, 255, 255, 0.0674749));
	transition: all 0.5s ease;
	&:hover,
	&:active {
		filter: none;
	}
	&:hover {
		.btn-inner {
			background-color: $color-btn-white-hover;
		}
	}
	&:active {
		.btn-inner {
			background-color: $color-btn-white-press;
		}
	}
	.btn-inner {
		background-color: $color-white;
		color: $color-grey-dark;
		font-size: 20px;
		line-height: calc(24/20) * 1;
		padding: 16px 12px;
		position: relative;
		z-index: 2;
	}
}

.btn-rect-white {
	position: relative;
	transition: all 0.5s ease;
	background: #fff;
	background: linear-gradient(135deg, transparent 11%, $color-white 11% 89%, transparent 89%);
	background: -webkit-linear-gradient(135deg, transparent 11%, $color-white 11% 89%, transparent 89%);
	background: -ms-linear-gradient(135deg, transparent 11%, $color-white 11% 89%, transparent 89%);
	background: -o-linear-gradient(135deg, transparent 11%, $color-white 11% 89%, transparent 89%);
	background: -moz-linear-gradient(135deg, transparent 11%, $color-white 11% 89%, transparent 89%);
	filter: drop-shadow(0px 15.8755px 33.4221px rgba(255, 255, 255, 0.172525))
		drop-shadow(0px 8.4878px 17.869px rgba(255, 255, 255, 0.143066))
		drop-shadow(0px 4.75819px 10.0172px rgba(255, 255, 255, 0.12))
		drop-shadow(0px 2.52704px 5.32008px rgba(255, 255, 255, 0.0969343))
		drop-shadow(0px 1.05156px 2.21381px rgba(255, 255, 255, 0.0674749));
	&:hover,
	&:active {
		filter: none;
	}
	&:hover {
		background: $color-btn-white-hover;
		background: linear-gradient(135deg, transparent 11%, $color-btn-white-hover 11% 89%, transparent 89%);
	}
	&:active {
		background: linear-gradient(135deg, transparent 11%, $color-btn-white-press 11% 89%, transparent 89%);
	}
	@include mediaMax($sm) {
		background: linear-gradient(135deg, transparent 6%, $color-white 6% 94%, transparent 93%);
		&:hover {
			background: linear-gradient(135deg, transparent 6%, $color-btn-white-hover 6% 94%, transparent 93%);
		}
		&:active {
			background: linear-gradient(135deg, transparent 6%, $color-btn-white-press 6% 94%, transparent 93%);
		}
	}
	.btn-inner {
		padding: 14px 36px;
		font-size: 20px;
		line-height: 24px;
		color: $color-grey-dark;
		@include mediaMax($sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.btn-rect-grey {
	position: relative;
	transition: all 0.5s ease;
	background-image: linear-gradient(135deg, transparent 11%, $color-grey-9 11% 89%, transparent 89%);
	@include mediaMax($sm) {
		background-image: linear-gradient(135deg, transparent 6%, $color-grey-9 6% 94%, transparent 93%);
	}
	.btn-inner {
		padding: 16px 36px;
		font-size: 20px;
		line-height: 24px;
		color: $color-white;
		@include mediaMax($sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.btn-base {
	padding: 15px;
	width: 100%;
	background: $color-grey-1;
	@include border-white();
	@include btn-white();
	justify-content: center;
	&:hover,
	&:active {
		.btn-inner {
			color: $color-grey-dark;
		}
	}
}

// Presskit
.btn-presskit {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	@include btn-white-rect();
	position: relative;

	&:hover {
		.download__text {
			color: $color-red-2;
		}
		.download__icon {
			fill: $color-red-2;
			stroke: $color-red-2;
		}
	}
	&:active {
		.download__text {
			color: $color-red-3;
		}
		.download__icon {
			fill: $color-red-3;
			stroke: $color-red-3;
		}
	}
	padding: 25px 76.5px 31px 51px;
	@include mediaMax($sm) {
		padding: 12.82px 39.08px 16.5px 25.78px;
	}
	&:after {
		content: "";
		transform: rotate(45deg);
		position: absolute;
		width: 60px;
		height: 60px;
		top: -30px;
		right: -30px;
		@include mediaMax($sm) {
			width: 35px;
			height: 35px;
			top: -17px;
			right: -17px;
		}
	}
	&:before {
		content: "";
		transform: rotate(-45deg);
		opacity: 0.2;
		position: absolute;
		z-index: 2;
		border: 23px solid transparent;
		border-right: 23px solid $color-grey-dark;
		top: 18px;
		right: 17px;
		@include mediaMax($sm) {
			border: 15px solid transparent;
			border-right: 15px solid $color-grey-dark;
			top: 11px;
			right: 11px;
		}
	}
	&__icon {
		margin: 0px 22.5px 0px 0px;
		position: relative;
		z-index: 2;
		@include mediaMax($sm) {
			margin: 0px 5.5px 0px 0px;
			width: 25px;
			height: 32px;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 2;
	}

	&__title {
		display: block;
		@include btn-inner();
		color: $color-grey-dark;
		text-transform: uppercase;
		font-size: 32px;
		line-height: 40px;
		user-select: none;
		@include mediaMax($sm) {
			font-size: 16px;
			line-height: 20px;
		}
		&_sm {
			font-size: 20px;
			line-height: 24px;
			@include mediaMax(1200px) {
				font-size: 18px;
				line-height: 20px;
			}
			@include mediaMax(850px) {
				font-size: 16px;
				line-height: 18px;
			}
			@include mediaMax($sm) {
				font-size: 12px;
				line-height: 14px;
			}
		}
	}

	&__download {
	}

	&_sm {
		padding: 8px;
		.btn-presskit__icon {
			margin: 0px 9px 0px 0px;
			@include mediaMax(1200px) {
				width: 20px;
				height: 25px;
				margin: 0px 7px 0px 0px;
			}
			@include mediaMax($md) {
				width: 20px;
				height: 25px;
				margin: 0px 9px 0px 0px;
			}
			@include mediaMax(850px) {
				margin: 0px 6px 0px 0px;
			}
			@include mediaMax($sm) {
				margin: 0px 9px 0px 0px;
				width: 16px;
				height: auto;
			}
			@include mediaMax(400px) {
				margin: 0px 6px 0px 0px;
				width: 14px;
			}
		}
		&:after {
			width: 36px;
			height: 36px;
			top: -19px;
			right: -19px;
		}

		&:before {
			border: 16px solid transparent;
			border-right: 16px solid #161825;
			top: 11px;
			right: 10px;
		}
	}
}
.download {
	display: flex;
	align-items: center;
	&__text {
		@include btn-inner();
		color: $color-grey-dark;
		transition: all 0.5s ease;
		font-size: 20px;
		line-height: calc(23/20) * 1;
		user-select: none;
		@include mediaMax($sm) {
			font-size: 10px;
			line-height: 12px;
		}
		&_sm {
			font-size: 12px;
			line-height: 16px;
			@include mediaMax(850px) {
				font-size: 10px;
				line-height: 15px;
			}
		}
	}

	&__icon {
		display: inline;
		margin: 0px 0px 0px 6px;
		transition: all 0.5s ease;
		@include mediaMax($sm) {
			width: 8px;
			height: 8px;
		}
	}
}

// Slider
.btn-slider {
	position: relative;
	width: 64.5px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	// padding: 0px 31.5px 0px 27px;
	position: relative;
	&:after {
		content: "";
		position: absolute;
		z-index: 2;
		transition: all 0.5s ease;
		width: 64px;
		height: 60px;
		background: url("./../img/sprite/slide-btn-frame.svg") 0 0 no-repeat;
	}
	&:hover {
		&:after {
			top: 0px;
			left: 0px;
		}
	}
	@include mediaMax($sm) {
		width: 45px;
		height: 42px;
	}
	&:active {
		.btn-slider-bg {
			fill: $color-grey-3;
		}
	}
	&.swiper-button-disabled {
		opacity: 0.4;
		&:after {
			display: none;
		}
	}
	&__icon {
		position: relative;
		z-index: 2;
		margin-right: 3px;
		@include mediaMax($sm) {
			width: 10px;
			height: 15px;
			margin-top: -3px;
		}
	}
}

.btn-slider-bg {
	position: absolute;
	top: 0;
	left: 0;
	fill: $color-grey-2;
}

// Inputs
.float-container {
	height: 52px;
	&.active {
		.label-text {
			transform: translate(17.5px, -9px) scale(0.85);
			z-index: 3;
		}

		.label-search {
			z-index: 3;
		}
		.label-textarea {
			transform: translate(17.5px, 2px) scale(0.85);
		}
		.icon {
			z-index: 3;
		}
		.input-border,
		.select-border,
		.textarea-border {
			top: 0;
			left: 0;
			z-index: -1;
		}
		.float-input,
		.custom-select {
			border: 1.5px solid transparent;

			&._error {
				@include border-red();
			}
		}

		.form__checkbox{
			@include border-grey();
			&._error {
				@include border-red();
			}
		}
	}
}

.form__checkbox-wrapper {
	position: relative;
	display: inline-flex;
	min-width: 16px;
	min-height: 16px;
	cursor: pointer;
}

.form__checkbox-container {
	height: 16px;
	&:hover {
		.form__checkbox-border {
			top: 0px;
			left: 0px;
		}
	}
	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
		cursor: pointer;
		margin: 0;
		width: 100%;
		height: 100%;
		&:focus {
			~ .form__checkbox {
				background-color: #fff;
			}
			~ .form__checkbox-border {
				@include border-red();
				top: 0px;
				left: 0px;
			}
		}
		&:checked {
			~ .form__checkbox-border {
				top: 0px;
				left: 0px;
				@include border-grey();
			}
			~ .form__checkbox {
				background-color: #fff;
				&:after {
					display: block;
				}
			}
		}
		&:disabled {
			~ .form__checkbox {
				background-color: $color-grey-6;
			}
			~ .form__checkbox-border {
				top: -4px;
				left: -4px;
			}
		}
		&._error {
			~ .form__checkbox-border {
				top: 0;
				left: 0;
				@include border-red();
			}
		}
	}
}

.form__checkbox {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	background-color: $color-grey-4;
	transition: all 0.5s ease;
	outline: none;
	cursor: pointer;
	&:after {
		display: none;
		content: "";
		position: absolute;
		bottom: 50%;
		right: 50%;
		transform: translate(50%, 50%);
		-ms-transform: translate(50%, 50%) !important;
		z-index: 3;
		width: 10px;
		height: 8px;
		background: url("./../img/sprite/checkbox-tick.svg") 0 0 no-repeat;
	}
}

.label {
	position: absolute;
	transform-origin: top left;
	transition: opacity 0.5s ease, transform 0.5s ease, background-color 1s ease;
	cursor: text;
	font-size: 16px;
	line-height: calc(24/16) * 1;
	letter-spacing: 0.04em;
	color: $color-grey-dark;
	opacity: 0.5;
	position: relative;
	z-index: 3;
	width: calc(100% - 16px);
	user-select: none;
	.hidden-m {
		@include mediaMax($sm) {
			display: none;
		}
	}
}

.label-text {
	transform: translate(16px, 0px) scale(1);
	height: auto;
}
.label-search {
	height: auto;
}
.input-border,
.select-border {
	position: absolute;
	top: -4px;
	left: -4px;
	z-index: 2;
	width: 100%;
	height: 100%;
	transition: all 0.5s ease;
	@include border-grey();
}
.form__checkbox-border {
	position: absolute;
	top: -4px;
	left: -4px;
	z-index: 2;
	transition: all 0.5s ease;
	@include border-grey();
	width: 100%;
	height: 100%;
}
.form-error {
	font-size: 12px;
	line-height: calc(16 / 12) * 1;
	letter-spacing: 0.04em;
	color: $color-grey-6;
	position: absolute;
	bottom: -20px;
	left: 16px;
	transition: all 0.5s ease;
	margin: 0;
	color: #ff1812;
	@include mediaMax($sm) {
		bottom: -15px;
	}
}

.custom-select-container,
.float-container,
.form-checkbox__container,
.attach-container {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	&:hover {
		.float-input,
		.form__checkbox,
		.custom-select,
		.label-file {
			background-color: #fff;
		}
		.float-input {
			border: 1.5px solid transparent;
		}
		.form__checkbox {
			@include border-grey();
		}
		.input-border,
		.textarea-border {
			top: 0px;
			left: 0px;
		}
		.form__checkbox-border {
			top: 0px;
			left: 0px;
		}
		.select-border {
			top: 0px;
			left: 0px;
		}
		.label {
			opacity: 0.7;
		}
	}
}

// Text input
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"] {
	border-radius: 0;
	border: none;
	outline: 0;
	display: flex;
	width: 100%;
	height: 100%;
	padding-top: 14px;
	color: $color-grey-dark;
	transition: all 0.5s ease;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background-color: $color-grey-4;
	//user-select: all;
	-webkit-appearance: none;
	-webkit-border-radius: 0px;
	&::placeholder {
		color: $color-grey-5;
		font-weight: 400;
	}
	&:disabled {
		background-color: $color-grey-6;
		~ .input-border {
			top: -4px;
			left: -4px;
		}
	}
	&:focus {
		transition: all 0.5s ease;
		caret-color: $color-red-1;
		background-color: #fff;
		~ .input-border {
			top: 0;
			left: 0;
			@include border-white();
		}
	}
	&._error {
		&:focus {
			~ .input-border {
				@include border-red();
			}
		}
	}
}
.float-input,
.input-file {
	padding: 0px 16px 0px 16px;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.04em;
	border: 1.5px solid transparent;

	&._error {
		~ .input-border,
		~ .textarea-border {
			@include border-red();
			top: 0;
			left: 0;
		}
		~ .form-error {
			color: $color-red-1;
		}
	}
}
.textarea-container {
	height: auto;
	cursor: text;
	@include mediaMax($sm) {
		margin: 0 0 20px;
	}
}

.textarea {
	min-height: 104px;
	border: none;
	outline: 0;
	display: flex;
	width: 100%;
	height: 100%;
	padding-top: 16px;
	color: $color-grey-dark;
	background-color: $color-grey-4;
	transition: all 0.5s ease;
	resize: none;
	overflow: auto;
	-webkit-appearance: none;
	-webkit-border-radius: 0px;
	&::placeholder {
		color: $color-grey-5;
		font-weight: 400;
	}
	&:disabled {
		background-color: $color-grey-6;
		~ .textarea-border {
			top: -4px;
			left: -4px;
		}
	}
	&:focus {
		transition: all 0.5s ease;
		caret-color: $color-red-1;
		background-color: #fff;
		~ .textarea-border {
			top: 0px;
			left: 0px;
			@include border-white();
		}
		~ .textarea-subtitle {
			color: $color-red-1;
		}
	}
}

.textarea-border {
	content: "";
	width: 100%;
	height: 100%;
	transition: all 0.5s ease;
	@include border-grey();
	position: absolute;
	top: -4px;
	left: -4px;
}

.label-textarea {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 16px);
	height: auto;
	transform: translate(16px, 8px) scale(1);
}

// File upload
.attach-container {
	.input-border {
		cursor: pointer;
	}
}

input[type="file"] {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	&:disabled {
		+ label {
			background-color: $color-grey-6;
		}
		~ .input-border {
			top: -4px;
			left: -4px;
		}
	}
}

.input-file {
	&.attached {
		~ .input-border {
			top: 0;
			left: 0;
		}
		~ .label-file {
			background-color: #fff;
		}
	}
}

.label-file {
	display: flex;
	align-items: center;
	width: 100%;
	height: 52px;
	padding: 0 44px;
	background-color: $color-grey-4;
	position: relative;
	transition: all 0.5s ease;
	cursor: pointer;
	span {
		font-size: 16px;
		line-height: calc(24/16) * 1;
		letter-spacing: 0.04em;
		color: $color-grey-dark;
		opacity: 0.5;
		cursor: pointer;
	}
}

// Input icons
.icon {
	position: absolute;
	top: 50%;
	transform: translate(0%, -50%);
}

// File upload icons
.icon-attach,
.icon-file,
.icon-search {
	left: 17.67px;
}
.icon-delete {
	right: 14px;
	z-index: 4;
}
.icon-file,
.icon-attach,
.icon-delete {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.1s ease, visibility 0.1s ease;
	&.visible {
		visibility: visible;
	}
}
.icon-file {
	&.visible {
		opacity: 1;
	}
}
.icon-attach {
	&.visible {
		opacity: 0.5;
	}
}
.icon-delete {
	&.visible {
		opacity: 0.6;
		transition: all 0.5s ease;
		&:hover {
			fill: #4c4f61;
			opacity: 1;
		}
	}
}

// Search input
.input-container {
	position: relative;
	&.active {
		.label-search {
			transform: translate(46px, -9px) scale(0.85);
		}
	}
}
.label-search {
	transform: translate(44px, 0px) scale(1);
}
.input-search {
	padding: 15px 15px 0px 44px !important;
}
.icon-search {
	z-index: 2;
}

// Select
.icon-select {
	right: 0;
}
.custom-select-container {
	position: relative;
	user-select: none;
	width: 100%;
}
.custom-select {
	position: relative;
	width: 100%;
	height: 52px;
	background-color: $color-grey-4;
	transition: all 0.5s ease;
	color: $color-grey-dark;
	padding: 0px 23px 0px 16px;
	cursor: pointer;
	&.open {
		background-color: $color-white;
		.custom-select__trigger {
			.icon-select {
				transform: translate(0%, -50%) rotate(-180deg);
			}
		}
		.custom-options {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
			background-color: $color-white;
			z-index: 4;
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
		.select-border {
			top: 0px;
			left: 0px;
		}
		.select-subtitle {
			opacity: 0;
			visibility: hidden;
		}
	}
}
.custom-select__trigger {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
}
.label-select {
	transform: translateX(0);
	cursor: pointer;
}
.custom-options {
	position: absolute;
	display: block;
	top: calc(100%);
	left: 0px;
	right: 0;
	width: 100%;
	background-color: $color-grey-4;
	transition: all 0.5s ease;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	&:after {
		content: "";
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: calc(100% - 3px);
		height: 100%;
		transition: all 0.5s ease;
		@include border-grey();
		border-top: none;
		opacity: 0;
		visibility: hidden;
	}
}

.custom-option {
	position: relative;
	display: block;
	font-size: 16px;
	line-height: calc(24/16) * 1;
	letter-spacing: 0.04em;
	padding: 10px 16px;
	color: rgba(22, 24, 37, 0.5);
	cursor: pointer;
	transition: all 0.5s ease;
	z-index: 2;
	&:hover {
		cursor: pointer;
		background-color: $color-grey-8;
	}
	&.selected {
		color: $color-white;
		background-color: $color-grey-5;
	}
}

.btn-arrow-red-bg {
	display: flex;
	position: relative;
	width: 244px;
	position: relative;
	transition: all 0.5s ease;
	min-height: 100%;
	@include btn-red();
	@include btn-icon-arrow();
	.btn-inner {
		width: 100%;
		padding: 20.5px 32px;
		background-color: $color-red-1;
		@include box-shadow-red();
	}
	// &__shape {
	// 	position: absolute;
	// 	top: 23%;
	// 	right: -7%;
	// 	width: 14%;
	// 	height: 50%;
	// 	z-index: 2;
	// 	transform: skewX(30deg) rotate(60deg);
	// 	background-color: #fff;
	// }
	&:hover {
		.btn-inner {
			width: 251px;
			background-color: $color-red-2;
		}
		.btn-icon-red {
			fill: $color-red-2;
		}
	}
	&:active {
		.btn-icon-arrow {
			fill: $color-red-3;
			stroke: $color-red-3;
		}
		.btn-icon-red {
			fill: $color-red-3;
		}
	}
}

// .btn-arrow-red-bg-shape {
// 	min-height: 100%;
// 	display: inline-flex;
// 	flex-direction: column;
// 	&__left,
// 	&__right {
// 		display: block;
// 		width: 31px;
// 		height: 15px;
// 		transition: all 0.5s ease;
// 		transform: rotate(90deg);
// 		background: linear-gradient(153.5deg, transparent 50%, $color-red-1 0);
// 	}

// 	&__right {
// 		transform: scaleX(-1) rotate(-90deg);
// 	}
// }

// Social icons
.social-icons {
	li {
		margin: 0;
	}
}

.icon-social {
	cursor: pointer;
	transition: all 0.5s ease;
	user-select: none;
	svg {
		transition: all 0.5s ease;
	}
}

.social-vk,
.social-fb,
.social-im,
.social-ytb,
.icon-platform,
.social-viber,
.social-tg {
	position: relative;
	display: block;
	&:hover {
		.icon-vk,
		.icon-fb,
		.icon-im,
		.icon-ytb,
		.icon-app,
		.icon-gp,
		.icon-steam,
		.icon-p1,
		.icon-xbox,
		.icon-playstation,
		.icon-steam,
		.icon-viber,
		.icon-tg {
			opacity: 0;
			visibility: hidden;
		}
		.icon-vk-hover,
		.icon-fb-hover,
		.icon-im-hover,
		.icon-ytb-hover,
		.icon-app-hover,
		.icon-gp-hover,
		.icon-steam-hover,
		.icon-p1-hover,
		.icon-xbox-hover,
		.icon-playstation-hover,
		.icon-steam-hover,
		.icon-viber-hover,
		.icon-tg-hover {
			opacity: 1;
			visibility: visible;
		}
	}
}

.icon-vk,
.icon-fb,
.icon-im,
.icon-ytb,
.icon-app,
.icon-gp,
.icon-steam,
.icon-p1,
.icon-xbox,
.icon-playstation,
.icon-steam,
.icon-viber,
.icon-tg {
	opacity: 1;
	visibility: visible;
	transition: all 0.5s ease;
}

.icon-vk-hover,
.icon-fb-hover,
.icon-im-hover,
.icon-ytb-hover,
.icon-app-hover,
.icon-gp-hover,
.icon-steam-hover,
.icon-p1-hover,
.icon-xbox-hover,
.icon-playstation-hover,
.icon-steam-hover,
.icon-viber-hover,
.icon-tg-hover {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s ease;
}

.icon-tw {
	&:hover {
		svg {
			fill: $color-social-tw;
		}
	}
}

.platforms-icons {
	padding: 0;
	@include list-reset();
	display: flex;
}

.icon-platform {
	width: 24px;
	height: 24px;
	user-select: none;
}

.icon-social {
	width: 20px;
	height: 20px;
	img {
		height: 100%;
		width: 100%;
		display: block;
	}
}

// Images

.image-crop {
	position: relative;
	margin: 2px;
	width: calc(100% - 4px);
	height: 100%;
	user-select: none;
	&:hover {
		.image-crop__info {
			opacity: 1;
			visibility: visible;
			z-index: 2;
		}
		.image-crop__content {
			width: calc(100% + 3px);
			height: calc(100% + 3px);
		}
		.image-crop__title_front {
			opacity: 0;
			visibility: hidden;
		}
	}
	&__wrapper {
		display: block;
		height: 100%;
		position: relative;
		text-decoration: none;
	}

	&__content {
		width: 95%;
		height: 93%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		padding: 8px 16px;
		display: flex;
		align-items: flex-end;
		@include border-white();
		transition: all 0.3s ease;
	}

	&__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;
		color: $color-white;
		max-width: 165px;
		margin: 0;
		user-select: none;
		&_hv {
			margin: 0px 0px 12px 0px;
			@include mediaMax($sm) {
				margin: 0px 0px 4px 0px;
			}
		}
		&_front {
			opacity: 1;
			visibility: visible;
			transition: all 0.5s ease;
		}
	}

	&__img-block {
		display: block;
		overflow: hidden;
		height: 100%;
		position: relative;
	}

	&__img {
		display: block;
		width: 100%;
		height: 200%;
		overflow: hidden;
		// margin-top: -105px;
		// margin-left: -10px;
		img {
			display: block;
			object-fit: cover;
			object-position: center;
			height: 50%;
			width: 100%;
			font-family: "object-fit: cover; object-position: center;";
		}
		// @include mediaMax($lg) {
		// 	// width: 114%;
		// 	transform: rotate(45deg) translate(-34%, -17%);
		// }
		// @include mediaMax(1050px) {
		// 	margin-left: -5px;
		// }
		// @include mediaMax($md) {
		// 	margin-left: -18px;
		// 	margin-top: -107px;
		// }
		// @include mediaMax($sm) {
		// 	margin-top: -94px;
		// 	margin-left: 16px;
		// }
	}

	&__info {
		display: flex;
		flex-direction: column;
		min-height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		padding: 24px 24px 16px 24px;
		background-color: $color-grey-10;
		transition: all 0.8s ease;
		opacity: 0;
		visibility: hidden;
	}

	&__icons {
		flex: 1 1 auto;
		li {
			margin: 0px 12px 0px 0px;
			&:last-child {
				margin: 0px 0px 0px 0px;
			}
		}
	}

	&__icon {
	}

	&__btn {
		width: 100%;
	}
}

// Swiper
.swiper {
	// overflow: hidden;
	> .swiper-wrapper {
		width: 100%;
		height: 100%;
		box-sizing: content-box;
		display: flex;
		position: relative;
		@include list-reset();
		&__center {
			justify-content: center;
      margin: 0 auto;
		}
	}
	&.swiper-container-autoheight {
		> li {
			align-items: flex-start;
		}
	}
}
.swiper-container-initialized {
	.swiper-slide {
		flex-shrink: 0;
		// transition-property: transform;
	}
}
.swiper-container-android {
	.swiper-slide,
	.swiper-wrapper {
		transform: translate3d(0px, 0, 0);
	}
}
.swiper-button-lock {
	display: none !important;
}

// .wrap {
// 	display: inline-block;
// 	padding: 20px;
// 	background-color: SeaGreen;
// }

// .elem {
// 	width: 300px;
// 	position: relative;
// 	border-radius: 10px;
// 	background-image: linear-gradient(135deg, transparent 5%, #333 5% 95%, transparent 95%);
// 	padding: 20px;
// 	z-index: 0;
// }

// .elem::before {
// 	z-index: -1;
// 	content: "";
// 	position: absolute;
// 	left: 3px;
// 	right: 3px;
// 	top: 3px;
// 	bottom: 3px;
// 	border-radius: 7px;
// 	background-image: linear-gradient(135deg, transparent 5%, orange 5% 95%, transparent 95%);
// }

// .title {
// 	position: relative;
// 	background: blue;
// 	text-align: center;
// 	color: white;
// 	font-weight: bold;
// 	text-transform: uppercase;
// }

// .title:before,
// .title:after {
// 	content: "";
// 	position: absolute;
// 	left: 0;
// 	right: 0;
// 	bottom: 100%;
// 	border-bottom: 15px solid blue;
// 	border-left: 15px solid transparent;
// 	border-right: 15px solid transparent;
// }

// .title:after {
// 	bottom: auto;
// 	top: 100%;
// 	border-bottom: none;
// 	border-top: 15px solid blue;
// }
