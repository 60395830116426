.release-main {
	position: relative;
	@include mediaMax(600px) {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background: linear-gradient(
				180deg,
				#161825 0%,
				rgba(22, 24, 37, 0) 29.87%,
				rgba(22, 24, 37, 0) 68.18%,
				#161825 88.94%
			);
		}
	}

	&__container {
		position: relative;
		height: 100%;
		min-height: 600px;
		display: flex;
		@include mediaMax($lg) {
			min-height: 495px;
		}
		@include mediaMax($md) {
			min-height: 380px;
		}
		@include mediaMax(600px) {
			min-height: 520px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
		}
	}

	&__logo {
		width: 100%;

		position: relative;
		z-index: 3;
		&_pc {
			margin: 0px 0px 9px -7px;
		}
	}

	&__content {
		max-width: 600px;
		padding: 140px 0px 0px 0px;
		&_mobile {
			@include mediaMax(1200px) {
				padding: 80px 0px 0px 0px;
			}
		}
		&_pc {
			@include mediaMax(1300px) {
				max-width: 525px;
			}
			@include mediaMax($lg) {
				padding: 95px 0px 0px 0px;
			}
		}
		@include mediaMax($md) {
			padding: 65px 0px 0px 0px;
		}
		@include mediaMax($md) {
			max-width: 435px;
		}
		@include mediaMax($sm) {
			max-width: 300px;
		}
		@include mediaMax(600px) {
			display: flex;
			flex-direction: column;
			min-height: 0;
			max-width: none;
			padding: 13px 0 0;
			&_mobile {
				padding: 35px 0 0;
			}
		}
	}

	&__title {
		font-weight: 600;
		font-size: 48px;
		line-height: calc(60 / 48) * 1;
		letter-spacing: $ls;
		text-transform: uppercase;
		color: #ffffff;
		text-shadow: 0px 2.67598px 2.67598px rgba(0, 0, 0, 0.25);
		margin: 0px 0px 100px 0px;
		user-select: none;
		position: relative;
		z-index: 3;
		&_mobile {
			margin: 0px 0px 40px 0px;
		}
		@include mediaMax($lg) {
			margin: 0px 0px 60px 0px;
		}
		@include mediaMax($md) {
			font-size: 32px;
			line-height: calc(40/32) * 1;
		}
		@include mediaMax($sm) {
			font-size: 26px;
		}
		@include mediaMax(600px) {
			margin: 0px 0px 0px 0px;
			font-size: 20px;
			line-height: 28px;
			color: $color-grey-3;
		}
	}

	&__btn {
		width: 242px;
		display: inline-block;
		&:hover {
			width: 251px;
		}

		position: relative;
		z-index: 3;
		@include mediaMax(600px) {
			width: 284px;
			position: absolute;
			left: 16px;
			bottom: 0;
			.btn-arrow_bg {
				box-shadow: none;
			}
			.btn-arrow-red {
				width: 90%;
				display: flex;
				justify-content: center;
			}
			.btn-inner {
				text-align: center;
			}
			&:hover {
				width: 286px;
			}
		}
	}

	&__image-block {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		user-select: none;
		picture {
			height: 100%;
		}
		img {
			max-height: 100%;
			object-fit: contain;
		}
		&_pc {
			img {
				@include mediaMin($lg) {
					margin: 45px 45px 0px 0px;
				}
			}
		}
		&_mobile {
			img {
				margin: 120px 0px 0px 0px;
			}
			@include mediaMax($md) {
				img {
					margin: 55px 0px 0px 0px;
				}
			}
			@include mediaMax($sm) {
				img {
					margin: 86px 0 0;
					height: 61%;
				}
			}
		}
		@include mediaMax(600px) {
			picture {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
			}
			img {
				height: auto;
				margin-top: 113px;
			}
		}
		// &_pc {
		// 	height: auto;
		// 	padding: 34px 34px 0px 0px;
		// 	@include mediaMax($lg) {
		// 		padding: 0;
		// 		.release-main__image {
		// 			margin: -9px 0px 0px 0px;
		// 		}
		// 	}
		// 	@include mediaMax(1300px) {
		// 		.release-main__image {
		// 			margin: -9px -75px 0px 0px;
		// 		}
		// 	}
		// 	@include mediaMax($md) {
		// 		.release-main__image {
		// 			margin: 0px -48px 0px 0px;
		// 		}
		// 	}
		// 	@include mediaMax(600px) {
		// 		.release-main__image {
		// 			margin: 142px -48px 0px 0px;
		// 			padding: 0;
		// 			width: 100%;
		// 			max-height: 445px;
		// 			img {
		// 				object-fit: contain;
		// 				object-position: center center;
		// 				width: 100%;
		// 			}
		// 		}
		// 	}
		// }
		// &_console {
		// 	@include mediaMax($lg) {
		// 		.release-main__image {
		// 			width: 600px;
		// 			margin: 0px -70px 0px 0px;
		// 		}
		// 	}
		// 	@include mediaMax($sm) {
		// 		.release-main__image {
		// 			width: 400px;
		// 			margin: 0 -19px 0 0;
		// 		}
		// 	}
		// }
		// @include mediaMax(600px) {
		// 	justify-content: flex-start;
		// 	align-items: center;
		// 	.release-main__image {
		// 		width: 450px;
		// 		margin: 60px 0px 0px 0px;
		// 	}
		// }
	}

	// &__image {
	// 	height: 100%;
	// 	display: flex;
	// 	justify-content: flex-end;
	// 	align-items: center;
	// 	img {
	// 		width: 100%;
	// 		height: 100%;
	// 		object-fit: contain;
	// 	}
	// 	@include mediaMax($md) {
	// 		width: 440px;
	// 	}
	// 	@include mediaMax(600px) {
	// 		width: 100%;
	// 	}
	// 	&_mobile {
	// 		margin: 189px -41px 0px 0px;
	// 		@include mediaMax($lg) {
	// 			max-width: 616px;
	// 		}
	// 		@include mediaMax($md) {
	// 			margin: 121px 0px 0 0;
	// 		}
	// 		@include mediaMax($sm) {
	// 			margin: 121px -33px 0;
	// 		}
	// 		@include mediaMax(600px) {
	// 			margin: 194px 0 0;
	// 		}
	// 	}
	// 	&_pc {
	// 		@include mediaMax($lg) {
	// 			max-width: 616px;
	// 		}
	// 	}

	// 	// &_mobile {
	// 	// 	width: 856px;
	// 	// 	margin: 0 -27px 0 0;
	// 	// 	padding-top: 162px;
	// 	// 	display: flex;
	// 	// 	justify-content: flex-end;
	// 	// 	img {
	// 	// 		object-fit: contain;
	// 	// 		object-position: center right;
	// 	// 		width: 100%;
	// 	// 	}
	// 	// 	@include mediaMax($lg) {
	// 	// 		width: 59vw;
	// 	// 		margin: 0 -18px 0 0;
	// 	// 		padding-top: 123px;
	// 	// 		max-width: 856px;
	// 	// 	}
	// 	// 	@include mediaMax($md) {
	// 	// 		width: 394px;
	// 	// 		margin: 0 0 0 0;
	// 	// 	}
	// 	// 	@include mediaMax(600px) {
	// 	// 		margin: 0;
	// 	// 		width: 118%;
	// 	// 		margin: 0 0 0 -41px;
	// 	// 		img {
	// 	// 			object-position: center center;
	// 	// 		}
	// 	// 	}
	// 	// }
	// }

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: top center;
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-image: url("../img/release-main/release-main-bg-shadow.png");
			background-color: rgba($color-grey-dark, -0.5);
			background-blend-mode: multiply, normal;
		}
	}
}
