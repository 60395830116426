.vacancy {
	margin: 0 auto;
	padding: 80px 0px;
	@include mediaMax($lg) {
		padding: 60px 0px;
	}
	@include mediaMax($sm) {
		padding: 35px 0px;
	}
	&__container {
		max-width: 1000px;
		@include mediaMax($lg) {
			max-width: none;
		}
	}

	&__filter {
		margin: 0px 0px 37px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 32px 0px;
		}
	}

	&__items {
		@include list-reset();
	}

	&__item {
		margin: 0px 0px 20px 0px;
		position: relative;
		&:last-child {
			margin: 0px 0px 0px 0px;
		}
		&:hover {
			.vacancy__frame {
				top: 0px;
				left: 0px;
			}
		}
	}

	&__frame {
		top: -4px;
		left: -4px;
		z-index: 2;
		cursor: pointer;
		@include mediaMax($sm) {
			.frame-border__corner {
				height: 24px;
			}
			.frame-border__border {
				height: calc(100% - 12.25px);
				bottom: 12.3px;
			}
		}
	}

	&__top {
		padding: 28px 24px 28px 40px;
		display: flex;
		justify-content: space-between;
		-ms-justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 2;
		background-color: $color-grey-9;
		@include mediaMax($sm) {
			padding: 4px 8px 14px 8px;
			flex-direction: column;
			align-items: flex-start;
		}
		&.active {
			&:after {
				width: 0;
				height: 0;
			}
			~ .vacancy__frame {
				top: 0px;
				left: 0px;
			}
		}
		&:after {
			// content: "";
			// box-shadow: 0 0 0 1900px $color-grey-9;
			// transition: all 0.5s ease;
			// transform: rotate(45deg);
			// position: absolute;
			// width: 30px;
			// height: 30px;
			// bottom: -15px;
			// right: -15px;
			// @include mediaMax($sm) {
			// 	width: 18px;
			// 	height: 18px;
			// 	bottom: -9px;
			// 	right: -9px;
			// }
			content: "";
			position: absolute;
			width: 36px;
			height: 36px;
			background-color: #161825;
			bottom: 0;
			right: 0;
			transform: translate(50%, 50%) rotate(45deg);

            transition: all 0.3s linear;
			@include mediaMax($sm) {
				width: 20px;
				height: 20px;
			}
		}
	}
	&__title {
		margin: 0px 0px 32px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 12px 0px;
		}
	}

	&__label {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-white;
		margin: 0;
		user-select: none;
		@include mediaMax($sm) {
			font-size: 14px;
			line-height: 20px;
		}
	}

	&__conditions {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-white;
		user-select: none;
		margin: 0;
		@include mediaMax($sm) {
			font-size: 12px;
			line-height: 16px;
		}
	}

	&__body {
		position: relative;
		z-index: 3;
	}
}

.body-vacancy {
	display: none;
	overflow: hidden;
	//transition: height 0.3s 0.1s ease-in-out;
	height: auto;
	will-change: height;
	-webkit-transform: translate3d(0,0,0);

  &.active {
	}

	&__content {
		padding: 26px 41px;
		@include mediaMax($sm) {
			padding: 12px 9px;
			flex-direction: column;
			align-items: center;
		}
	}

	&__info {
		max-width: 520px;
		padding: 0px 0px 11px 0px;
		p,
		ul > li {
			color: $color-grey-17;
			&:before {
				background-color: $color-grey-17;
			}
		}
		@include mediaMax($sm) {
			padding: 0px 0px 46px 0px;
			max-width: none;
		}
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		border-top: 1px solid $color-grey-2;
		padding: 19px 0px 0px 0px;
		@include mediaMax($sm) {
			border: none;
			padding: 0px 0px 0px 0px;
			flex-direction: column;
			width: 100%;
		}
	}

	&__btn {
		display: flex;
		position: relative;
		z-index: 2;
		justify-content: center;
		align-items: center;
		.btn-inner {
			padding: 14px 36px;
		}
		&_grey {
			display: none;
			@include mediaMax($sm) {
				display: flex;
				margin: 0px 0px 8px 0px;
			}
		}
	}

	&__social {
		position: relative;
		z-index: 2;
		@include mediaMax($sm) {
			display: none;
		}
		.social__label {
			text-align: right;
		}
		.social__icons {
			display: flex;
			@include list-reset();
			li {
				margin: 0px 12px 0px 0px;
				&:last-child {
					margin: 0px 0px 0px 0px;
				}
			}
		}
	}

	&__icon {
		display: block;
		&:hover {
			.icon-tw {
				fill: $color-white;
			}
		}
	}
}
