@use "sass:math";

.release {
	padding: 100px 0px 135px 0px;
	margin: 0px 0px 120px 0px;
	position: relative;
	@include mediaMax($lg) {
		margin: 0px 0px calc(125 / 1600) * 100vw 0px;
	}
	@include mediaMax($md) {
		padding: calc(55 / 1024) * 100vw 0px calc(80/ 1024) * 100vw 0px;
		margin: 0px 0px calc(108 / 1024) * 100vw 0px;
	}
	@include mediaMax($sm) {
		padding: 60px 0px 30px 0px;
		margin: 0px 0px;
	}
	&__container {
		position: relative;
		z-index: 2;
	}

	&__title {
		margin: 0px 0px 24px 0px;
		@include mediaMax($md) {
			margin: 0px 0px 27px 0px;
			// margin: 0px 0px 38/ 1024 * 100vw 0px;
		}
	}

	&__text {
		margin: 0px 0px 108px 0px;
		max-width: 418px;
		@include mediaMax($lg) {
			margin: 0px 0px math.div(125, 1600) * 100vw 0px;
		}
		@include mediaMax($md) {
			margin: 0px 0px math.div(87, 1024) * 100vw 0px;
		}
		@include mediaMax($sm) {
			max-width: 181px;
			margin: 0px 0px 20px 0px;
			// margin: 0px 0px 50/ 768 * 100vw 0px;
		}
	}

	&__buttons {
		@include mediaMax($sm) {
			display: inline-flex;
			flex-direction: column;

			min-width: 200px;
			width: auto;
		}
	}
	&__btn {
		display: none;
		@include mediaMin($sm) {
			display: inline-flex;
			margin: 0px 37px 0px 0px;
			&:nth-child(3) {
				margin: 0px 0px 0px 0px;
			}
		}
		@include mediaMax($md) {
			margin: 0px 25px 0px 0px;
		}
	}

	&__btn-mobile {
		display: none;
		border-left: $color-grey;
		@include mediaMax($sm) {
			display: inline-block;
			margin: 0px 0px 10px -16px;
			width: auto;
			.btn-inner {
				font-size: 16px;
				line-height: calc(20/16) * 1;
				padding: 0;
				background-color: $color-grey;
				border: none;
				min-height: 52px;
				padding: 3px 10px 6px 16px;
				span {
					padding: 0px 0px 0px 12px;
				}
			}
			.btn-icon {
				position: relative;
				right: 0;
				top: 0%;
				transform: none;
				min-width: 24px;
			}
			.btn__arrow {
				position: absolute;
				top: 0;
				right: 3px;
			}
			.btn__arrow-fill {
				fill: $color-grey;
			}
			.btn__arrow-stroke {
				stroke: $color-grey;
			}
			&:hover {
				.btn-arrow {
					width: auto;
				}
			}
		}
	}

	&__img {
		position: absolute;
		bottom: 0;
		right: 0;
		img {
			width: 100%;
			height: 100%;
			object-position: top right;
			object-fit: contain;
		}

		@include mediaMax($lg) {
			right: -135px;
		}
		@include mediaMax($md) {
			right: -118px;
			height: 137%;
		}
		@include mediaMax($sm) {
			right: -165px;
			height: 326px;
			width: 408px;
			img {
				transform: rotate(-12deg);
			}
		}
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-size: 100% 100%;
		background-color: $color-grey;
		@include mediaMax($sm) {
			background-image: none !important;
			background-color: transparent;
		}
	}
}
