.contacts {
	padding: 80px 0px 90px 0px;
	@include mediaMax($md) {
		padding: 60px 0px 80px 0px;
	}
	@include mediaMax($sm) {
		padding: 25px 0px 40px 0px;
	}
	&__container {
	}

	&__lang {
		@include list-reset();
		display: none;
		margin: 0px 0px 20px 0px;
		li {
			margin: 0px 15px 0px 0px;
			&:last-child {
				margin: 0px 0px 0px 0px;
			}
		}
		@include mediaMax($sm) {
			display: flex;
		}
	}

	&__icon {
		opacity: 0.4;
		&.active {
			opacity: 1;
		}
	}

	&__title {
		margin: 0px 0px 24px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 16px 0px;
		}
	}

	&__form {
		max-width: 680px;
		margin: 0px 0px 120px 0px;
		@include mediaMax($md) {
			max-width: none;
		}
	}

	&__offices {
		@include list-reset();
		display: flex;
		flex-wrap: wrap;
		margin: 0 -80px;
		@include mediaMax($lg) {
			margin: 0 -20px;
		}
		@include mediaMax($md) {
			display: block;
		}
	}

	&__office {
		flex: 0 1 33.333%;
		padding: 0 80px;
		@include mediaMax($lg) {
			padding: 0 20px;
		}
	}

	&__btn {
		background-color: $color-grey-9;
		padding: 8px 17px 8px 20px;
		outline: none;
		border: none;
		display: flex;
		align-items: center;
		margin: 0px 0px 32px 0px;
		user-select: none;
		span {
			font-size: 16px;
			line-height: 24px;
			letter-spacing: $ls;
			color: $color-white;
			padding: 0px 18px 0px 0px;
		}
		svg {
			margin: 2px 0px 0px 0px;
			transform: rotate(90deg);
			transition: all 0.3s ease;
		}
		&.active {
			svg {
				transform: rotate(-90deg);
			}
		}
	}

	&__other-offices {
		display: none;
	}
	&__other-offices-list {
		@include list-reset();
		margin: 0 -80px;
		display: flex;
		flex-wrap: wrap;
		@include mediaMax($md) {
			display: block;
		}
	}
	&__other-office {
		flex: 0 1 33.333%;
		padding: 0 80px;
		margin: 0px 0px 20px 0px;
	}
}

.form-contacts {
	&__select,
	&__input,
	&__textarea {
		margin: 0px 0px 36px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 28px 0px;
		}
	}

	&__input {
	}

	&__textarea {
		@include mediaMax($sm) {
			margin: 0px 0px 15px 0px;
		}
	}

	&__bottom {
	}
}

.office-block {
	margin: 0px 0px 40px 0px;
	@include mediaMax($sm) {
		margin: 0px 0px 60px 0px;
	}
	p {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-grey-6;
	}
	span {
		display: block;
		&:last-child {
			color: $color-white;
			font-weight: 600;
		}
	}
	&__title {
		margin: 0px 0px 8px 0px;
		white-space: nowrap;
		font-size: 32px;
		font-weight: 600;
		line-height: 40px;
		letter-spacing: $ls;
		text-transform: uppercase;
		color: $color-white;
		@include mediaMax($lg) {
			font-size: 24px;
		}
		@include mediaMax($sm) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__address {
		margin: 0px 0px 24px 0px;
		max-width: 320px;
		@include mediaMax($lg) {
			max-width: 287px;
		}
		@include mediaMax($md) {
			max-width: none;
		}
	}

	&__support,
	&__proposal,
	&__resume {
		margin: 0px 0px 12px 0px;
		@include mediaMax($md) {
			display: flex;
			span {
				flex: 0 1 34%;
			}
		}
		@include mediaMax($sm) {
			margin: 0px 0px 20px 0px;
			display: block;
		}

		& a {
			color: inherit;
			text-decoration: none;

			&:hover {
				opacity: 0.75;
			}
		}
	}

	&__proposal {
	}

	&__resume {
	}
}
.office-block-other {
	@include mediaMax($md) {
		margin: 0px 0px 40px 0px;
		&:last-child {
			margin: 0px 0px 0px 0px;
		}
	}
	&__location {
		font-size: 20px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-white;
		margin: 0px 0px 8px 0px;
	}

	&__address {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-grey-6;
		margin: 0;
	}
}
