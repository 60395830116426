.form {
	&--modal {
		padding-bottom: 28px;

		@include mediaMax($sm) {
			padding-bottom: 0;
		}

		.form__btn {
			@include mediaMax($sm) {
				position: relative;
				top: 0;
				left: 0;
				margin-top: 40px;
			}
		}

		&.form {
			& .form__row {
				@include mediaMax($sm) {
					margin: 0;
				}
			}
		}
	}

	&__fieldset {
		margin-top: 24px;

		&:first-child {
			margin-top: 0;
		}

		&-title {
			margin: 0 0 12px;
			user-select: none;

			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.04em;
			color: $color-white;

			@include mediaMax($sm) {
				font-size: 12px;
				line-height: 16px;
				color: rgba($color-white, 0.7);
			}

			&--mb-hide {
				@include mediaMax($sm) {
					display: none;
				}
			}
		}
	}

	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin: 0px 0px 39px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 0px 0px;
			&:nth-child(2) {
				margin: 0px 0px 20px 0px;
			}
			.space-m {
				margin: 0px 0px 12px 0px;
			}
		}
	}

	&__col {
		flex: 0 1 45.5%;
		@include mediaMax($sm) {
			flex: 1 1 100%;
			margin: 0 0 20px 0;
		}

		&-full {
			flex: 0 1 100%;
			@include mediaMax($sm) {
				flex: 1 1 100%;
				margin: 0 0 20px 0;
			}
		}
	}

	&__text {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: #e7e7e9;
		@include mediaMax($sm) {
			margin: 0px 0px 12px 0px;
		}
	}

	&__bottom {
		position: relative;
	}

	&__agreement-container {
		display: flex;
		align-items: center;
		@include mediaMax($sm) {
			margin: 17px 0px 0px 0px;
		}
	}

	&__checkbox-block {
		position: relative;

		&.open-list {
			& .checkbox-block__list {
				@include mediaMax($sm) {
					display: flex;
				}
			}
		}
		.checkbox-block {
			&__list {
				@include list-reset();
				display: flex;
				flex-wrap: wrap;

				margin: -18px -9px 0;

				@include mediaMax($sm) {
					display: none;
					position: absolute;
					top: 100%;
					left: 0;
					z-index: 100;

					width: 100%;
					margin: 0;

					border-top: 1px solid $color-grey-16;
					background-color: $color-grey;

					box-shadow: 0 12px 32px rgba(0, 0, 0, 0.5);

					flex-direction: column;
				}
			}

			&__text {
				display: flex;
				width: auto;
				margin: 18px 9px 0;

				& input:checked ~ .checkbox-block__label {
					color: $color-grey-dark;

					background-color: rgba($color-white, 1);
				}

				@include mediaMax($sm) {
					width: 100%;
					margin: 0;
				}
			}

			&__label,
			&__placeholder {
				display: flex;
				padding: 18px 24px;

				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.04em;
				color: rgba($color-white, 0.6);

				background-color: rgba(255, 255, 255, 0.1);
				cursor: pointer;

				transition: all 0.5s ease;

				& svg {
					margin-right: 10px;

					fill: currentColor;
					stroke: currentColor;

					align-self: center;
				}

				& .value {
					font: inherit;
					user-select: none;
				}

				&:hover {
					color: $color-white;
					background-color: rgba($color-white, 0.2);
				}

				@include mediaMax($sm) {
					padding: 14px 16px;
					width: 100%;
				}
			}

			&__placeholder {
				display: none;

				@include mediaMax($sm) {
					display: flex;

					&.active {
						color: $color-grey-dark;

						background-color: rgba($color-white, 1);
					}
				}
			}
		}
	}

	&__agreement {
		font-size: 12px;
		line-height: 16px;
		letter-spacing: $ls;
		color: $color-grey-6;
		padding: 0px 0px 0px 12px;
		max-width: 390px;
		@include mediaMax($sm) {
			font-size: 10px;
			line-height: 14px;
		}
	}

	&__link {
		color: $color-white;
	}

	&__btn {
		position: absolute;
		top: 0;
		left: calc(100% - 200px);
		background: none;
		border: none;
		padding: 0;
		margin: 0;
		cursor: pointer;
		width: 208px;
		text-align: left;
		.btn-arrow-red {
			width: 86%;
		}
		&:hover {
			width: 220px;
		}
		&:focus {
			outline: none;
		}
		@include mediaMax($sm) {
			top: 53px;
			left: 0;
			width: 284px;
			.btn-arrow-red {
				width: 90%;
			}
			.btn-arrow_bg {
				box-shadow: none;
			}
			&:hover {
				width: 286px;
				.btn-arrow-red {
					width: 93%;
				}
			}
		}
	}
	& .captcha-img{
		text-align: right;
		@include mediaMax($sm) {
			flex: 1 1 5%;
			text-align: center;
		}
	}
	& .captcha-input{
		@include mediaMax($sm) {
			flex: 1 1 10%;
		}
	}
}
