.career {
	position: relative;
	padding: 120px 0px 188px 0px;
	@include mediaMax($lg) {
		padding: calc(65 / 1600) * 100vw 0px calc(125 / 1600) * 100vw 0px;
	}
	@include mediaMax($md) {
		padding: 40px 0px 80px 0px;
	}
	&__container {
	}

	&__content {
		z-index: 3;
		position: relative;
		left: 13.5vw;
		@include mediaMax($lg) {
			left: 86px;
		}
		@include mediaMax($sm) {
			left: 0px;
			width: 100%;
			padding: 0px 16px;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__title {
		margin: 0px 0px 28px 0px;
		@include mediaMax($md) {
			margin: 0px 0px calc(28 / 1024) * 100vw 0px;
		}
	}

	&__text {
		max-width: 460px;
		margin: 0px 0px 57px 0px;
		@include mediaMax($md) {
			margin: 0px 0px calc(80 / 1024) * 100vw 0px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 35px 0px;
		}
	}

	&__btn {
		@include mediaMax($sm) {
			.btn-inner {
				font-size: 14px;
				line-height: calc(24/14) * 1;
			}
			.btn-icon {
				margin: 0px 0px 0px 16px;
			}
		}
	}

	&__frame {
		top: -20px;
		left: 8.125vw;
		width: 81.25%;
		@include mediaMax($lg) {
			left: 40px;
			width: 91.25%;
		}
		@include mediaMax($sm) {
			display: none;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		@include mediaMax($sm) {
			background-size: auto 100%;
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(180deg, rgba(22, 24, 37, 0) 0%, #161825 80.01%);
				background-size: auto 100%;
			}
		}
	}
}
