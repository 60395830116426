.release-info {
	background-color: $color-grey;
	padding: 134px 0px 156px 0px;
	&_mobile {
		padding: 74px 0px 104px 0px;
	}
	&_console {
		padding: 74px 0px 164px 0px;
	}
	@include mediaMax($lg) {
		padding: 80px 0px 55px 0px;
		background-color: $color-grey-dark;
		&_mobile {
			padding: 91px 0px 74px 0px;
		}
	}
	@include mediaMax($md) {
		padding: 15px 0px 77px 0px;
		&_mobile {
			padding: 23px 0px 74px 0px;
		}
	}
	@include mediaMax($sm) {
		padding: 40px 0px 20px 0px;
	}
	&__container {
		&_mobile {
			@include mediaMin($md) {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
		@include mediaMax($sm) {
			display: flex;
			flex-direction: column;
		}
	}

	&__content {
		display: flex;
		&_mobile {
			@include mediaMax($md) {
				flex-direction: row-reverse;
			}
		}
		&_pc {
			@include mediaMax($md) {
				display: block;
				flex-direction: row-reverse;
				.release-info__img {
					order: 2;
					padding: 0;
					margin: 0;
					flex: 0 0 300px;
				}
			}
		}
		@include mediaMax($sm) {
			flex-direction: column;
			.release-info__img {
				width: 260px;
				max-height: 167px;
			}
		}
	}

	&__left {
		flex: 0 0 540px;
		@include mediaMin($md) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		@include mediaMax($lg) {
			flex: 0 0 422px;
			padding: 45px 0px 0px 0px;
			.release-info__img {
				margin: 0;
			}
			&_mobile {
				padding: 0;
			}
		}
		@include mediaMax($md) {
			display: flex;
			margin: 0px 0px 31px 0px;
			&_mobile {
				padding: 0;
				flex: 0 0 300px;
				margin: 0;
			}
		}
		@include mediaMax($sm) {
			flex: 1 1 100%;
			flex-direction: column;
			margin: 0px 0px 22px 0px;
			padding: 0;
		}
	}

	&__right {
		margin: 0px 0px 0px 179px;
		flex: 0 1 560px;

		@include mediaMax($lg) {
			flex: 1 1 100%;
			margin: 0 0 0 44px;
		}
		@include mediaMax($md) {
			margin: 0px 0px 0px 0;
			&_mobile {
				flex: 1 1 100%;
				margin: 0 20px 0 0px;
			}
		}
		@include mediaMax($sm) {
			flex: 1 1 auto;
			&_mobile {
				margin: 0 0px 0 0px;
				width: 100%;
			}
		}
	}

	&__info {
		@include mediaMax($md) {
			margin-bottom: 35px;
		}
		&_mobile {
			p {
				font-size: 20px;
				line-height: 24px;
				@include mediaMax($lg) {
					font-size: 16px;
					line-height: 24px;
				}
				@include mediaMax($sm) {
					font-size: 12px;
					line-height: 16px;
				}
			}
		}
	}

	&__img {
		display: flex;
		justify-content: center;
		margin: 0px 0px 0px -10px;
		width: 100%;
		user-select: none;
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
		@include mediaMax($sm) {
			width: 260px;
			height: auto;
			align-self: center;
		}
	}

	&__btn-block {
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 62px 0px 0px 0px;
		@include mediaMax($lg) {
			padding: 50px 0px 0px 0px;
			&_mobile {
				justify-content: flex-start;
			}
		}
		@include mediaMax($md) {
			justify-content: start;
			&_mobile {
				padding: 5px 0px 0px 0px;
			}
		}
		@include mediaMax($sm) {
			flex-direction: column;
			align-items: center;
			padding: 0px 0px 0px 0px;
			width: 229px;
			margin: 0 auto;
		}
	}

	&__btn {
		display: inline-flex;
		overflow: visible;
		margin: 0px 0px 10px 36px;
		&:first-of-type {
			margin-left: 0;
		}
		.btn {
			width: 100%;
		}
		.btn-inner {
			display: flex;
			align-items: center;
			border: 1px solid $color-border-light;
			border-right: none;
			padding: 3px 21px 6px;
			span {
				margin-left: auto;
				padding: 0px 20px 0px 0px;
			}
		}
		.btn-icon {
			margin-right: auto;
			min-width: 24px;
		}
		&:hover {
		}
		.btn-wrapper {
			width: auto;
		}

		@include mediaMax($sm) {
			margin: 0px 0px 20px 0px;
			display: inline-flex;
			width: 231px;
			align-self: center;
			.btn-arrow {
				width: 100%;
				justify-content: center;
			}
			&:last-child {
				margin: 0px 0px 0px 0px;
			}
		}
	}

	&__title {
		padding: 18px 0px 0px 0px;
		margin: 0px 0px 40px 0px;
		font-weight: 600;
		font-size: 32px;
		line-height: calc(40/32) * 1;
		letter-spacing: $ls;
		text-transform: uppercase;
		color: $color-white;
		user-select: none;
		&_mobile {
			padding: 74px 0px 0px 0px;
			max-width: 452px;
		}
		@include mediaMax($lg) {
			padding: 0px 0px;
		}
		@include mediaMax($md) {
			font-size: 24px;
			line-height: 32px;
			flex: 1 1 auto;
			padding: 45px 44px 0px 0px;
			margin: 0px 0px 10px 0px;
		}
		@include mediaMax($sm) {
			font-size: 20px;
			line-height: 28px;
			padding: 0px;
			margin: 0px 0px 28px 0px;
		}
	}
}
