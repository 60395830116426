.burger{

  @include button-reset();

  position: relative;
  width: 24px;
  height: 39px;

  &:focus {
    outline: none;
  }
}

.burger span {
  position: absolute;
  -webkit-transition: .3s
}

.burger span  {
  display: block;
  position: absolute;
  right: 4px;
  width: 16px;
  height: 2px;


  background: $color-white;

  &:nth-child(2) {
    width: 14px;
    top: 19px;

    opacity: 1;

    transition: transform 0.3s ease-in-out;
  }

  &:first-child {
    top: 13px;

    transition: transform 0.3s ease-in-out;
  }

  &:last-child {
    bottom: 12px;

    transition: transform 0.3s ease-in-out;
  }
}