//<ОБНУЛЕНИЕ, ОБЩИЕ ПАРАМЕТРЫ>===============================================================================================
html,
body {
	height: 100%;
	min-width: $xs;
}
body {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	font-family: $font-commissioner, Arial, sans-serif;
	background-color: $color-grey-dark;
	color: $color-white;
	* {
		box-sizing: border-box;
	}
}

body.no-scroll-no-fixed {
	overflow: hidden;
	width: 100%;
	height: 100%;
}

button {
	cursor: pointer;
}
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}
a,
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}

a,
button,
.select__placeholder,
label {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=submit],
input[type=url],
textarea{
	-webkit-appearance: none;
}


textarea,
input,
button,
select {
	font-family: inherit;
	font-size: inherit;
}

img {
	vertical-align: top;
	max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
}
//</ОБНУЛЕНИЕ, ОБЩИЕ ПАРАМЕТРЫ>===============================================================================================

//<ОБОЛОЧКА>===============================================================================================
.wrapper {
	width: 100%;
	min-height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	padding-top: 80px;

	@include mediaMax($lg) {
		padding-top: 60px;
	}

	@include mediaMax($md) {
		padding-top: 52px;
	}

	@include mediaMax($sm) {
		padding-top: 40px;
	}
}
//</ОБОЛОЧКА>===============================================================================================

//<ОСНОВНАЯ СЕТКА>===========================================================================================================
.page {
	flex: 1 1 auto;
}

.container {
	max-width: $maxWidthContainer + px;
	width: 100%;
	margin: 0 auto;
	padding-left: 40px;
	padding-right: 40px;
	@include mediaMax($lg) {
		max-width: none;
	}
	@include mediaMax($sm) {
		padding-left: 16px;
		padding-right: 16px;
	}
}

.row {
	margin-left: -20px;
	margin-right: -20px;
	@include mediaMax($sm) {
		margin-left: -8px;
		margin-right: -8px;
	}
}

.ibg {
	background-repeat: no-repeat;
	background-position: center;
}
.ibg img {
	display: none;
}

// Main page
.title-main {
	margin: 0;
	user-select: none;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 48px;
	line-height: calc(60/48) * 1;
	letter-spacing: $ls;
	@include mediaMax($md) {
		font-size: 32px;
		line-height: 1.25;
	}
	@include mediaMax($sm) {
		font-size: 20px;
		line-height: calc(28 / 20) * 1;
	}
}
.text-main {
	font-size: 20px;
	line-height: 28px;
	letter-spacing: $ls;
	color: $color-grey-6;
	user-select: none;
	@include mediaMax($sm) {
		font-size: 12px;
		line-height: calc(16 / 12) * 1;
	}
}

.title-block {
	font-weight: 600;
	font-size: 32px;
	line-height: calc(40/32) * 1;
	letter-spacing: $ls;
	text-transform: uppercase;
	color: $color-white;
	user-select: none;
	@include mediaMax($sm) {
		font-size: 20px;
		line-height: 28px;
	}
}

.text-release {
	p {
		font-size: 16px;
		line-height: calc(24/16) * 1;
		letter-spacing: $ls;
		color: $color-grey-6;
		margin: 0px 0px 15px 0px;
		@include mediaMax($sm) {
			font-size: 12px;
			line-height: 16px;
		}
		&:nth-child(even) {
			font-weight: 600;
			font-size: 20px;
			line-height: calc(24/20) * 1;
			color: $color-white;
			@include mediaMax($sm) {
				font-size: 12px;
				line-height: 16px;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.frame {
	position: absolute;
	height: 100%;
	z-index: 2;
	height: 101.5%;
	&__img {
		height: 100%;
		width: 100%;
	}
}

.hidden {
	display: none;
}

.frame-content {
	z-index: 3;
	position: relative;
	margin: 0 auto;
	@include mediaMax($lg) {
		width: 100%;
	}
	@include mediaMax($sm) {
		left: 0px;
		width: 100%;
		padding: 0px 16px;
	}
}

.logo-text {
	@include mediaMax($lg) {
		.icon-logo {
			width: 479px;
			height: 69px;
		}
	}
	@include mediaMax($md) {
		.icon-logo {
			width: 360px;
		}
	}
	@include mediaMax($sm) {
		.icon-logo {
			width: 282px;
			height: 40px;
			// width: calc(677 / 768) * 100vw;
			// height: calc(96 / 768) * 100vw;
		}
	}
}

.hidden-desk {
	@include mediaMin(600px) {
		display: none;
	}
}

.frame-border {
	position: absolute;
	width: 100%;
	height: 100%;
	@include border-white();
	border-bottom: none;
	border-right: none;
	transition: all 0.5s ease;
	&__border {
		position: absolute;
		display: block;
		height: calc(100% - 24.25px);
		width: 1.5px;
		overflow: hidden;
		right: 0px;
		bottom: 24.25px;
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			right: 0px;
			bottom: 1.5px;
			transform: skewY(-45deg);
			background-color: $color-border-light;
		}
	}

	&__corner {
		width: 100%;
		height: 48px;
		position: absolute;
		display: block;
		overflow: hidden;
		right: 0px;
		bottom: 0px;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			right: 0px;
			bottom: 0px;
			transform: skew(-45deg);
			border-right: 2px solid $color-border-light;
			border-bottom: 1.6px solid $color-border-light;
			border-top: none;
			border-left: none;
		}
	}
}

.info {
	p,
	span,
	h3,
	ul > li {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: #ffffff;
		user-select: text;
		@include mediaMax($sm) {
			font-size: 12px;
			line-height: 16px;
		}
	}
	span,
	h3 {
		display: block;
		font-weight: 600;
		margin: 0px 0px 5px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 3px 0px;
		}
	}

	p,
	ul {
		margin: 0px 0px 20px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 10px 0px;
		}
	}
	ul {
		padding: 0;
		list-style: none;
		li {
			padding: 0px 0px 0px 13px;
			position: relative;
			margin: 0px 0px 3px 0px;
			&:before {
				content: "";
				position: absolute;
				top: 12px;
				left: 0;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: $color-white;
				@include mediaMax($sm) {
					top: 9px;
					width: 3.5px;
					height: 3.5px;
				}
			}
		}
	}
}

//</ОСНОВНАЯ СЕТКА>===========================================================================================================

// затемнение
.dark-body {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 20;

	background-color: rgba($color-black, 0.3);
	&.active {
		display: block;
	}
}

.blur {
	filter: blur(5px);
}

.captcha-img{
	text-align: right;
}

.visually-hidden:not(:focus):not(:active),
.visually-hidden[type="file"]:focus,
.visually-hidden[type="file"]:active,
.visually-hidden[type="checkbox"]:focus,
.visually-hidden[type="checkbox"]:active,
.visually-hidden[type="radio"]:focus,
.visually-hidden[type="radio"]:active {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	-webkit-clip-path: inset(100%);
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}
