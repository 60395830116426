@use "sass:math";

.presskit {
	padding: 80px 0px 160px 0px;
	@include mediaMax($lg) {
		padding: 60px 0px 48px 0px;
	}
	@include mediaMax($md) {
		padding: 41px 0px 28px 0px;
	}
	@include mediaMax($sm) {
		padding: 24px 0px 26px 0px;
	}
	&__container {
	}

	&__title {
		margin: 0px 0px 40px 0px;
		@include mediaMax($lg) {
			margin: 0px 0px 32px 0px;
		}
		@include mediaMax($md) {
			margin: 0px 0px 24px 0px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 20px 0px;
		}
	}

	&__search {
		margin: 0px 0px 40px 0px;
		width: 480px;
		@include mediaMax($md) {
			width: 448px;
		}
		@include mediaMax($sm) {
			width: 100%;
		}
	}

	&__list {
		@include list-reset();
		display: flex;
		flex-wrap: wrap;
		margin: 0px -20px;
		@include mediaMax(1400px) {
			margin: 0px -16px;
		}
		@include mediaMax(600px) {
			margin: 0px -4px;
		}
	}

	&__item {
		width: 25%;
		padding: 0px 20px;
		margin: 0px 0px 40px 0px;
		height: 230px;
		.image-crop__img {
			transform: rotate(45deg) translate(-81px, -49px);
			img {
				transform: rotate(-45deg) translate(-58.5px, 57px);
			}
		}
		@include mediaMax($lg) {
			.image-crop__img {
				transform: rotate(45deg) translate(-79px, -60px);
				img {
					transform: rotate(-45deg) translate(-69px, 64px);
				}
			}
		}
		@include mediaMax(1400px) {
			padding: 0px 16px;
			height: 200px;
			margin: 0px 0px 32px 0px;
			.image-crop__img {
				transform: rotate(45deg) translate(-68px, -60px);
				img {
					transform: rotate(-45deg) translate(-65px, 62px);
				}
			}
		}
		@include mediaMax(1200px) {
			.image-crop__info {
				padding: 5px 10px 10px;
			}
		}
		@include mediaMax($md) {
			flex: 0 1 33.333%;
			.image-crop__img {
				transform: rotate(45deg) translate(-70px, -60px);
				img {
					transform: rotate(-45deg) translate(-63px, 64px);
				}
			}
		}
		> .image-crop__img {
			top: -16px;
			left: 0px;
			img {
				// margin-top: 115px;
				width: 100%;
				height: auto;
			}
		}
		@include mediaMax($sm) {
			flex: 0 1 50%;
			height: 29vw;
			max-height: 200px;
			min-height: 120px;
			.image-crop__img {
				transform: rotate(45deg) translate(-10vw, -10vw);
				img {
					transform: rotate(-45deg) translate(-10.3vw, 10vw);
				}
			}
			.image-crop__info {
				padding: 10px;
			}
		}
		@include mediaMax(600px) {
			padding: 0px 4px;
			margin: 0px 0px 8px 0px;
			.image-crop__info {
				padding: 6px;
			}
		}
		@include mediaMax(420px) {
			.image-crop__img {
				transform: rotate(45deg) translate(-42px, -42px);
				img {
					transform: rotate(-45deg) translate(-42.5px, 42.5px);
				}
			}
		}
	}

	&__content {
		@include mediaMax(600px) {
			width: math.div(136, 140) * 100%;
			height: math.div(116, 120) * 100%;
			padding: 8px;
		}
	}

	&__image-title {
		@include mediaMax(600px) {
			font-size: 12px;
			line-height: 16px;
		}
	}

	&__info {
		display: flex;
		justify-content: space-between;
	}

	&__btn-about {
		margin: 0px 0px 12px 0px;
		@include mediaMax(600px) {
			padding: 6px;
			margin: 0px 0px 5px 0px;
			.btn-inner {
				font-size: 10px;
				line-height: 12px;
			}
		}
	}

	&__btn-download {
		@include mediaMax(600px) {
			padding: 5px;
			.download__text_sm {
				font-size: 8px;
				line-height: 10px;
			}
			&:after {
				width: 27px;
				height: 27px;
				top: -17px;
				right: -17px;
			}
			&:before {
				border: 8px solid transparent;
				border-right: 8px solid #161825;
				top: 5px;
				right: 5px;
			}
		}
	}

	&__image-crop {
	}
}
