@font-face {
	font-family: "Commissioner";
	src: url("./../fonts/Commissioner-Bold.woff2") format("woff2"),
		url("./../fonts/Commissioner-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Commissioner";
	src: url("./../fonts/Commissioner-SemiBold.woff2") format("woff2"),
		url("./../fonts/Commissioner-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

//@font-face {
//	font-family: "Commissione";
//	src: url("./../fonts/Commissioner-Medium.woff2") format("woff2"),
//	url("./../fonts/Commissioner-Medium.woff") format("woff"),
//	url("./../fonts/Commissioner-Medium.ttf") format("ttf");
//	font-weight: 500;
//	font-style: normal;
//	font-display: swap;
//}

@font-face {
	font-family: "Commissioner";
	src: url("./../fonts/Commissioner-Regular.woff2") format("woff2"),
		url("./../fonts/Commissioner-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
