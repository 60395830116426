@charset "UTF-8";
@font-face {
  font-family: "Commissioner";
  src: url("./../fonts/Commissioner-Bold.woff2") format("woff2"), url("./../fonts/Commissioner-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Commissioner";
  src: url("./../fonts/Commissioner-SemiBold.woff2") format("woff2"), url("./../fonts/Commissioner-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Commissioner";
  src: url("./../fonts/Commissioner-Regular.woff2") format("woff2"), url("./../fonts/Commissioner-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/*Font family*/
/* Colors*/
html,
body {
  height: 100%;
  min-width: 320px;
}

body {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: "Commissioner", Arial, sans-serif;
  background-color: #161825;
  color: #ffffff;
}
body * {
  box-sizing: border-box;
}

body.no-scroll-no-fixed {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a,
button,
.select__placeholder,
label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=submit],
input[type=url],
textarea {
  -webkit-appearance: none;
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

img {
  vertical-align: top;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}
@media only screen and (max-width: 1599px) {
  .wrapper {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 1023px) {
  .wrapper {
    padding-top: 52px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding-top: 40px;
  }
}

.page {
  flex: 1 1 auto;
}

.container {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}
@media only screen and (max-width: 1599px) {
  .container {
    max-width: none;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.row {
  margin-left: -20px;
  margin-right: -20px;
}
@media only screen and (max-width: 767px) {
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
}

.ibg {
  background-repeat: no-repeat;
  background-position: center;
}

.ibg img {
  display: none;
}

.title-main {
  margin: 0;
  user-select: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 48px;
  line-height: 1.25;
  letter-spacing: 0.04em;
}
@media only screen and (max-width: 1023px) {
  .title-main {
    font-size: 32px;
    line-height: 1.25;
  }
}
@media only screen and (max-width: 767px) {
  .title-main {
    font-size: 20px;
    line-height: 1.4;
  }
}

.text-main {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .text-main {
    font-size: 12px;
    line-height: 1.3333333333;
  }
}

.title-block {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .title-block {
    font-size: 20px;
    line-height: 28px;
  }
}

.text-release p {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px 0px 15px 0px;
}
@media only screen and (max-width: 767px) {
  .text-release p {
    font-size: 12px;
    line-height: 16px;
  }
}
.text-release p:nth-child(even) {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: #ffffff;
}
@media only screen and (max-width: 767px) {
  .text-release p:nth-child(even) {
    font-size: 12px;
    line-height: 16px;
  }
}
.text-release p:last-child {
  margin-bottom: 0;
}

.frame {
  position: absolute;
  height: 100%;
  z-index: 2;
  height: 101.5%;
}
.frame__img {
  height: 100%;
  width: 100%;
}

.hidden {
  display: none;
}

.frame-content {
  z-index: 3;
  position: relative;
  margin: 0 auto;
}
@media only screen and (max-width: 1599px) {
  .frame-content {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .frame-content {
    left: 0px;
    width: 100%;
    padding: 0px 16px;
  }
}

@media only screen and (max-width: 1599px) {
  .logo-text .icon-logo {
    width: 479px;
    height: 69px;
  }
}
@media only screen and (max-width: 1023px) {
  .logo-text .icon-logo {
    width: 360px;
  }
}
@media only screen and (max-width: 767px) {
  .logo-text .icon-logo {
    width: 282px;
    height: 40px;
  }
}

@media only screen and (min-width: 600px) {
  .hidden-desk {
    display: none;
  }
}

.frame-border {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  border-bottom: none;
  border-right: none;
  transition: all 0.5s ease;
}
.frame-border__border {
  position: absolute;
  display: block;
  height: calc(100% - 24.25px);
  width: 1.5px;
  overflow: hidden;
  right: 0px;
  bottom: 24.25px;
}
.frame-border__border:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;
  bottom: 1.5px;
  transform: skewY(-45deg);
  background-color: rgba(255, 255, 255, 0.4);
}
.frame-border__corner {
  width: 100%;
  height: 48px;
  position: absolute;
  display: block;
  overflow: hidden;
  right: 0px;
  bottom: 0px;
}
.frame-border__corner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;
  bottom: 0px;
  transform: skew(-45deg);
  border-right: 2px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1.6px solid rgba(255, 255, 255, 0.4);
  border-top: none;
  border-left: none;
}

.info p,
.info span,
.info h3,
.info ul > li {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  user-select: text;
}
@media only screen and (max-width: 767px) {
  .info p,
  .info span,
  .info h3,
  .info ul > li {
    font-size: 12px;
    line-height: 16px;
  }
}
.info span,
.info h3 {
  display: block;
  font-weight: 600;
  margin: 0px 0px 5px 0px;
}
@media only screen and (max-width: 767px) {
  .info span,
  .info h3 {
    margin: 0px 0px 3px 0px;
  }
}
.info p,
.info ul {
  margin: 0px 0px 20px 0px;
}
@media only screen and (max-width: 767px) {
  .info p,
  .info ul {
    margin: 0px 0px 10px 0px;
  }
}
.info ul {
  padding: 0;
  list-style: none;
}
.info ul li {
  padding: 0px 0px 0px 13px;
  position: relative;
  margin: 0px 0px 3px 0px;
}
.info ul li:before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ffffff;
}
@media only screen and (max-width: 767px) {
  .info ul li:before {
    top: 9px;
    width: 3.5px;
    height: 3.5px;
  }
}

.dark-body {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
}
.dark-body.active {
  display: block;
}

.blur {
  filter: blur(5px);
}

.captcha-img {
  text-align: right;
}

.visually-hidden:not(:focus):not(:active),
.visually-hidden[type=file]:focus,
.visually-hidden[type=file]:active,
.visually-hidden[type=checkbox]:focus,
.visually-hidden[type=checkbox]:active,
.visually-hidden[type=radio]:focus,
.visually-hidden[type=radio]:active {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.fancybox-enabled {
  overflow: hidden;
}

.fancybox-enabled body {
  overflow: visible;
  height: 100%;
}

.fancybox-is-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.fancybox-container ~ .fancybox-container {
  z-index: 99992;
}

.fancybox-bg,
.fancybox-inner,
.fancybox-outer,
.fancybox-stage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fancybox-outer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-caption-wrap,
.fancybox-infobar,
.fancybox-toolbar {
  position: absolute;
  direction: ltr;
  z-index: 99997;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, visibility 0s linear 0.25s;
  box-sizing: border-box;
}

.fancybox-show-caption .fancybox-caption-wrap,
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.25s, visibility 0s;
}

.fancybox-infobar {
  top: 0;
  left: 50%;
  margin-left: -79px;
}

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}

.fancybox-toolbar {
  top: 0;
  right: 0;
}

.fancybox-stage {
  overflow: hidden;
  direction: ltr;
  z-index: 99994;
  -webkit-transform: translateZ(0);
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  display: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.fancybox-slide:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--current,
.fancybox-slide--next,
.fancybox-slide--previous {
  display: block;
}

.fancybox-slide--image {
  overflow: visible;
}

.fancybox-slide--image:before {
  display: none;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video iframe {
  background: #000;
}

.fancybox-slide--map .fancybox-content,
.fancybox-slide--map iframe {
  background: #e5e3df;
}

.fancybox-slide--next {
  z-index: 99995;
}

.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}

.fancybox-slide .fancybox-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-can-zoomOut .fancybox-image-wrap {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-image-wrap {
  cursor: zoom-in;
}

.fancybox-can-drag .fancybox-image-wrap {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-dragging .fancybox-image-wrap {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--iframe .fancybox-content {
  padding: 0;
  width: 80%;
  height: 80%;
  max-width: calc(100% - 100px);
  max-height: calc(100% - 88px);
  overflow: visible;
  background: #fff;
}

.fancybox-iframe {
  display: block;
  padding: 0;
  border: 0;
  height: 100%;
}

.fancybox-error,
.fancybox-iframe {
  margin: 0;
  width: 100%;
  background: #fff;
}

.fancybox-error {
  padding: 40px;
  max-width: 380px;
  cursor: default;
}

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px Helvetica Neue, Helvetica, Arial, sans-serif;
}

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer;
}

.fancybox-close-small:after {
  content: "×";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 20px/30px Arial, Helvetica Neue, Helvetica, sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background: #fff;
  transition: background 0.25s;
  box-sizing: border-box;
  z-index: 2;
}

.fancybox-close-small:focus:after {
  outline: 1px dotted #888;
}

.fancybox-close-small:hover:after {
  color: #555;
  background: #eee;
}

.fancybox-slide--iframe .fancybox-close-small {
  top: 0;
  right: -44px;
}

.fancybox-slide--iframe .fancybox-close-small:after {
  background: transparent;
  font-size: 35px;
  color: #aaa;
}

.fancybox-slide--iframe .fancybox-close-small:hover:after {
  color: #fff;
}

.fancybox-caption-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 30px 0;
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8));
  pointer-events: none;
}

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid hsla(0, 0%, 100%, 0.4);
  font-size: 14px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none;
}

.fancybox-caption a,
.fancybox-caption button,
.fancybox-caption select {
  pointer-events: all;
}

.fancybox-caption a {
  color: #fff;
  text-decoration: underline;
}

.fancybox-button {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: transparent;
  color: #ddd;
  border-radius: 0;
  cursor: pointer;
  vertical-align: top;
  outline: none;
}

.fancybox-button[disabled] {
  cursor: default;
  pointer-events: none;
}

.fancybox-button,
.fancybox-infobar__body {
  background: rgba(30, 30, 30, 0.6);
}

.fancybox-button:hover:not([disabled]) {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
}

.fancybox-button:after,
.fancybox-button:before {
  content: "";
  pointer-events: none;
  position: absolute;
  background-color: currentColor;
  color: currentColor;
  opacity: 0.9;
  box-sizing: border-box;
  display: inline-block;
}

.fancybox-button[disabled]:after,
.fancybox-button[disabled]:before {
  opacity: 0.3;
}

.fancybox-button--left:after,
.fancybox-button--right:after {
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
}

.fancybox-button--left:after {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.fancybox-button--right:after {
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--left {
  border-bottom-left-radius: 5px;
}

.fancybox-button--right {
  border-bottom-right-radius: 5px;
}

.fancybox-button--close:after,
.fancybox-button--close:before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 16px;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
}

.fancybox-button--close:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.fancybox-arrow {
  position: absolute;
  top: 50%;
  margin: -50px 0 0;
  height: 100px;
  width: 54px;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  z-index: 99995;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity 0.25s;
}

.fancybox-arrow:after {
  content: "";
  position: absolute;
  top: 28px;
  width: 44px;
  height: 44px;
  background-color: rgba(30, 30, 30, 0.8);
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 24px 24px;
}

.fancybox-arrow--right {
  right: 0;
}

.fancybox-arrow--left {
  left: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.fancybox-arrow--left:after,
.fancybox-arrow--right:after {
  left: 0;
}

.fancybox-show-nav .fancybox-arrow {
  opacity: 0.6;
}

.fancybox-show-nav .fancybox-arrow[disabled] {
  opacity: 0.3;
}

.fancybox-loading {
  border: 6px solid hsla(0, 0%, 39%, 0.4);
  border-top: 6px solid hsla(0, 0%, 100%, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: a 0.8s infinite linear;
  animation: a 0.8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999;
}

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--current {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
}

.fancybox-fx-fade.fancybox-slide--next,
.fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  opacity: 1;
}

.fancybox-fx-rotate.fancybox-slide--previous {
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn);
  opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--next {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--current {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}

.fancybox-fx-circular.fancybox-slide--previous {
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--next {
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--current {
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  opacity: 1;
}

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
}

@media (max-width: 800px) {
  .fancybox-infobar {
    left: 0;
    margin-left: 0;
  }
  .fancybox-button--left,
  .fancybox-button--right {
    display: none !important;
  }
  .fancybox-caption {
    padding: 20px 0;
    margin: 0;
  }
}
.fancybox-button--fullscreen:before {
  width: 15px;
  height: 11px;
  left: calc(50% - 7px);
  top: calc(50% - 6px);
  border: 2px solid;
  background: none;
}

.fancybox-button--pause:before,
.fancybox-button--play:before {
  top: calc(50% - 6px);
  left: calc(50% - 4px);
  background: transparent;
}

.fancybox-button--play:before {
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px inset transparent;
  border-left: 10px solid;
  border-radius: 1px;
}

.fancybox-button--pause:before {
  width: 7px;
  height: 11px;
  border-style: solid;
  border-width: 0 2px;
}

.fancybox-button--thumbs,
.fancybox-thumbs {
  display: none;
}

@media (min-width: 800px) {
  .fancybox-button--thumbs {
    display: inline-block;
  }
  .fancybox-button--thumbs span {
    font-size: 23px;
  }
  .fancybox-button--thumbs:before {
    width: 3px;
    height: 3px;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
    box-shadow: 0 -4px 0, -4px -4px 0, 4px -4px 0, inset 0 0 0 32px, -4px 0 0, 4px 0 0, 0 4px 0, -4px 4px 0, 4px 4px 0;
  }
  .fancybox-thumbs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    width: 220px;
    margin: 0;
    padding: 5px 5px 0 0;
    background: #fff;
    word-break: normal;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    box-sizing: border-box;
    z-index: 99995;
  }
  .fancybox-show-thumbs .fancybox-thumbs {
    display: block;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 220px;
  }
  .fancybox-thumbs > ul {
    list-style: none;
    position: absolute;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 0;
  }
  .fancybox-thumbs > ul > li {
    float: left;
    overflow: hidden;
    max-width: 50%;
    padding: 0;
    margin: 0;
    width: 105px;
    height: 75px;
    position: relative;
    cursor: pointer;
    outline: none;
    border: 5px solid transparent;
    border-top-width: 0;
    border-right-width: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-sizing: border-box;
  }
  li.fancybox-thumbs-loading {
    background: rgba(0, 0, 0, 0.1);
  }
  .fancybox-thumbs > ul > li > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    max-height: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .fancybox-thumbs > ul > li:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 2px;
    border: 4px solid #4ea7f9;
    z-index: 99991;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
    opacity: 1;
  }
}
.buttons-ui {
  list-style: none;
  padding: 50px;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.buttons-ui li {
  display: inline-flex;
  margin: 0px 0px 40px 0px;
  background-color: #161825;
}

.btn {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.btn-inner {
  font-weight: 600;
  letter-spacing: 0.04em;
  transition: all 0.5s ease;
  color: #ffffff;
  position: relative;
  z-index: 2;
  user-select: none;
}

.btn-icon {
  transition: all 0.5s ease;
  z-index: 2;
}

.btn-red {
  background-color: #ff1812;
  padding: 16px 38px 16px 24px;
  position: relative;
  box-shadow: 0px 38px 80px rgba(255, 59, 0, 0.24), 0px 15.8755px 33.4221px rgba(255, 59, 0, 0.172525), 0px 8.4878px 17.869px rgba(255, 59, 0, 0.143066), 0px 4.75819px 10.0172px rgba(255, 59, 0, 0.12), 0px 2.52704px 5.32008px rgba(255, 59, 0, 0.0969343), 0px 1.05156px 2.21381px rgba(255, 59, 0, 0.0674749);
  transition: all 0.5s ease;
}
.btn-red:hover {
  filter: none;
}
.btn-red:active {
  filter: none;
}
.btn-red .btn-icon {
  margin: 0px 12px 0px 0px;
}
.btn-red .btn-inner {
  font-size: 16px;
  line-height: 1.5;
}
.btn-red:after {
  content: "";
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  opacity: 0.4;
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 2;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  transition: all 0.5s ease;
}
.btn-red:hover {
  box-shadow: none;
}
.btn-red:hover:after {
  top: 0px;
  left: 0px;
}
.btn-red:hover {
  background-color: #ea0600;
  border-color: #ea0600;
}
.btn-red:active {
  background-color: #bc1713;
  border-color: #bc1713;
}

.btn-arrow {
  border-right: none;
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}
.btn-arrow:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: calc(100% - 1px);
  height: calc(50% - 1px);
  transform: skewX(27deg);
  transform-origin: right top;
  background-color: transparent;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  transition: all 0.5s ease;
}
.btn-arrow:after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: calc(100% - 1px);
  height: calc(50% - 1px);
  transform: skewX(-27deg);
  transform-origin: right bottom;
  background-color: transparent;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  transition: all 0.5s ease;
}
.btn-arrow_bg {
  background-color: #ff1812;
  box-shadow: 0px 38px 80px rgba(255, 59, 0, 0.24), 0px 15.8755px 33.4221px rgba(255, 59, 0, 0.172525), 0px 8.4878px 17.869px rgba(255, 59, 0, 0.143066), 0px 4.75819px 10.0172px rgba(255, 59, 0, 0.12), 0px 2.52704px 5.32008px rgba(255, 59, 0, 0.0969343), 0px 1.05156px 2.21381px rgba(255, 59, 0, 0.0674749);
}
.btn-arrow_bg:hover {
  filter: none;
}
.btn-arrow_bg:active {
  filter: none;
}
.btn-arrow_bg:before, .btn-arrow_bg:after {
  background-color: #ff1812;
  border-top: none;
  border-bottom: none;
  height: 50%;
}
.btn-arrow_bg:before {
  top: 0.5px;
}
.btn-arrow_bg:after {
  bottom: 0.5px;
}
.btn-arrow_bg:hover:before, .btn-arrow_bg:hover:after {
  border-top: none !important;
  border-bottom: none !important;
}
.btn-arrow .btn-inner {
  font-size: 20px;
  line-height: 1.2;
}
@media only screen and (max-width: 767px) {
  .btn-arrow .btn-inner {
    font-size: 16px;
  }
}

.btn-wrapper {
  width: 274px;
  transition: all 0.5s ease;
  text-decoration: none;
  position: relative;
}
.btn-wrapper .btn-icon-red {
  transition: all 0.5s ease;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: auto;
  height: 100%;
}
.btn-wrapper_red .btn-arrow {
  border-color: #ff1812;
}
.btn-wrapper_red .btn-arrow:before, .btn-wrapper_red .btn-arrow:after {
  border-color: #ff1812;
}
.btn-wrapper_red:hover .btn-arrow {
  border-color: #ea0600;
}
.btn-wrapper_red:hover .btn-arrow_bg {
  background-color: #ea0600;
  box-shadow: none;
}
.btn-wrapper_red:active .btn-arrow {
  border-color: #bc1713;
}
.btn-wrapper_red:active .btn-arrow_bg {
  background-color: #bc1713;
}
.btn-wrapper_hv:hover {
  width: 286px;
}
.btn-wrapper_hv:hover .btn-arrow-red {
  width: 93%;
}
.btn-wrapper_hv:hover .btn-icon-red {
  fill: #ea0600;
}
.btn-wrapper_hv:hover .btn-arrow:before {
  border-right: 1px solid #ea0600;
  background-color: #ea0600;
}
.btn-wrapper_hv:hover .btn-arrow:after {
  border-right: 1px solid #ea0600;
  background-color: #ea0600;
}
.btn-wrapper_hv:active .btn-icon-red {
  fill: #bc1713;
}
.btn-wrapper_hv:active .btn-arrow:before {
  border-right: 1px solid #bc1713;
  background-color: #bc1713;
}
.btn-wrapper_hv:active .btn-arrow:after {
  border-right: 1px solid #bc1713;
  background-color: #bc1713;
}
.btn-wrapper_transparent {
  overflow: hidden;
}
.btn-wrapper_transparent .btn-arrow {
  padding: 0;
  border: none;
}
.btn-wrapper_transparent .btn-arrow:before, .btn-wrapper_transparent .btn-arrow:after {
  display: none;
}
.btn-wrapper_transparent .btn__arrow {
  width: auto;
}
.btn-wrapper_transparent .btn-arrow-red {
  width: 93%;
}
.btn-wrapper_transparent .btn-inner {
  width: calc(100% - 17px);
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-right: none;
  padding: 3px 21px 6px 21px;
  min-height: 60px;
}
.btn-wrapper_transparent .btn-inner span {
  padding: 0px 20px 0px 0px;
}
.btn-wrapper_transparent .btn-arrow-red {
  width: 94%;
}
.btn-wrapper_transparent .btn__arrow-fill {
  fill: transparent;
  transition: all 0.5s ease;
}
.btn-wrapper_transparent .btn__arrow-stroke {
  stroke: rgba(255, 255, 255, 0.4);
  transition: all 0.5s ease;
}
.btn-wrapper_transparent:hover .btn-arrow {
  width: 99%;
}
.btn-wrapper_transparent:hover .btn-inner {
  background-color: #ea0600;
  border-color: #ea0600;
}
.btn-wrapper_transparent:hover .btn__arrow-fill {
  fill: #ea0600;
}
.btn-wrapper_transparent:hover .btn__arrow-stroke {
  stroke: #ea0600;
}
.btn-wrapper_transparent:active .btn-arrow {
  width: 98%;
}
.btn-wrapper_transparent:active .btn-inner {
  background-color: #bc1713;
  border-color: #bc1713;
}
.btn-wrapper_transparent:active .btn__arrow-fill {
  fill: #bc1713;
}
.btn-wrapper_transparent:active .btn__arrow-stroke {
  stroke: #bc1713;
}
.btn-wrapper_white {
  overflow: hidden;
}
.btn-wrapper_white .btn__arrow-fill {
  fill: transparent;
  transition: all 0.5s ease;
}
.btn-wrapper_white .btn__arrow-stroke {
  stroke: rgba(255, 255, 255, 0.4);
  transition: all 0.5s ease;
}
.btn-wrapper_white .btn .btn__arrow {
  width: auto;
}
.btn-wrapper_white:hover .btn-inner {
  color: #161825;
  border-color: #f5f5f5;
}
.btn-wrapper_white:hover .btn-icon {
  fill: #161825;
  z-index: 2;
}
.btn-wrapper_white:hover .btn-inner {
  background-color: #f5f5f5;
}
.btn-wrapper_white:hover .btn__arrow-fill {
  fill: #f5f5f5;
}
.btn-wrapper_white:hover .btn__arrow-stroke {
  stroke: #f5f5f5;
}
.btn-wrapper_white:active .btn-inner {
  background-color: #cbcbcb;
  color: #161825;
  border-color: #cbcbcb;
}
.btn-wrapper_white:active .btn-icon {
  fill: #161825;
  z-index: 2;
}
.btn-wrapper_white:active .btn__arrow-fill {
  fill: #cbcbcb;
}
.btn-wrapper_white:active .btn__arrow-stroke {
  stroke: #cbcbcb;
}

.btn-arrow-red {
  width: 88%;
  position: relative;
  transition: all 0.5s ease;
  padding: 3px 21px 6px;
  min-height: 60px;
}
@media only screen and (max-width: 767px) {
  .btn-arrow-red {
    min-height: 50px;
  }
}

.btn-arrow-base {
  width: 91%;
  position: relative;
}
.btn-arrow-base .btn-icon {
  transition: all 0.5s ease;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 2;
  right: 0;
}
@media only screen and (max-width: 767px) {
  .btn-arrow-base .btn-inner {
    font-size: 16px;
    line-height: 24px;
  }
  .btn-arrow-base:before {
    transform: skewX(35deg);
  }
  .btn-arrow-base:after {
    transform: skewX(-35deg);
  }
}

.btn-transparent .btn-inner {
  font-size: 20px;
  line-height: 1.2;
}
@media only screen and (max-width: 767px) {
  .btn-transparent .btn-inner {
    font-size: 14px;
    line-height: 24px;
  }
}
.btn-transparent .btn-icon {
  margin: 2.5px 0px 0px 20px;
  transition: all 0.5s ease;
}
.btn-transparent:hover .btn-icon {
  stroke: #ea0600;
}
.btn-transparent:hover .btn-inner {
  color: #f5f5f5;
}
.btn-transparent:active .btn-icon {
  stroke: #bc1713;
}
.btn-transparent:active .btn-inner {
  color: #cbcbcb;
}

.btn-rect {
  position: relative;
  filter: drop-shadow(0px 15.8755px 33.4221px rgba(255, 255, 255, 0.172525)) drop-shadow(0px 8.4878px 17.869px rgba(255, 255, 255, 0.143066)) drop-shadow(0px 4.75819px 10.0172px rgba(255, 255, 255, 0.12)) drop-shadow(0px 2.52704px 5.32008px rgba(255, 255, 255, 0.0969343)) drop-shadow(0px 1.05156px 2.21381px rgba(255, 255, 255, 0.0674749));
  transition: all 0.5s ease;
}
.btn-rect:hover, .btn-rect:active {
  filter: none;
}
.btn-rect:hover .btn-inner {
  background-color: #f5f5f5;
}
.btn-rect:active .btn-inner {
  background-color: #cbcbcb;
}
.btn-rect .btn-inner {
  background-color: #ffffff;
  color: #161825;
  font-size: 20px;
  line-height: 1.2;
  padding: 16px 12px;
  position: relative;
  z-index: 2;
}

.btn-rect-white {
  position: relative;
  transition: all 0.5s ease;
  background: #fff;
  background: linear-gradient(135deg, transparent 11%, #ffffff 11% 89%, transparent 89%);
  background: -webkit-linear-gradient(135deg, transparent 11%, #ffffff 11% 89%, transparent 89%);
  background: -ms-linear-gradient(135deg, transparent 11%, #ffffff 11% 89%, transparent 89%);
  background: -o-linear-gradient(135deg, transparent 11%, #ffffff 11% 89%, transparent 89%);
  background: -moz-linear-gradient(135deg, transparent 11%, #ffffff 11% 89%, transparent 89%);
  filter: drop-shadow(0px 15.8755px 33.4221px rgba(255, 255, 255, 0.172525)) drop-shadow(0px 8.4878px 17.869px rgba(255, 255, 255, 0.143066)) drop-shadow(0px 4.75819px 10.0172px rgba(255, 255, 255, 0.12)) drop-shadow(0px 2.52704px 5.32008px rgba(255, 255, 255, 0.0969343)) drop-shadow(0px 1.05156px 2.21381px rgba(255, 255, 255, 0.0674749));
}
.btn-rect-white:hover, .btn-rect-white:active {
  filter: none;
}
.btn-rect-white:hover {
  background: #f5f5f5;
  background: linear-gradient(135deg, transparent 11%, #f5f5f5 11% 89%, transparent 89%);
}
.btn-rect-white:active {
  background: linear-gradient(135deg, transparent 11%, #cbcbcb 11% 89%, transparent 89%);
}
@media only screen and (max-width: 767px) {
  .btn-rect-white {
    background: linear-gradient(135deg, transparent 6%, #ffffff 6% 94%, transparent 93%);
  }
  .btn-rect-white:hover {
    background: linear-gradient(135deg, transparent 6%, #f5f5f5 6% 94%, transparent 93%);
  }
  .btn-rect-white:active {
    background: linear-gradient(135deg, transparent 6%, #cbcbcb 6% 94%, transparent 93%);
  }
}
.btn-rect-white .btn-inner {
  padding: 14px 36px;
  font-size: 20px;
  line-height: 24px;
  color: #161825;
}
@media only screen and (max-width: 767px) {
  .btn-rect-white .btn-inner {
    font-size: 16px;
    line-height: 24px;
  }
}

.btn-rect-grey {
  position: relative;
  transition: all 0.5s ease;
  background-image: linear-gradient(135deg, transparent 11%, rgba(255, 255, 255, 0.1) 11% 89%, transparent 89%);
}
@media only screen and (max-width: 767px) {
  .btn-rect-grey {
    background-image: linear-gradient(135deg, transparent 6%, rgba(255, 255, 255, 0.1) 6% 94%, transparent 93%);
  }
}
.btn-rect-grey .btn-inner {
  padding: 16px 36px;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
@media only screen and (max-width: 767px) {
  .btn-rect-grey .btn-inner {
    font-size: 16px;
    line-height: 24px;
  }
}

.btn-base {
  padding: 15px;
  width: 100%;
  background: rgba(255, 255, 255, 0.12);
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  transition: all 0.5s ease;
  justify-content: center;
}
.btn-base:hover {
  background-color: #f5f5f5;
}
.btn-base:active {
  background-color: #cbcbcb;
}
.btn-base:hover .btn-inner, .btn-base:active .btn-inner {
  color: #161825;
}

.btn-presskit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  padding: 25px 76.5px 31px 51px;
}
.btn-presskit:after {
  box-shadow: 0 0 0 1900px #ffffff;
  transform: rotate(45deg);
  transition: all 0.3s ease;
}
.btn-presskit:hover:after {
  box-shadow: 0 0 0 1900px #f5f5f5;
}
.btn-presskit:active:after {
  box-shadow: 0 0 0 1900px #cbcbcb;
}
.btn-presskit:hover .download__text {
  color: #ea0600;
}
.btn-presskit:hover .download__icon {
  fill: #ea0600;
  stroke: #ea0600;
}
.btn-presskit:active .download__text {
  color: #bc1713;
}
.btn-presskit:active .download__icon {
  fill: #bc1713;
  stroke: #bc1713;
}
@media only screen and (max-width: 767px) {
  .btn-presskit {
    padding: 12.82px 39.08px 16.5px 25.78px;
  }
}
.btn-presskit:after {
  content: "";
  transform: rotate(45deg);
  position: absolute;
  width: 60px;
  height: 60px;
  top: -30px;
  right: -30px;
}
@media only screen and (max-width: 767px) {
  .btn-presskit:after {
    width: 35px;
    height: 35px;
    top: -17px;
    right: -17px;
  }
}
.btn-presskit:before {
  content: "";
  transform: rotate(-45deg);
  opacity: 0.2;
  position: absolute;
  z-index: 2;
  border: 23px solid transparent;
  border-right: 23px solid #161825;
  top: 18px;
  right: 17px;
}
@media only screen and (max-width: 767px) {
  .btn-presskit:before {
    border: 15px solid transparent;
    border-right: 15px solid #161825;
    top: 11px;
    right: 11px;
  }
}
.btn-presskit__icon {
  margin: 0px 22.5px 0px 0px;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .btn-presskit__icon {
    margin: 0px 5.5px 0px 0px;
    width: 25px;
    height: 32px;
  }
}
.btn-presskit__inner {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
.btn-presskit__title {
  display: block;
  font-weight: 600;
  letter-spacing: 0.04em;
  transition: all 0.5s ease;
  color: #161825;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 40px;
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .btn-presskit__title {
    font-size: 16px;
    line-height: 20px;
  }
}
.btn-presskit__title_sm {
  font-size: 20px;
  line-height: 24px;
}
@media only screen and (max-width: 1199px) {
  .btn-presskit__title_sm {
    font-size: 18px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 849px) {
  .btn-presskit__title_sm {
    font-size: 16px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .btn-presskit__title_sm {
    font-size: 12px;
    line-height: 14px;
  }
}
.btn-presskit_sm {
  padding: 8px;
}
.btn-presskit_sm .btn-presskit__icon {
  margin: 0px 9px 0px 0px;
}
@media only screen and (max-width: 1199px) {
  .btn-presskit_sm .btn-presskit__icon {
    width: 20px;
    height: 25px;
    margin: 0px 7px 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .btn-presskit_sm .btn-presskit__icon {
    width: 20px;
    height: 25px;
    margin: 0px 9px 0px 0px;
  }
}
@media only screen and (max-width: 849px) {
  .btn-presskit_sm .btn-presskit__icon {
    margin: 0px 6px 0px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .btn-presskit_sm .btn-presskit__icon {
    margin: 0px 9px 0px 0px;
    width: 16px;
    height: auto;
  }
}
@media only screen and (max-width: 399px) {
  .btn-presskit_sm .btn-presskit__icon {
    margin: 0px 6px 0px 0px;
    width: 14px;
  }
}
.btn-presskit_sm:after {
  width: 36px;
  height: 36px;
  top: -19px;
  right: -19px;
}
.btn-presskit_sm:before {
  border: 16px solid transparent;
  border-right: 16px solid #161825;
  top: 11px;
  right: 10px;
}

.download {
  display: flex;
  align-items: center;
}
.download__text {
  font-weight: 600;
  letter-spacing: 0.04em;
  transition: all 0.5s ease;
  color: #161825;
  transition: all 0.5s ease;
  font-size: 20px;
  line-height: 1.15;
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .download__text {
    font-size: 10px;
    line-height: 12px;
  }
}
.download__text_sm {
  font-size: 12px;
  line-height: 16px;
}
@media only screen and (max-width: 849px) {
  .download__text_sm {
    font-size: 10px;
    line-height: 15px;
  }
}
.download__icon {
  display: inline;
  margin: 0px 0px 0px 6px;
  transition: all 0.5s ease;
}
@media only screen and (max-width: 767px) {
  .download__icon {
    width: 8px;
    height: 8px;
  }
}

.btn-slider {
  position: relative;
  width: 64.5px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.btn-slider:after {
  content: "";
  position: absolute;
  z-index: 2;
  transition: all 0.5s ease;
  width: 64px;
  height: 60px;
  background: url("./../img/sprite/slide-btn-frame.svg") 0 0 no-repeat;
}
.btn-slider:hover:after {
  top: 0px;
  left: 0px;
}
@media only screen and (max-width: 767px) {
  .btn-slider {
    width: 45px;
    height: 42px;
  }
}
.btn-slider:active .btn-slider-bg {
  fill: rgba(255, 255, 255, 0.5);
}
.btn-slider.swiper-button-disabled {
  opacity: 0.4;
}
.btn-slider.swiper-button-disabled:after {
  display: none;
}
.btn-slider__icon {
  position: relative;
  z-index: 2;
  margin-right: 3px;
}
@media only screen and (max-width: 767px) {
  .btn-slider__icon {
    width: 10px;
    height: 15px;
    margin-top: -3px;
  }
}

.btn-slider-bg {
  position: absolute;
  top: 0;
  left: 0;
  fill: rgba(255, 255, 255, 0.2);
}

.float-container {
  height: 52px;
}
.float-container.active .label-text {
  transform: translate(17.5px, -9px) scale(0.85);
  z-index: 3;
}
.float-container.active .label-search {
  z-index: 3;
}
.float-container.active .label-textarea {
  transform: translate(17.5px, 2px) scale(0.85);
}
.float-container.active .icon {
  z-index: 3;
}
.float-container.active .input-border,
.float-container.active .select-border,
.float-container.active .textarea-border {
  top: 0;
  left: 0;
  z-index: -1;
}
.float-container.active .float-input,
.float-container.active .custom-select {
  border: 1.5px solid transparent;
}
.float-container.active .float-input._error,
.float-container.active .custom-select._error {
  border: 1.5px solid #ff1812;
}
.float-container.active .form__checkbox {
  border: 1.5px solid #73747c;
}
.float-container.active .form__checkbox._error {
  border: 1.5px solid #ff1812;
}

.form__checkbox-wrapper {
  position: relative;
  display: inline-flex;
  min-width: 16px;
  min-height: 16px;
  cursor: pointer;
}

.form__checkbox-container {
  height: 16px;
}
.form__checkbox-container:hover .form__checkbox-border {
  top: 0px;
  left: 0px;
}
.form__checkbox-container input[type=checkbox] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  cursor: pointer;
  margin: 0;
  width: 100%;
  height: 100%;
}
.form__checkbox-container input[type=checkbox]:focus ~ .form__checkbox {
  background-color: #fff;
}
.form__checkbox-container input[type=checkbox]:focus ~ .form__checkbox-border {
  border: 1.5px solid #ff1812;
  top: 0px;
  left: 0px;
}
.form__checkbox-container input[type=checkbox]:checked ~ .form__checkbox-border {
  top: 0px;
  left: 0px;
  border: 1.5px solid #73747c;
}
.form__checkbox-container input[type=checkbox]:checked ~ .form__checkbox {
  background-color: #fff;
}
.form__checkbox-container input[type=checkbox]:checked ~ .form__checkbox:after {
  display: block;
}
.form__checkbox-container input[type=checkbox]:disabled ~ .form__checkbox {
  background-color: rgba(255, 255, 255, 0.7);
}
.form__checkbox-container input[type=checkbox]:disabled ~ .form__checkbox-border {
  top: -4px;
  left: -4px;
}
.form__checkbox-container input[type=checkbox]._error ~ .form__checkbox-border {
  top: 0;
  left: 0;
  border: 1.5px solid #ff1812;
}

.form__checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s ease;
  outline: none;
  cursor: pointer;
}
.form__checkbox:after {
  display: none;
  content: "";
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  -ms-transform: translate(50%, 50%) !important;
  z-index: 3;
  width: 10px;
  height: 8px;
  background: url("./../img/sprite/checkbox-tick.svg") 0 0 no-repeat;
}

.label {
  position: absolute;
  transform-origin: top left;
  transition: opacity 0.5s ease, transform 0.5s ease, background-color 1s ease;
  cursor: text;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #161825;
  opacity: 0.5;
  position: relative;
  z-index: 3;
  width: calc(100% - 16px);
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .label .hidden-m {
    display: none;
  }
}

.label-text {
  transform: translate(16px, 0px) scale(1);
  height: auto;
}

.label-search {
  height: auto;
}

.input-border,
.select-border {
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 2;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  border: 1.5px solid #73747c;
}

.form__checkbox-border {
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 2;
  transition: all 0.5s ease;
  border: 1.5px solid #73747c;
  width: 100%;
  height: 100%;
}

.form-error {
  font-size: 12px;
  line-height: 1.3333333333;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: -20px;
  left: 16px;
  transition: all 0.5s ease;
  margin: 0;
  color: #ff1812;
}
@media only screen and (max-width: 767px) {
  .form-error {
    bottom: -15px;
  }
}

.custom-select-container,
.float-container,
.form-checkbox__container,
.attach-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.custom-select-container:hover .float-input,
.custom-select-container:hover .form__checkbox,
.custom-select-container:hover .custom-select,
.custom-select-container:hover .label-file,
.float-container:hover .float-input,
.float-container:hover .form__checkbox,
.float-container:hover .custom-select,
.float-container:hover .label-file,
.form-checkbox__container:hover .float-input,
.form-checkbox__container:hover .form__checkbox,
.form-checkbox__container:hover .custom-select,
.form-checkbox__container:hover .label-file,
.attach-container:hover .float-input,
.attach-container:hover .form__checkbox,
.attach-container:hover .custom-select,
.attach-container:hover .label-file {
  background-color: #fff;
}
.custom-select-container:hover .float-input,
.float-container:hover .float-input,
.form-checkbox__container:hover .float-input,
.attach-container:hover .float-input {
  border: 1.5px solid transparent;
}
.custom-select-container:hover .form__checkbox,
.float-container:hover .form__checkbox,
.form-checkbox__container:hover .form__checkbox,
.attach-container:hover .form__checkbox {
  border: 1.5px solid #73747c;
}
.custom-select-container:hover .input-border,
.custom-select-container:hover .textarea-border,
.float-container:hover .input-border,
.float-container:hover .textarea-border,
.form-checkbox__container:hover .input-border,
.form-checkbox__container:hover .textarea-border,
.attach-container:hover .input-border,
.attach-container:hover .textarea-border {
  top: 0px;
  left: 0px;
}
.custom-select-container:hover .form__checkbox-border,
.float-container:hover .form__checkbox-border,
.form-checkbox__container:hover .form__checkbox-border,
.attach-container:hover .form__checkbox-border {
  top: 0px;
  left: 0px;
}
.custom-select-container:hover .select-border,
.float-container:hover .select-border,
.form-checkbox__container:hover .select-border,
.attach-container:hover .select-border {
  top: 0px;
  left: 0px;
}
.custom-select-container:hover .label,
.float-container:hover .label,
.form-checkbox__container:hover .label,
.attach-container:hover .label {
  opacity: 0.7;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=url] {
  border-radius: 0;
  border: none;
  outline: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 14px;
  color: #161825;
  transition: all 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}
input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=tel]::placeholder,
input[type=url]::placeholder {
  color: #73747c;
  font-weight: 400;
}
input[type=text]:disabled,
input[type=email]:disabled,
input[type=tel]:disabled,
input[type=url]:disabled {
  background-color: rgba(255, 255, 255, 0.7);
}
input[type=text]:disabled ~ .input-border,
input[type=email]:disabled ~ .input-border,
input[type=tel]:disabled ~ .input-border,
input[type=url]:disabled ~ .input-border {
  top: -4px;
  left: -4px;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=url]:focus {
  transition: all 0.5s ease;
  caret-color: #ff1812;
  background-color: #fff;
}
input[type=text]:focus ~ .input-border,
input[type=email]:focus ~ .input-border,
input[type=tel]:focus ~ .input-border,
input[type=url]:focus ~ .input-border {
  top: 0;
  left: 0;
  border: 1.5px solid rgba(255, 255, 255, 0.4);
}
input[type=text]._error:focus ~ .input-border,
input[type=email]._error:focus ~ .input-border,
input[type=tel]._error:focus ~ .input-border,
input[type=url]._error:focus ~ .input-border {
  border: 1.5px solid #ff1812;
}

.float-input,
.input-file {
  padding: 0px 16px 0px 16px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  border: 1.5px solid transparent;
}
.float-input._error ~ .input-border,
.float-input._error ~ .textarea-border,
.input-file._error ~ .input-border,
.input-file._error ~ .textarea-border {
  border: 1.5px solid #ff1812;
  top: 0;
  left: 0;
}
.float-input._error ~ .form-error,
.input-file._error ~ .form-error {
  color: #ff1812;
}

.textarea-container {
  height: auto;
  cursor: text;
}
@media only screen and (max-width: 767px) {
  .textarea-container {
    margin: 0 0 20px;
  }
}

.textarea {
  min-height: 104px;
  border: none;
  outline: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  color: #161825;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s ease;
  resize: none;
  overflow: auto;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}
.textarea::placeholder {
  color: #73747c;
  font-weight: 400;
}
.textarea:disabled {
  background-color: rgba(255, 255, 255, 0.7);
}
.textarea:disabled ~ .textarea-border {
  top: -4px;
  left: -4px;
}
.textarea:focus {
  transition: all 0.5s ease;
  caret-color: #ff1812;
  background-color: #fff;
}
.textarea:focus ~ .textarea-border {
  top: 0px;
  left: 0px;
  border: 1.5px solid rgba(255, 255, 255, 0.4);
}
.textarea:focus ~ .textarea-subtitle {
  color: #ff1812;
}

.textarea-border {
  content: "";
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  border: 1.5px solid #73747c;
  position: absolute;
  top: -4px;
  left: -4px;
}

.label-textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 16px);
  height: auto;
  transform: translate(16px, 8px) scale(1);
}

.attach-container .input-border {
  cursor: pointer;
}

input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
input[type=file]:disabled + label {
  background-color: rgba(255, 255, 255, 0.7);
}
input[type=file]:disabled ~ .input-border {
  top: -4px;
  left: -4px;
}

.input-file.attached ~ .input-border {
  top: 0;
  left: 0;
}
.input-file.attached ~ .label-file {
  background-color: #fff;
}

.label-file {
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 0 44px;
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  transition: all 0.5s ease;
  cursor: pointer;
}
.label-file span {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #161825;
  opacity: 0.5;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.icon-attach,
.icon-file,
.icon-search {
  left: 17.67px;
}

.icon-delete {
  right: 14px;
  z-index: 4;
}

.icon-file,
.icon-attach,
.icon-delete {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease, visibility 0.1s ease;
}
.icon-file.visible,
.icon-attach.visible,
.icon-delete.visible {
  visibility: visible;
}

.icon-file.visible {
  opacity: 1;
}

.icon-attach.visible {
  opacity: 0.5;
}

.icon-delete.visible {
  opacity: 0.6;
  transition: all 0.5s ease;
}
.icon-delete.visible:hover {
  fill: #4c4f61;
  opacity: 1;
}

.input-container {
  position: relative;
}
.input-container.active .label-search {
  transform: translate(46px, -9px) scale(0.85);
}

.label-search {
  transform: translate(44px, 0px) scale(1);
}

.input-search {
  padding: 15px 15px 0px 44px !important;
}

.icon-search {
  z-index: 2;
}

.icon-select {
  right: 0;
}

.custom-select-container {
  position: relative;
  user-select: none;
  width: 100%;
}

.custom-select {
  position: relative;
  width: 100%;
  height: 52px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s ease;
  color: #161825;
  padding: 0px 23px 0px 16px;
  cursor: pointer;
}
.custom-select.open {
  background-color: #ffffff;
}
.custom-select.open .custom-select__trigger .icon-select {
  transform: translate(0%, -50%) rotate(-180deg);
}
.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  background-color: #ffffff;
  z-index: 4;
}
.custom-select.open .custom-options:after {
  opacity: 1;
  visibility: visible;
}
.custom-select.open .select-border {
  top: 0px;
  left: 0px;
}
.custom-select.open .select-subtitle {
  opacity: 0;
  visibility: hidden;
}

.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.label-select {
  transform: translateX(0);
  cursor: pointer;
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0px;
  right: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.custom-options:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 3px);
  height: 100%;
  transition: all 0.5s ease;
  border: 1.5px solid #73747c;
  border-top: none;
  opacity: 0;
  visibility: hidden;
}

.custom-option {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  padding: 10px 16px;
  color: rgba(22, 24, 37, 0.5);
  cursor: pointer;
  transition: all 0.5s ease;
  z-index: 2;
}
.custom-option:hover {
  cursor: pointer;
  background-color: rgba(115, 116, 124, 0.1490196078);
}
.custom-option.selected {
  color: #ffffff;
  background-color: #73747c;
}

.btn-arrow-red-bg {
  display: flex;
  position: relative;
  width: 244px;
  position: relative;
  transition: all 0.5s ease;
  min-height: 100%;
  transition: all 0.5s ease;
}
.btn-arrow-red-bg:hover {
  background-color: #ea0600;
  border-color: #ea0600;
}
.btn-arrow-red-bg:active {
  background-color: #bc1713;
  border-color: #bc1713;
}
.btn-arrow-red-bg .btn-icon-arrow {
  position: absolute;
  left: 100%;
  top: 50%;
  width: auto;
  height: calc(100% + 1.5px);
  transform: translateY(-50%);
  transition: fill 0.5s ease;
}
.btn-arrow-red-bg .btn-inner {
  width: 100%;
  padding: 20.5px 32px;
  background-color: #ff1812;
  box-shadow: 0px 38px 80px rgba(255, 59, 0, 0.24), 0px 15.8755px 33.4221px rgba(255, 59, 0, 0.172525), 0px 8.4878px 17.869px rgba(255, 59, 0, 0.143066), 0px 4.75819px 10.0172px rgba(255, 59, 0, 0.12), 0px 2.52704px 5.32008px rgba(255, 59, 0, 0.0969343), 0px 1.05156px 2.21381px rgba(255, 59, 0, 0.0674749);
}
.btn-arrow-red-bg .btn-inner:hover {
  filter: none;
}
.btn-arrow-red-bg .btn-inner:active {
  filter: none;
}
.btn-arrow-red-bg:hover .btn-inner {
  width: 251px;
  background-color: #ea0600;
}
.btn-arrow-red-bg:hover .btn-icon-red {
  fill: #ea0600;
}
.btn-arrow-red-bg:active .btn-icon-arrow {
  fill: #bc1713;
  stroke: #bc1713;
}
.btn-arrow-red-bg:active .btn-icon-red {
  fill: #bc1713;
}

.social-icons li {
  margin: 0;
}

.icon-social {
  cursor: pointer;
  transition: all 0.5s ease;
  user-select: none;
}
.icon-social svg {
  transition: all 0.5s ease;
}

.social-vk,
.social-fb,
.social-im,
.social-ytb,
.icon-platform,
.social-viber,
.social-tg {
  position: relative;
  display: block;
}
.social-vk:hover .icon-vk,
.social-vk:hover .icon-fb,
.social-vk:hover .icon-im,
.social-vk:hover .icon-ytb,
.social-vk:hover .icon-app,
.social-vk:hover .icon-gp,
.social-vk:hover .icon-steam,
.social-vk:hover .icon-p1,
.social-vk:hover .icon-xbox,
.social-vk:hover .icon-playstation,
.social-vk:hover .icon-steam,
.social-vk:hover .icon-viber,
.social-vk:hover .icon-tg,
.social-fb:hover .icon-vk,
.social-fb:hover .icon-fb,
.social-fb:hover .icon-im,
.social-fb:hover .icon-ytb,
.social-fb:hover .icon-app,
.social-fb:hover .icon-gp,
.social-fb:hover .icon-steam,
.social-fb:hover .icon-p1,
.social-fb:hover .icon-xbox,
.social-fb:hover .icon-playstation,
.social-fb:hover .icon-steam,
.social-fb:hover .icon-viber,
.social-fb:hover .icon-tg,
.social-im:hover .icon-vk,
.social-im:hover .icon-fb,
.social-im:hover .icon-im,
.social-im:hover .icon-ytb,
.social-im:hover .icon-app,
.social-im:hover .icon-gp,
.social-im:hover .icon-steam,
.social-im:hover .icon-p1,
.social-im:hover .icon-xbox,
.social-im:hover .icon-playstation,
.social-im:hover .icon-steam,
.social-im:hover .icon-viber,
.social-im:hover .icon-tg,
.social-ytb:hover .icon-vk,
.social-ytb:hover .icon-fb,
.social-ytb:hover .icon-im,
.social-ytb:hover .icon-ytb,
.social-ytb:hover .icon-app,
.social-ytb:hover .icon-gp,
.social-ytb:hover .icon-steam,
.social-ytb:hover .icon-p1,
.social-ytb:hover .icon-xbox,
.social-ytb:hover .icon-playstation,
.social-ytb:hover .icon-steam,
.social-ytb:hover .icon-viber,
.social-ytb:hover .icon-tg,
.icon-platform:hover .icon-vk,
.icon-platform:hover .icon-fb,
.icon-platform:hover .icon-im,
.icon-platform:hover .icon-ytb,
.icon-platform:hover .icon-app,
.icon-platform:hover .icon-gp,
.icon-platform:hover .icon-steam,
.icon-platform:hover .icon-p1,
.icon-platform:hover .icon-xbox,
.icon-platform:hover .icon-playstation,
.icon-platform:hover .icon-steam,
.icon-platform:hover .icon-viber,
.icon-platform:hover .icon-tg,
.social-viber:hover .icon-vk,
.social-viber:hover .icon-fb,
.social-viber:hover .icon-im,
.social-viber:hover .icon-ytb,
.social-viber:hover .icon-app,
.social-viber:hover .icon-gp,
.social-viber:hover .icon-steam,
.social-viber:hover .icon-p1,
.social-viber:hover .icon-xbox,
.social-viber:hover .icon-playstation,
.social-viber:hover .icon-steam,
.social-viber:hover .icon-viber,
.social-viber:hover .icon-tg,
.social-tg:hover .icon-vk,
.social-tg:hover .icon-fb,
.social-tg:hover .icon-im,
.social-tg:hover .icon-ytb,
.social-tg:hover .icon-app,
.social-tg:hover .icon-gp,
.social-tg:hover .icon-steam,
.social-tg:hover .icon-p1,
.social-tg:hover .icon-xbox,
.social-tg:hover .icon-playstation,
.social-tg:hover .icon-steam,
.social-tg:hover .icon-viber,
.social-tg:hover .icon-tg {
  opacity: 0;
  visibility: hidden;
}
.social-vk:hover .icon-vk-hover,
.social-vk:hover .icon-fb-hover,
.social-vk:hover .icon-im-hover,
.social-vk:hover .icon-ytb-hover,
.social-vk:hover .icon-app-hover,
.social-vk:hover .icon-gp-hover,
.social-vk:hover .icon-steam-hover,
.social-vk:hover .icon-p1-hover,
.social-vk:hover .icon-xbox-hover,
.social-vk:hover .icon-playstation-hover,
.social-vk:hover .icon-steam-hover,
.social-vk:hover .icon-viber-hover,
.social-vk:hover .icon-tg-hover,
.social-fb:hover .icon-vk-hover,
.social-fb:hover .icon-fb-hover,
.social-fb:hover .icon-im-hover,
.social-fb:hover .icon-ytb-hover,
.social-fb:hover .icon-app-hover,
.social-fb:hover .icon-gp-hover,
.social-fb:hover .icon-steam-hover,
.social-fb:hover .icon-p1-hover,
.social-fb:hover .icon-xbox-hover,
.social-fb:hover .icon-playstation-hover,
.social-fb:hover .icon-steam-hover,
.social-fb:hover .icon-viber-hover,
.social-fb:hover .icon-tg-hover,
.social-im:hover .icon-vk-hover,
.social-im:hover .icon-fb-hover,
.social-im:hover .icon-im-hover,
.social-im:hover .icon-ytb-hover,
.social-im:hover .icon-app-hover,
.social-im:hover .icon-gp-hover,
.social-im:hover .icon-steam-hover,
.social-im:hover .icon-p1-hover,
.social-im:hover .icon-xbox-hover,
.social-im:hover .icon-playstation-hover,
.social-im:hover .icon-steam-hover,
.social-im:hover .icon-viber-hover,
.social-im:hover .icon-tg-hover,
.social-ytb:hover .icon-vk-hover,
.social-ytb:hover .icon-fb-hover,
.social-ytb:hover .icon-im-hover,
.social-ytb:hover .icon-ytb-hover,
.social-ytb:hover .icon-app-hover,
.social-ytb:hover .icon-gp-hover,
.social-ytb:hover .icon-steam-hover,
.social-ytb:hover .icon-p1-hover,
.social-ytb:hover .icon-xbox-hover,
.social-ytb:hover .icon-playstation-hover,
.social-ytb:hover .icon-steam-hover,
.social-ytb:hover .icon-viber-hover,
.social-ytb:hover .icon-tg-hover,
.icon-platform:hover .icon-vk-hover,
.icon-platform:hover .icon-fb-hover,
.icon-platform:hover .icon-im-hover,
.icon-platform:hover .icon-ytb-hover,
.icon-platform:hover .icon-app-hover,
.icon-platform:hover .icon-gp-hover,
.icon-platform:hover .icon-steam-hover,
.icon-platform:hover .icon-p1-hover,
.icon-platform:hover .icon-xbox-hover,
.icon-platform:hover .icon-playstation-hover,
.icon-platform:hover .icon-steam-hover,
.icon-platform:hover .icon-viber-hover,
.icon-platform:hover .icon-tg-hover,
.social-viber:hover .icon-vk-hover,
.social-viber:hover .icon-fb-hover,
.social-viber:hover .icon-im-hover,
.social-viber:hover .icon-ytb-hover,
.social-viber:hover .icon-app-hover,
.social-viber:hover .icon-gp-hover,
.social-viber:hover .icon-steam-hover,
.social-viber:hover .icon-p1-hover,
.social-viber:hover .icon-xbox-hover,
.social-viber:hover .icon-playstation-hover,
.social-viber:hover .icon-steam-hover,
.social-viber:hover .icon-viber-hover,
.social-viber:hover .icon-tg-hover,
.social-tg:hover .icon-vk-hover,
.social-tg:hover .icon-fb-hover,
.social-tg:hover .icon-im-hover,
.social-tg:hover .icon-ytb-hover,
.social-tg:hover .icon-app-hover,
.social-tg:hover .icon-gp-hover,
.social-tg:hover .icon-steam-hover,
.social-tg:hover .icon-p1-hover,
.social-tg:hover .icon-xbox-hover,
.social-tg:hover .icon-playstation-hover,
.social-tg:hover .icon-steam-hover,
.social-tg:hover .icon-viber-hover,
.social-tg:hover .icon-tg-hover {
  opacity: 1;
  visibility: visible;
}

.icon-vk,
.icon-fb,
.icon-im,
.icon-ytb,
.icon-app,
.icon-gp,
.icon-steam,
.icon-p1,
.icon-xbox,
.icon-playstation,
.icon-steam,
.icon-viber,
.icon-tg {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}

.icon-vk-hover,
.icon-fb-hover,
.icon-im-hover,
.icon-ytb-hover,
.icon-app-hover,
.icon-gp-hover,
.icon-steam-hover,
.icon-p1-hover,
.icon-xbox-hover,
.icon-playstation-hover,
.icon-steam-hover,
.icon-viber-hover,
.icon-tg-hover {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.icon-tw:hover svg {
  fill: #1da1f2;
}

.platforms-icons {
  padding: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.icon-platform {
  width: 24px;
  height: 24px;
  user-select: none;
}

.icon-social {
  width: 20px;
  height: 20px;
}
.icon-social img {
  height: 100%;
  width: 100%;
  display: block;
}

.image-crop {
  position: relative;
  margin: 2px;
  width: calc(100% - 4px);
  height: 100%;
  user-select: none;
}
.image-crop:hover .image-crop__info {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}
.image-crop:hover .image-crop__content {
  width: calc(100% + 3px);
  height: calc(100% + 3px);
}
.image-crop:hover .image-crop__title_front {
  opacity: 0;
  visibility: hidden;
}
.image-crop__wrapper {
  display: block;
  height: 100%;
  position: relative;
  text-decoration: none;
}
.image-crop__content {
  width: 95%;
  height: 93%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 8px 16px;
  display: flex;
  align-items: flex-end;
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
}
.image-crop__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  max-width: 165px;
  margin: 0;
  user-select: none;
}
.image-crop__title_hv {
  margin: 0px 0px 12px 0px;
}
@media only screen and (max-width: 767px) {
  .image-crop__title_hv {
    margin: 0px 0px 4px 0px;
  }
}
.image-crop__title_front {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}
.image-crop__img-block {
  display: block;
  overflow: hidden;
  height: 100%;
  position: relative;
}
.image-crop__img {
  display: block;
  width: 100%;
  height: 200%;
  overflow: hidden;
}
.image-crop__img img {
  display: block;
  object-fit: cover;
  object-position: center;
  height: 50%;
  width: 100%;
  font-family: "object-fit: cover; object-position: center;";
}
.image-crop__info {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 24px 24px 16px 24px;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.8s ease;
  opacity: 0;
  visibility: hidden;
}
.image-crop__icons {
  flex: 1 1 auto;
}
.image-crop__icons li {
  margin: 0px 12px 0px 0px;
}
.image-crop__icons li:last-child {
  margin: 0px 0px 0px 0px;
}
.image-crop__btn {
  width: 100%;
}

.swiper > .swiper-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}
.swiper > .swiper-wrapper__center {
  justify-content: center;
  margin: 0 auto;
}
.swiper.swiper-container-autoheight > li {
  align-items: flex-start;
}

.swiper-container-initialized .swiper-slide {
  flex-shrink: 0;
}

.swiper-container-android .swiper-slide,
.swiper-container-android .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-button-lock {
  display: none !important;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
  background-color: #161825;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header__logo-block {
  align-self: flex-start;
  flex-shrink: 0;
  text-decoration: none;
  margin-right: 58px;
  user-select: none;
  height: 79px;
  display: flex;
}
.header__logo-block picture {
  width: 100%;
  height: 100%;
}
.header__logo-block img, .header__logo-block svg {
  max-width: 100%;
  height: 100%;
  width: auto;
  object-fit: contain;
  -webkit-transform: translate3d(0, 0, 0);
}
@media only screen and (max-width: 1599px) {
  .header__logo-block {
    height: 60px;
    margin-left: -10px;
    margin-right: 32px;
  }
}
@media only screen and (max-width: 1023px) {
  .header__logo-block {
    height: 52px;
    margin-right: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .header__logo-block {
    height: 39px;
    margin-right: 15px;
    margin-left: 1px;
    overflow: hidden;
  }
  .header__logo-block img, .header__logo-block svg {
    max-width: none;
    height: 50px;
    position: relative;
    top: -6px;
  }
}

.apple .header__logo-block .apple-logo {
  display: block;
}

.header__menu {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
@media only screen and (max-width: 767px) {
  .header__menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    width: 260px;
    max-width: 100%;
    padding-top: 54px;
    padding-bottom: 22px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #161825;
    box-shadow: 0 4px 16px #000000;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
}

.header__menu-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .header__menu-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    height: 100%;
    overflow-y: auto;
  }
}

.header__nav {
  flex-shrink: 1;
}

.header__nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 0 -10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .header__nav-list {
    flex-direction: column;
    margin: 0;
  }
}

.header__lang {
  flex-shrink: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 0 -8px 0 25px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  user-select: none;
}
.header__lang li {
  display: flex;
}
.header__lang .header__link {
  text-transform: none;
  font-weight: 400;
}
@media only screen and (max-width: 767px) {
  .header__lang {
    flex-direction: column;
    justify-content: flex-start;
    margin: 4px 0 0;
    position: relative;
  }
  .header__lang::before {
    content: "";
    position: absolute;
    top: 0;
    left: 24px;
    right: 24px;
    height: 1px;
    background-color: #ffffff;
    opacity: 0.1;
  }
  .header__lang .header__link {
    font-size: 12px;
  }
}

.header__nav-menu {
  margin: 0 10px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.header__nav-menu:hover .header__nav-sub-menu {
  display: block;
}
@media only screen and (max-width: 1023px) {
  .header__nav-menu {
    margin: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header__nav-menu {
    margin: 0;
  }
  .header__nav-menu .header__nav-sub-menu {
    display: block;
    height: 0;
    transition: height 0.3s linear;
  }
}

.header__link {
  position: relative;
  width: auto;
  padding: 30px 11px 29px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  user-select: none;
  opacity: 0.7;
}
@media only screen and (max-width: 1599px) {
  .header__link {
    padding: 20px 11px 19px;
  }
}
@media only screen and (max-width: 1023px) {
  .header__link {
    padding: 16px 10px 15px;
    font-size: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .header__link {
    width: 100%;
    padding: 10px 24px;
  }
}
.header__link:hover {
  opacity: 1;
}
.header__link:focus {
  outline: none;
}
.header__link:active {
  opacity: 0.9;
}
@media only screen and (max-width: 767px) {
  .header__link:active {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.header__link.active {
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .header__link.active {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.header__link.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 11px;
  right: 11px;
  z-index: 1;
  height: 4px;
  background-color: #ff1812;
  box-shadow: 0 8.4878px 17.869px rgba(255, 59, 0, 0.2), 0 4.75819px 10.0172px rgba(255, 59, 0, 0.18), 0 2.52704px 5.32008px rgba(255, 59, 0, 0.12), 0 1.05156px 2.21381px rgba(255, 59, 0, 0.1);
}
@media only screen and (max-width: 767px) {
  .header__link.active::after {
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    height: 100%;
    width: 3px;
  }
}

.header__nav-sub-menu {
  display: none;
}

.sub-menu {
  position: fixed;
  top: 79px;
  left: 0;
  right: 0;
  z-index: 50;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  background-color: #161825;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width: 1599px) {
  .sub-menu {
    top: 59px;
  }
}
@media only screen and (max-width: 1023px) {
  .sub-menu {
    top: 51px;
  }
}
@media only screen and (max-width: 767px) {
  .sub-menu {
    width: 100%;
    position: static;
    max-height: none;
    box-shadow: none;
    background-color: transparent;
  }
}

.sub-menu__container {
  max-width: 1236px;
  padding-top: 32px;
  padding-bottom: 42px;
}
@media only screen and (max-width: 1599px) {
  .sub-menu__container {
    max-width: 100%;
    padding-left: 126px;
  }
}
@media only screen and (max-width: 1023px) {
  .sub-menu__container {
    padding-left: 94px;
    padding-top: 15px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .sub-menu__container {
    width: 100%;
    padding: 0;
  }
}

.sub-menu__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 767px) {
  .sub-menu__list {
    align-items: stretch;
  }
}
.sub-menu__list li {
  margin-top: 16px;
}
@media only screen and (max-width: 1023px) {
  .sub-menu__list li {
    margin-top: 0;
  }
}
.sub-menu__list li:first-child {
  margin-top: 0;
}

.sub-menu__link {
  padding: 10px 20px 10px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.7;
}
.sub-menu__link:hover {
  opacity: 1;
}
.sub-menu__link:active {
  opacity: 0.9;
}
@media only screen and (max-width: 1023px) {
  .sub-menu__link {
    padding: 10px 24px 10px 34px;
    font-size: 10px;
  }
}

.icon-link {
  display: flex;
  justify-content: flex-start;
  user-select: none;
}
.icon-link svg {
  fill: currentColor;
  align-self: flex-start;
  margin-right: 12px;
}
.icon-link .icon-link__value {
  font: inherit;
  color: inherit;
}

.header__buttons-block {
  display: none;
}
@media only screen and (max-width: 767px) {
  .header__buttons-block {
    display: flex;
    padding: 0 12px 0 18px;
    margin-top: auto;
    flex-shrink: 0;
  }
  .header__buttons-block .btn-wrapper {
    overflow: hidden;
  }
  .header__buttons-block .btn-wrapper .btn-arrow-red {
    width: 89%;
    display: flex;
    justify-content: center;
  }
  .header__buttons-block .btn-wrapper:hover .btn-arrow-red, .header__buttons-block .btn-wrapper:active .btn-arrow-red {
    width: 92%;
  }
}

.header__menu-mobile {
  display: none;
  margin-left: auto;
  flex-direction: row;
  align-items: flex-start;
}
@media only screen and (max-width: 767px) {
  .header__menu-mobile {
    display: flex;
    margin-right: -4px;
    position: relative;
    z-index: 105;
  }
  .header__menu-mobile .header__lang {
    margin-top: 0;
  }
}
.header__menu-mobile .header__link {
  padding: 9px 10px 12px;
  font-size: 12px;
  line-height: 18px;
}

.header__burger {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .menu-open .header__menu-mobile .header__lang {
    display: none;
  }
  .menu-open .header__menu {
    transform: translateX(0);
  }
  .menu-open .header__burger span:nth-child(2) {
    opacity: 0;
    transform: translateX(50%);
    transition: all 0.3s ease-in-out;
  }
  .menu-open .header__burger span:first-child {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    transition: all 0.3s ease-in-out;
  }
  .menu-open .header__burger span:last-child {
    bottom: 50%;
    transform: translateY(50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
  }
}
.burger {
  background-color: transparent;
  border: 0;
  padding: 0;
  position: relative;
  width: 24px;
  height: 39px;
}
.burger:focus {
  outline: none;
}

.burger span {
  position: absolute;
  -webkit-transition: 0.3s;
}

.burger span {
  display: block;
  position: absolute;
  right: 4px;
  width: 16px;
  height: 2px;
  background: #ffffff;
}
.burger span:nth-child(2) {
  width: 14px;
  top: 19px;
  opacity: 1;
  transition: transform 0.3s ease-in-out;
}
.burger span:first-child {
  top: 13px;
  transition: transform 0.3s ease-in-out;
}
.burger span:last-child {
  bottom: 12px;
  transition: transform 0.3s ease-in-out;
}

.main-section {
  position: relative;
  background: linear-gradient(180deg, rgba(47, 49, 63, 0.2) 78.76%, rgba(0, 0, 0, 0.2) 100%);
}
@media only screen and (max-width: 767px) {
  .main-section {
    position: relative;
    background: none;
  }
  .main-section:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(180deg, #161825 0%, rgba(22, 24, 37, 0) 29.87%, rgba(22, 24, 37, 0) 68.18%, #161825 88.94%);
  }
}
.main-section__container {
  display: flex;
  justify-content: space-between;
}
.main-section__body {
  padding: 200px 0px 202px 0px;
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 1599px) {
  .main-section__body {
    padding: 12.5vw 0px 11.375vw 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .main-section__body {
    padding: 10.44921875vw 0px 9.27734375vw 0px;
  }
}
@media only screen and (max-width: 767px) {
  .main-section__body {
    padding: 35px 0px 9px 0px;
  }
}
.main-section__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.main-section__logo {
  margin: 0;
  font-size: 0;
  margin: 0px 0px 10px 0px;
}
.main-section__text {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px 0px 127px 0px;
  max-width: 441px;
}
@media only screen and (max-width: 1599px) {
  .main-section__text {
    font-size: 24px;
    line-height: 1.3333333333;
    margin: 0px 0px 7.0625vw 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .main-section__text {
    font-size: 20px;
    line-height: 1.4;
    margin: 0px 0px 4.58984375vw 0px;
  }
}
@media only screen and (max-width: 767px) {
  .main-section__text {
    font-size: 16px;
    line-height: 1;
    margin: 0px 0px 340px 0px;
    max-width: 58.59375vw;
  }
}
.main-section__btn {
  display: inline-flex;
}
@media only screen and (max-width: 767px) {
  .main-section__btn {
    width: 284px;
  }
  .main-section__btn .btn__arrow {
    position: absolute;
    top: 0;
    right: 3px;
  }
  .main-section__btn .btn-arrow {
    width: 96%;
  }
  .main-section__btn .btn-inner {
    display: flex;
    justify-content: center;
    border-color: #ff1812;
    background-color: #ff1812;
    min-height: 52px;
  }
  .main-section__btn .btn__arrow-fill {
    fill: #ff1812;
  }
  .main-section__btn .btn__arrow-stroke {
    stroke: #ff1812;
  }
}
.main-section__bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  display: none;
}
@media only screen and (min-width: 768px) {
  .main-section__bg {
    display: flex;
  }
}
.main-section__bg img {
  height: 100%;
  width: auto;
  object-fit: contain;
  object-position: top right;
}
.main-section__bg_mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .main-section__bg_mobile {
    display: flex;
  }
}

.games-main {
  background-color: transparent;
  padding: 120px 0px 199px 0px;
}
@media only screen and (max-width: 1599px) {
  .games-main {
    padding: 60px 0 199px 0;
  }
}
@media only screen and (max-width: 1023px) {
  .games-main {
    padding: 40px 0 107px 0;
  }
}
@media only screen and (max-width: 767px) {
  .games-main {
    padding: 80px 0 32px 0;
  }
}
.games-main__body {
  margin: 0 0 31px 0;
}
.games-main__title {
  margin: 0 0 16px 0;
}
.games-main__text {
  margin: 0 0 95px 0;
}
@media only screen and (max-width: 1599px) {
  .games-main__text {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 1023px) {
  .games-main__text {
    margin-bottom: 52px;
  }
}
@media only screen and (max-width: 767px) {
  .games-main__text {
    margin-bottom: 32px;
  }
}

.games-slider__image-crop-wrapper {
  height: 320px;
}
.games-slider__image-crop-wrapper > .image-crop__img {
  top: -29px;
  left: -33px;
}
.games-slider__image-crop-wrapper > .image-crop__img img {
  margin-top: 115px;
}
.games-slider__btn {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media only screen and (max-width: 1023px) {
  .games-slider__btn {
    position: static;
  }
}
.games-slider__pagination {
  padding: 0 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1023px) {
  .games-slider__pagination {
    display: none;
  }
}
.games-slider__arrow {
  display: flex;
  outline: none;
}
.games-slider__arrow_right {
  transform: rotate(-180deg);
}
.games-slider__dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 16px;
}
.games-slider__dots .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  background-color: transparent;
  border-radius: 50%;
  margin: 0px 24px 0px 0px;
  cursor: pointer;
  outline: none;
}
.games-slider__dots .swiper-pagination-bullet:last-child {
  margin: 0px 0px 0px 0px;
}
.games-slider__dots .swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
}

.games-slider {
  position: relative;
}

.release {
  padding: 100px 0px 135px 0px;
  margin: 0px 0px 120px 0px;
  position: relative;
}
@media only screen and (max-width: 1599px) {
  .release {
    margin: 0px 0px 7.8125vw 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .release {
    padding: 5.37109375vw 0px 7.8125vw 0px;
    margin: 0px 0px 10.546875vw 0px;
  }
}
@media only screen and (max-width: 767px) {
  .release {
    padding: 60px 0px 30px 0px;
    margin: 0px 0px;
  }
}
.release__container {
  position: relative;
  z-index: 2;
}
.release__title {
  margin: 0px 0px 24px 0px;
}
@media only screen and (max-width: 1023px) {
  .release__title {
    margin: 0px 0px 27px 0px;
  }
}
.release__text {
  margin: 0px 0px 108px 0px;
  max-width: 418px;
}
@media only screen and (max-width: 1599px) {
  .release__text {
    margin: 0px 0px 7.8125vw 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .release__text {
    margin: 0px 0px 8.49609375vw 0px;
  }
}
@media only screen and (max-width: 767px) {
  .release__text {
    max-width: 181px;
    margin: 0px 0px 20px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .release__buttons {
    display: inline-flex;
    flex-direction: column;
    min-width: 200px;
    width: auto;
  }
}
.release__btn {
  display: none;
}
@media only screen and (min-width: 768px) {
  .release__btn {
    display: inline-flex;
    margin: 0px 37px 0px 0px;
  }
  .release__btn:nth-child(3) {
    margin: 0px 0px 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .release__btn {
    margin: 0px 25px 0px 0px;
  }
}
.release__btn-mobile {
  display: none;
  border-left: #1c1f2f;
}
@media only screen and (max-width: 767px) {
  .release__btn-mobile {
    display: inline-block;
    margin: 0px 0px 10px -16px;
    width: auto;
  }
  .release__btn-mobile .btn-inner {
    font-size: 16px;
    line-height: 1.25;
    padding: 0;
    background-color: #1c1f2f;
    border: none;
    min-height: 52px;
    padding: 3px 10px 6px 16px;
  }
  .release__btn-mobile .btn-inner span {
    padding: 0px 0px 0px 12px;
  }
  .release__btn-mobile .btn-icon {
    position: relative;
    right: 0;
    top: 0%;
    transform: none;
    min-width: 24px;
  }
  .release__btn-mobile .btn__arrow {
    position: absolute;
    top: 0;
    right: 3px;
  }
  .release__btn-mobile .btn__arrow-fill {
    fill: #1c1f2f;
  }
  .release__btn-mobile .btn__arrow-stroke {
    stroke: #1c1f2f;
  }
  .release__btn-mobile:hover .btn-arrow {
    width: auto;
  }
}
.release__img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.release__img img {
  width: 100%;
  height: 100%;
  object-position: top right;
  object-fit: contain;
}
@media only screen and (max-width: 1599px) {
  .release__img {
    right: -135px;
  }
}
@media only screen and (max-width: 1023px) {
  .release__img {
    right: -118px;
    height: 137%;
  }
}
@media only screen and (max-width: 767px) {
  .release__img {
    right: -165px;
    height: 326px;
    width: 408px;
  }
  .release__img img {
    transform: rotate(-12deg);
  }
}
.release__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: 100% 100%;
  background-color: #1c1f2f;
}
@media only screen and (max-width: 767px) {
  .release__bg {
    background-image: none !important;
    background-color: transparent;
  }
}

.career {
  position: relative;
  padding: 120px 0px 188px 0px;
}
@media only screen and (max-width: 1599px) {
  .career {
    padding: 4.0625vw 0px 7.8125vw 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .career {
    padding: 40px 0px 80px 0px;
  }
}
.career__content {
  z-index: 3;
  position: relative;
  left: 13.5vw;
}
@media only screen and (max-width: 1599px) {
  .career__content {
    left: 86px;
  }
}
@media only screen and (max-width: 767px) {
  .career__content {
    left: 0px;
    width: 100%;
    padding: 0px 16px;
  }
}
.career__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.career__title {
  margin: 0px 0px 28px 0px;
}
@media only screen and (max-width: 1023px) {
  .career__title {
    margin: 0px 0px 2.734375vw 0px;
  }
}
.career__text {
  max-width: 460px;
  margin: 0px 0px 57px 0px;
}
@media only screen and (max-width: 1023px) {
  .career__text {
    margin: 0px 0px 7.8125vw 0px;
  }
}
@media only screen and (max-width: 767px) {
  .career__text {
    margin: 0px 0px 35px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .career__btn .btn-inner {
    font-size: 14px;
    line-height: 1.7142857143;
  }
  .career__btn .btn-icon {
    margin: 0px 0px 0px 16px;
  }
}
.career__frame {
  top: -20px;
  left: 8.125vw;
  width: 81.25%;
}
@media only screen and (max-width: 1599px) {
  .career__frame {
    left: 40px;
    width: 91.25%;
  }
}
@media only screen and (max-width: 767px) {
  .career__frame {
    display: none;
  }
}
.career__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
@media only screen and (max-width: 767px) {
  .career__bg {
    background-size: auto 100%;
  }
  .career__bg:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(22, 24, 37, 0) 0%, #161825 80.01%);
    background-size: auto 100%;
  }
}

.release-main {
  position: relative;
}
@media only screen and (max-width: 599px) {
  .release-main:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(180deg, #161825 0%, rgba(22, 24, 37, 0) 29.87%, rgba(22, 24, 37, 0) 68.18%, #161825 88.94%);
  }
}
.release-main__container {
  position: relative;
  height: 100%;
  min-height: 600px;
  display: flex;
}
@media only screen and (max-width: 1599px) {
  .release-main__container {
    min-height: 495px;
  }
}
@media only screen and (max-width: 1023px) {
  .release-main__container {
    min-height: 380px;
  }
}
@media only screen and (max-width: 599px) {
  .release-main__container {
    min-height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.release-main__logo {
  width: 100%;
  position: relative;
  z-index: 3;
}
.release-main__logo_pc {
  margin: 0px 0px 9px -7px;
}
.release-main__content {
  max-width: 600px;
  padding: 140px 0px 0px 0px;
}
@media only screen and (max-width: 1199px) {
  .release-main__content_mobile {
    padding: 80px 0px 0px 0px;
  }
}
@media only screen and (max-width: 1299px) {
  .release-main__content_pc {
    max-width: 525px;
  }
}
@media only screen and (max-width: 1599px) {
  .release-main__content_pc {
    padding: 95px 0px 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .release-main__content {
    padding: 65px 0px 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .release-main__content {
    max-width: 435px;
  }
}
@media only screen and (max-width: 767px) {
  .release-main__content {
    max-width: 300px;
  }
}
@media only screen and (max-width: 599px) {
  .release-main__content {
    display: flex;
    flex-direction: column;
    min-height: 0;
    max-width: none;
    padding: 13px 0 0;
  }
  .release-main__content_mobile {
    padding: 35px 0 0;
  }
}
.release-main__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 1.25;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 2.67598px 2.67598px rgba(0, 0, 0, 0.25);
  margin: 0px 0px 100px 0px;
  user-select: none;
  position: relative;
  z-index: 3;
}
.release-main__title_mobile {
  margin: 0px 0px 40px 0px;
}
@media only screen and (max-width: 1599px) {
  .release-main__title {
    margin: 0px 0px 60px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .release-main__title {
    font-size: 32px;
    line-height: 1.25;
  }
}
@media only screen and (max-width: 767px) {
  .release-main__title {
    font-size: 26px;
  }
}
@media only screen and (max-width: 599px) {
  .release-main__title {
    margin: 0px 0px 0px 0px;
    font-size: 20px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.5);
  }
}
.release-main__btn {
  width: 242px;
  display: inline-block;
  position: relative;
  z-index: 3;
}
.release-main__btn:hover {
  width: 251px;
}
@media only screen and (max-width: 599px) {
  .release-main__btn {
    width: 284px;
    position: absolute;
    left: 16px;
    bottom: 0;
  }
  .release-main__btn .btn-arrow_bg {
    box-shadow: none;
  }
  .release-main__btn .btn-arrow-red {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .release-main__btn .btn-inner {
    text-align: center;
  }
  .release-main__btn:hover {
    width: 286px;
  }
}
.release-main__image-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
}
.release-main__image-block picture {
  height: 100%;
}
.release-main__image-block img {
  max-height: 100%;
  object-fit: contain;
}
@media only screen and (min-width: 1600px) {
  .release-main__image-block_pc img {
    margin: 45px 45px 0px 0px;
  }
}
.release-main__image-block_mobile img {
  margin: 120px 0px 0px 0px;
}
@media only screen and (max-width: 1023px) {
  .release-main__image-block_mobile img {
    margin: 55px 0px 0px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .release-main__image-block_mobile img {
    margin: 86px 0 0;
    height: 61%;
  }
}
@media only screen and (max-width: 599px) {
  .release-main__image-block picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .release-main__image-block img {
    height: auto;
    margin-top: 113px;
  }
}
.release-main__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top center;
}
.release-main__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../img/release-main/release-main-bg-shadow.png");
  background-color: rgba(22, 24, 37, 0);
  background-blend-mode: multiply, normal;
}

.release-info {
  background-color: #1c1f2f;
  padding: 134px 0px 156px 0px;
}
.release-info_mobile {
  padding: 74px 0px 104px 0px;
}
.release-info_console {
  padding: 74px 0px 164px 0px;
}
@media only screen and (max-width: 1599px) {
  .release-info {
    padding: 80px 0px 55px 0px;
    background-color: #161825;
  }
  .release-info_mobile {
    padding: 91px 0px 74px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .release-info {
    padding: 15px 0px 77px 0px;
  }
  .release-info_mobile {
    padding: 23px 0px 74px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .release-info {
    padding: 40px 0px 20px 0px;
  }
}
@media only screen and (min-width: 1024px) {
  .release-info__container_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 767px) {
  .release-info__container {
    display: flex;
    flex-direction: column;
  }
}
.release-info__content {
  display: flex;
}
@media only screen and (max-width: 1023px) {
  .release-info__content_mobile {
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 1023px) {
  .release-info__content_pc {
    display: block;
    flex-direction: row-reverse;
  }
  .release-info__content_pc .release-info__img {
    order: 2;
    padding: 0;
    margin: 0;
    flex: 0 0 300px;
  }
}
@media only screen and (max-width: 767px) {
  .release-info__content {
    flex-direction: column;
  }
  .release-info__content .release-info__img {
    width: 260px;
    max-height: 167px;
  }
}
.release-info__left {
  flex: 0 0 540px;
}
@media only screen and (min-width: 1024px) {
  .release-info__left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 1599px) {
  .release-info__left {
    flex: 0 0 422px;
    padding: 45px 0px 0px 0px;
  }
  .release-info__left .release-info__img {
    margin: 0;
  }
  .release-info__left_mobile {
    padding: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .release-info__left {
    display: flex;
    margin: 0px 0px 31px 0px;
  }
  .release-info__left_mobile {
    padding: 0;
    flex: 0 0 300px;
    margin: 0;
  }
}
@media only screen and (max-width: 767px) {
  .release-info__left {
    flex: 1 1 100%;
    flex-direction: column;
    margin: 0px 0px 22px 0px;
    padding: 0;
  }
}
.release-info__right {
  margin: 0px 0px 0px 179px;
  flex: 0 1 560px;
}
@media only screen and (max-width: 1599px) {
  .release-info__right {
    flex: 1 1 100%;
    margin: 0 0 0 44px;
  }
}
@media only screen and (max-width: 1023px) {
  .release-info__right {
    margin: 0px 0px 0px 0;
  }
  .release-info__right_mobile {
    flex: 1 1 100%;
    margin: 0 20px 0 0px;
  }
}
@media only screen and (max-width: 767px) {
  .release-info__right {
    flex: 1 1 auto;
  }
  .release-info__right_mobile {
    margin: 0 0px 0 0px;
    width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .release-info__info {
    margin-bottom: 35px;
  }
}
.release-info__info_mobile p {
  font-size: 20px;
  line-height: 24px;
}
@media only screen and (max-width: 1599px) {
  .release-info__info_mobile p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .release-info__info_mobile p {
    font-size: 12px;
    line-height: 16px;
  }
}
.release-info__img {
  display: flex;
  justify-content: center;
  margin: 0px 0px 0px -10px;
  width: 100%;
  user-select: none;
}
.release-info__img img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
@media only screen and (max-width: 767px) {
  .release-info__img {
    width: 260px;
    height: auto;
    align-self: center;
  }
}
.release-info__btn-block {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 62px 0px 0px 0px;
}
@media only screen and (max-width: 1599px) {
  .release-info__btn-block {
    padding: 50px 0px 0px 0px;
  }
  .release-info__btn-block_mobile {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1023px) {
  .release-info__btn-block {
    justify-content: start;
  }
  .release-info__btn-block_mobile {
    padding: 5px 0px 0px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .release-info__btn-block {
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 0px 0px;
    width: 229px;
    margin: 0 auto;
  }
}
.release-info__btn {
  display: inline-flex;
  overflow: visible;
  margin: 0px 0px 10px 36px;
}
.release-info__btn:first-of-type {
  margin-left: 0;
}
.release-info__btn .btn {
  width: 100%;
}
.release-info__btn .btn-inner {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-right: none;
  padding: 3px 21px 6px;
}
.release-info__btn .btn-inner span {
  margin-left: auto;
  padding: 0px 20px 0px 0px;
}
.release-info__btn .btn-icon {
  margin-right: auto;
  min-width: 24px;
}
.release-info__btn .btn-wrapper {
  width: auto;
}
@media only screen and (max-width: 767px) {
  .release-info__btn {
    margin: 0px 0px 20px 0px;
    display: inline-flex;
    width: 231px;
    align-self: center;
  }
  .release-info__btn .btn-arrow {
    width: 100%;
    justify-content: center;
  }
  .release-info__btn:last-child {
    margin: 0px 0px 0px 0px;
  }
}
.release-info__title {
  padding: 18px 0px 0px 0px;
  margin: 0px 0px 40px 0px;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  user-select: none;
}
.release-info__title_mobile {
  padding: 74px 0px 0px 0px;
  max-width: 452px;
}
@media only screen and (max-width: 1599px) {
  .release-info__title {
    padding: 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .release-info__title {
    font-size: 24px;
    line-height: 32px;
    flex: 1 1 auto;
    padding: 45px 44px 0px 0px;
    margin: 0px 0px 10px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .release-info__title {
    font-size: 20px;
    line-height: 28px;
    padding: 0px;
    margin: 0px 0px 28px 0px;
  }
}

.games-release {
  padding: 100px 0px 116px 0px;
}
@media only screen and (max-width: 1599px) {
  .games-release {
    padding: 60px 0px 62px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .games-release {
    padding: 40px 0px 29px 0px;
  }
}
.games-release__title {
  margin: 0px 0px 60px 0px;
}
@media only screen and (max-width: 1599px) {
  .games-release__title {
    margin: 0px 0px 27px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .games-release__title {
    margin: 0px 0px 37px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .games-release__title {
    margin: 0px 0px 24px 0px;
  }
}
.games-release__slider {
  overflow: hidden;
}
.games-release__slide {
  flex-shrink: 0;
  margin: 0px 0px 5px 0px;
}
@media only screen and (max-width: 1023px) {
  .games-release__slide {
    height: 252px;
    width: 288px !important;
  }
}
@media only screen and (max-width: 767px) {
  .games-release__slide {
    height: 156px;
    width: 181px !important;
  }
  .games-release__slide:first-child .image-crop__img {
    clip-path: polygon(24% 0, 100% 0, 100% 100%, 0 100%, 0 28%);
  }
}
.games-release__body {
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 0 0 35px;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .games-release__body {
    margin: 0 0 0px;
  }
}
.games-release__body-main .games-slider__slide {
  width: 439px;
  height: 320px;
  margin-right: 40px;
}
.games-release__body-main .games-slider__slide.swiper-slide:not(.swiper-slide-visible) {
  filter: grayscale(0.9);
}
@media only screen and (max-width: 1599px) {
  .games-release__body-main .games-slider__slide.swiper-slide:not(.swiper-slide-visible) {
    filter: none;
  }
}
@media only screen and (max-width: 1599px) {
  .games-release__body-main .games-slider__slide {
    width: calc((100vw - 160px) / 3);
    height: 252px;
    max-height: none;
  }
}
@media only screen and (max-width: 1023px) {
  .games-release__body-main .games-slider__slide {
    width: 293px;
    height: 252px;
    margin-right: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .games-release__body-main .games-slider__slide {
    width: 181px;
    height: 156px;
    margin-right: 12px;
  }
}
.games-release__body-main .games-release__image-crop-wrapper {
  height: 100%;
}
@media only screen and (max-width: 1599px) {
  .games-release__body-main .games-release__image-crop-wrapper {
    max-height: none;
  }
}
.games-release__body-main .games-slider__image-crop {
  margin: 0;
  width: 100%;
}
.games-release__body-main .image-crop__content {
  width: calc(100% - 16px);
  height: calc(100% - 16px);
}
.games-release__body-main .games-release__image-crop-wrapper .image-crop__img {
  transform: none;
  height: 100%;
  position: relative;
}
.games-release__body-main .games-release__image-crop-wrapper .image-crop__img::after, .games-release__body-main .games-release__image-crop-wrapper .image-crop__img::before {
  content: "";
  position: absolute;
  width: 90px;
  height: 90px;
  background-color: #161825;
}
@media only screen and (max-width: 1599px) {
  .games-release__body-main .games-release__image-crop-wrapper .image-crop__img::after, .games-release__body-main .games-release__image-crop-wrapper .image-crop__img::before {
    width: 60px;
    height: 60px;
  }
}
.games-release__body-main .games-release__image-crop-wrapper .image-crop__img::after {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}
.games-release__body-main .games-release__image-crop-wrapper .image-crop__img::before {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%) rotate(45deg);
}
@media only screen and (max-width: 767px) {
  .games-release__body-main .games-release__image-crop-wrapper .image-crop__img::before {
    display: none;
  }
}
.games-release__body-main .games-release__image-crop-wrapper .image-crop__img img {
  transform: none;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .games-release__info {
    padding: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .games-release__image-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.games-release__image-crop-wrapper {
  height: 230px;
}
.games-release__image-crop-wrapper .image-crop__img {
  transform: rotate(45deg) translate(-80px, -80px);
}
.games-release__image-crop-wrapper .image-crop__img img {
  transform: rotate(-45deg) translate(-82px, 80px);
}
@media only screen and (max-width: 1599px) {
  .games-release__image-crop-wrapper {
    height: 19.5vw;
    max-height: 230px;
  }
  .games-release__image-crop-wrapper .image-crop__img {
    transform: rotate(45deg) translate(-80px, -60px);
  }
  .games-release__image-crop-wrapper .image-crop__img img {
    transform: rotate(-45deg) translate(-67px, 67px);
  }
}
@media only screen and (max-width: 1199px) {
  .games-release__image-crop-wrapper .image-crop__info {
    padding: 24px 16px 16px;
  }
  .games-release__image-crop-wrapper .image-crop__img {
    transform: rotate(45deg) translate(-7vw, -8vw);
  }
  .games-release__image-crop-wrapper .image-crop__img img {
    transform: rotate(-45deg) translate(-7.8vw, 7.88vw);
  }
}
@media only screen and (max-width: 1023px) {
  .games-release__image-crop-wrapper {
    height: 100%;
    width: 100%;
    max-height: none;
  }
  .games-release__image-crop-wrapper .image-crop__img {
    transform: rotate(45deg) translate(-88px, -60px);
  }
  .games-release__image-crop-wrapper .image-crop__img img {
    transform: rotate(-45deg) translate(-71px, 69px);
  }
  .games-release__image-crop-wrapper .image-crop__info {
    padding: 24px 24px 16px;
  }
}
.games-release__image-crop-wrapper > .image-crop__img {
  top: -16px;
  left: 0px;
}
.games-release__image-crop-wrapper > .image-crop__img img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .games-release__image-crop-wrapper .image-crop__img {
    transform: none;
    height: 100%;
  }
  .games-release__image-crop-wrapper .image-crop__img img {
    height: 100%;
    transform: none;
  }
  .games-release__image-crop-wrapper .image-crop__info {
    padding: 10px;
  }
  .games-release__image-crop-wrapper .icon-app svg,
  .games-release__image-crop-wrapper .icon-gp svg,
  .games-release__image-crop-wrapper .icon-steam svg,
  .games-release__image-crop-wrapper .icon-p1 svg,
  .games-release__image-crop-wrapper .icon-xbox svg,
  .games-release__image-crop-wrapper .icon-playstation svg {
    display: block;
    width: 19px;
    height: 19px;
  }
  .games-release__image-crop-wrapper .icon-app img,
  .games-release__image-crop-wrapper .icon-gp img,
  .games-release__image-crop-wrapper .icon-steam img,
  .games-release__image-crop-wrapper .icon-p1 img,
  .games-release__image-crop-wrapper .icon-xbox img,
  .games-release__image-crop-wrapper .icon-playstation img {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 1199px) {
  .games-release__icons li {
    margin: 0px 8px 0px 0px;
  }
  .games-release__icons li .icon-platform {
    width: 22px;
    height: 22px;
  }
}
@media only screen and (max-width: 1023px) {
  .games-release__icons li {
    margin: 0px 12px 0px 0px;
  }
  .games-release__icons li .icon-platform {
    width: 24px;
    height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .games-release__icons li {
    margin: 0px 7px 0px 0px;
  }
  .games-release__icons li .icon-platform {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .games-release__btn {
    padding: 10px 0px;
  }
  .games-release__btn .btn-inner {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1023px) {
  .games-release__pagination {
    display: none;
  }
}
.offer {
  background-color: #1c1f2f;
  padding: 100px 0px 55px 0px;
}
@media only screen and (max-width: 1599px) {
  .offer {
    padding: 80px 0px 97px 0px;
    background-color: #161825;
  }
}
@media only screen and (max-width: 1023px) {
  .offer {
    padding: 40px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .offer {
    padding: 40px 0px 19px 0px;
  }
}
.offer__title {
  margin: 0px 0px 80px 0px;
  max-width: 540px;
}
@media only screen and (max-width: 1599px) {
  .offer__title {
    margin: 0px 0px 72px 0px;
    max-width: 520px;
  }
}
@media only screen and (max-width: 1023px) {
  .offer__title {
    margin: 0px 0px 82px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .offer__title {
    margin: 0px 0px 40px 0px;
  }
}
.offer__items {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -20px;
}
@media only screen and (max-width: 1023px) {
  .offer__items {
    margin: 0px -12px;
  }
}
.offer__item {
  width: 33.333%;
  padding: 0px 20px;
  margin: 0px 0px 94px 0px;
}
@media only screen and (max-width: 1599px) {
  .offer__item {
    margin: 0px 0px 37px 0px;
    flex: 0 1 50%;
  }
}
@media only screen and (max-width: 1023px) {
  .offer__item {
    margin: 0px 0px 60px 0px;
    padding: 0px 12px;
  }
}
@media only screen and (max-width: 767px) {
  .offer__item {
    flex: 1 1 100%;
    margin: 0px 0px 35px 0px;
  }
}

.item-offer {
  display: flex;
}
@media only screen and (max-width: 1023px) {
  .item-offer {
    flex-direction: column;
    align-items: center;
  }
}
.item-offer__img {
  min-width: 100px;
  display: flex;
  align-items: flex-start;
  user-select: none;
}
.item-offer__img img {
  width: 100%;
  object-fit: contain;
  object-position: center;
  font-family: "object-fit: contain; object-position: center;";
}
@media only screen and (max-width: 1023px) {
  .item-offer__img {
    display: block;
    margin: 0px 0px 12px 0px;
  }
}
.item-offer__info {
  flex: 1 1 auto;
  padding: 0px 0px 0px 20px;
}
@media only screen and (max-width: 1023px) {
  .item-offer__info {
    padding: 0;
    flex: none;
  }
}
.item-offer__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0px 0px 8px 0px;
}
@media only screen and (max-width: 1023px) {
  .item-offer__title {
    text-align: center;
  }
}
.item-offer__text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
@media only screen and (max-width: 1023px) {
  .item-offer__text {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .item-offer__text {
    font-size: 12px;
    line-height: 16px;
  }
}

.apply {
  padding: 120px 0px 128px 0px;
  background-color: #161825;
}
@media only screen and (max-width: 1599px) {
  .apply {
    padding: 0px 0px 40px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .apply {
    padding: 0px 0px 0px 0px;
  }
}
.apply__container {
  position: relative;
}
@media only screen and (max-width: 1599px) {
  .apply__container {
    max-width: 911px;
  }
}
.apply__content {
  display: flex;
  padding: 73px 122px 102px 0px;
}
@media only screen and (max-width: 1599px) {
  .apply__content {
    flex-direction: column;
    padding: 60px 48px 87px;
  }
}
@media only screen and (max-width: 1023px) {
  .apply__content {
    padding: 40px 0px 107px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .apply__content {
    padding: 32px 0px 105px 0px;
  }
}
.apply__info {
  flex: 1 1 auto;
  padding: 0px 155px 0px 0px;
}
@media only screen and (max-width: 1599px) {
  .apply__info {
    padding: 0px 0px 0px 0px;
    max-width: 460px;
  }
}
@media only screen and (max-width: 767px) {
  .apply__info {
    max-width: none;
  }
}
.apply__title {
  margin: 0px 0px 20px 0px;
}
@media only screen and (max-width: 1023px) {
  .apply__title {
    margin: 0px 0px 16px 0px;
  }
}
.apply__form {
  flex: 0 0 680px;
}
@media only screen and (max-width: 1599px) {
  .apply__form {
    padding: 0px;
    flex: 1 1 auto;
  }
}
.apply__frame {
  top: -16px;
  left: -46px;
  width: 108%;
  user-select: none;
}
@media only screen and (max-width: 1599px) {
  .apply__frame {
    display: none;
  }
}
.apply__bg {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  background-size: cover;
}
@media only screen and (min-width: 1600px) {
  .apply__bg {
    width: 155%;
    left: -30px;
  }
  .apply__bg:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 64.14%, rgba(0, 0, 0, 0.8) 100%);
    transform: rotate(180deg);
  }
}

.form__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 0px 40px 0px;
}
@media only screen and (max-width: 767px) {
  .form__row {
    margin: 0px 0px 0px 0px;
  }
  .form__row:nth-child(2) {
    margin: 0px 0px 20px 0px;
  }
  .form__row .space-m {
    margin: 0px 0px 12px 0px;
  }
}
.form__col {
  flex: 0 1 45.5%;
}
@media only screen and (max-width: 767px) {
  .form__col {
    flex: 1 1 100%;
    margin: 0px 0px 20px 0px;
  }
}
.form__text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #e7e7e9;
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .form__text {
    margin: 0px 0px 12px 0px;
  }
}
.form__bottom {
  position: relative;
}
.form__agreement-container {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .form__agreement-container {
    margin: 17px 0px 0px 0px;
  }
}
.form__agreement {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  padding: 0px 0px 0px 12px;
  max-width: 390px;
  user-select: none;
}
@media only screen and (max-width: 1739px) {
  .form__agreement {
    max-width: 381px;
  }
}
@media only screen and (max-width: 1599px) {
  .form__agreement {
    max-width: 390px;
  }
}
@media only screen and (max-width: 767px) {
  .form__agreement {
    font-size: 10px;
    line-height: 14px;
  }
}
.form__link {
  color: #ffffff;
}
.form__btn {
  position: absolute;
  top: 0;
  left: calc(100% - 200px);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 208px;
  text-align: left;
  overflow: visible;
}
.form__btn .btn-arrow-red {
  width: 86%;
}
.form__btn:hover {
  width: 220px;
}
.form__btn:focus {
  outline: none;
}
@media only screen and (max-width: 767px) {
  .form__btn {
    top: 53px;
    left: 0;
    width: 284px;
  }
  .form__btn .btn-arrow-red {
    width: 89.5%;
  }
  .form__btn .btn-arrow_bg {
    box-shadow: none;
  }
  .form__btn:hover {
    width: 286px;
  }
  .form__btn:hover .btn-arrow-red {
    width: 93%;
  }
}

.form--modal {
  padding-bottom: 28px;
}
@media only screen and (max-width: 767px) {
  .form--modal {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .form--modal .form__btn {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .form--modal.form .form__row {
    margin: 0;
  }
}
.form__fieldset {
  margin-top: 24px;
}
.form__fieldset:first-child {
  margin-top: 0;
}
.form__fieldset-title {
  margin: 0 0 12px;
  user-select: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
@media only screen and (max-width: 767px) {
  .form__fieldset-title {
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.7);
  }
}
@media only screen and (max-width: 767px) {
  .form__fieldset-title--mb-hide {
    display: none;
  }
}
.form__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 0px 39px 0px;
}
@media only screen and (max-width: 767px) {
  .form__row {
    margin: 0px 0px 0px 0px;
  }
  .form__row:nth-child(2) {
    margin: 0px 0px 20px 0px;
  }
  .form__row .space-m {
    margin: 0px 0px 12px 0px;
  }
}
.form__col {
  flex: 0 1 45.5%;
}
@media only screen and (max-width: 767px) {
  .form__col {
    flex: 1 1 100%;
    margin: 0 0 20px 0;
  }
}
.form__col-full {
  flex: 0 1 100%;
}
@media only screen and (max-width: 767px) {
  .form__col-full {
    flex: 1 1 100%;
    margin: 0 0 20px 0;
  }
}
.form__text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #e7e7e9;
}
@media only screen and (max-width: 767px) {
  .form__text {
    margin: 0px 0px 12px 0px;
  }
}
.form__bottom {
  position: relative;
}
.form__agreement-container {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .form__agreement-container {
    margin: 17px 0px 0px 0px;
  }
}
.form__checkbox-block {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .form__checkbox-block.open-list .checkbox-block__list {
    display: flex;
  }
}
.form__checkbox-block .checkbox-block__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin: -18px -9px 0;
}
@media only screen and (max-width: 767px) {
  .form__checkbox-block .checkbox-block__list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    width: 100%;
    margin: 0;
    border-top: 1px solid #e7e7e9;
    background-color: #1c1f2f;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.5);
    flex-direction: column;
  }
}
.form__checkbox-block .checkbox-block__text {
  display: flex;
  width: auto;
  margin: 18px 9px 0;
}
.form__checkbox-block .checkbox-block__text input:checked ~ .checkbox-block__label {
  color: #161825;
  background-color: white;
}
@media only screen and (max-width: 767px) {
  .form__checkbox-block .checkbox-block__text {
    width: 100%;
    margin: 0;
  }
}
.form__checkbox-block .checkbox-block__label, .form__checkbox-block .checkbox-block__placeholder {
  display: flex;
  padding: 18px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.5s ease;
}
.form__checkbox-block .checkbox-block__label svg, .form__checkbox-block .checkbox-block__placeholder svg {
  margin-right: 10px;
  fill: currentColor;
  stroke: currentColor;
  align-self: center;
}
.form__checkbox-block .checkbox-block__label .value, .form__checkbox-block .checkbox-block__placeholder .value {
  font: inherit;
  user-select: none;
}
.form__checkbox-block .checkbox-block__label:hover, .form__checkbox-block .checkbox-block__placeholder:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 767px) {
  .form__checkbox-block .checkbox-block__label, .form__checkbox-block .checkbox-block__placeholder {
    padding: 14px 16px;
    width: 100%;
  }
}
.form__checkbox-block .checkbox-block__placeholder {
  display: none;
}
@media only screen and (max-width: 767px) {
  .form__checkbox-block .checkbox-block__placeholder {
    display: flex;
  }
  .form__checkbox-block .checkbox-block__placeholder.active {
    color: #161825;
    background-color: white;
  }
}
.form__agreement {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  padding: 0px 0px 0px 12px;
  max-width: 390px;
}
@media only screen and (max-width: 767px) {
  .form__agreement {
    font-size: 10px;
    line-height: 14px;
  }
}
.form__link {
  color: #ffffff;
}
.form__btn {
  position: absolute;
  top: 0;
  left: calc(100% - 200px);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 208px;
  text-align: left;
}
.form__btn .btn-arrow-red {
  width: 86%;
}
.form__btn:hover {
  width: 220px;
}
.form__btn:focus {
  outline: none;
}
@media only screen and (max-width: 767px) {
  .form__btn {
    top: 53px;
    left: 0;
    width: 284px;
  }
  .form__btn .btn-arrow-red {
    width: 90%;
  }
  .form__btn .btn-arrow_bg {
    box-shadow: none;
  }
  .form__btn:hover {
    width: 286px;
  }
  .form__btn:hover .btn-arrow-red {
    width: 93%;
  }
}
.form .captcha-img {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .form .captcha-img {
    flex: 1 1 5%;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .form .captcha-input {
    flex: 1 1 10%;
  }
}

.our-games-main {
  padding: 80px 0px 116px 0px;
  background-color: #1c1f2f;
}
@media only screen and (max-width: 1599px) {
  .our-games-main {
    padding: 60px 0px 52px 0px;
    background-color: #161825;
  }
}
@media only screen and (max-width: 767px) {
  .our-games-main {
    padding: 25px 0 37px;
  }
}
@media only screen and (max-width: 767px) {
  .our-games-main__container {
    padding: 0 !important;
  }
}
.our-games-main__title {
  margin: 0px 0px 35px 0px;
}
@media only screen and (max-width: 1599px) {
  .our-games-main__title {
    margin: 0px 0px 32px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .our-games-main__title {
    padding: 0px 16px;
    margin: 0 0 10px;
  }
}
.our-games-main__slider {
  margin: 0px -1px 0px 0px;
}
.our-games-main__body {
  margin: 0px 0px 24px 0px;
}
.our-games-main__slide {
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
}
.our-games-main__slide.swiper-slide-active {
  opacity: 1;
  visibility: visible;
}
@media only screen and (max-width: 767px) {
  .our-games-main__slide {
    width: 100% !important;
  }
}
.our-games-main__control {
  display: flex;
  justify-content: flex-end;
}
.our-games-main__control.disabled {
  display: none;
}
.our-games-main__control .currentNumber {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
}
@media only screen and (max-width: 767px) {
  .our-games-main__control .currentNumber {
    font-size: 35.8919px;
    line-height: 45px;
  }
}
.our-games-main__control .slash {
  display: inline-block;
  width: 3px;
  height: 48px;
  transform: skew(-30deg);
  background-color: rgba(255, 255, 255, 0.4);
  margin: 0px 10px -10px 10px;
}
@media only screen and (max-width: 767px) {
  .our-games-main__control .slash {
    height: 35px;
    transform: skew(-25deg);
    background-color: rgba(255, 255, 255, 0.4);
    margin: 0 7px -6px 7px;
  }
}
.our-games-main__control .totalNumber {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 767px) {
  .our-games-main__control .totalNumber {
    font-size: 14.955px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .our-games-main__control {
    justify-content: space-between;
    padding: 0px 16px;
  }
}
.our-games-main__pagination {
  margin: 0px 25px 0px 0px;
}
@media only screen and (max-width: 767px) {
  .our-games-main__pagination {
    margin: 0px 0px 0px 0px;
  }
}
.our-games-main__arrow {
  outline: none;
}
@media only screen and (max-width: 767px) {
  .our-games-main__arrow .btn-slider-bg {
    width: 43.32px;
    height: 40.3px;
  }
  .our-games-main__arrow:after {
    width: 43.32px;
    height: 40.3px;
  }
}
.our-games-main__arrow_prev {
  transform: rotate(-180deg);
}
.our-games-main__arrow_prev:after {
  top: 3px;
  left: 5px;
}
@media only screen and (max-width: 767px) {
  .our-games-main__arrow_prev:after {
    left: 3.38px;
    top: 2.03px;
  }
}
.our-games-main__arrow_next {
  margin: 0px 0px 0px 8px;
}
.our-games-main__arrow_next:after {
  top: -3px;
  left: 5px;
}
@media only screen and (max-width: 767px) {
  .our-games-main__arrow_next {
    margin: 0px 0px 0px 0px;
  }
  .our-games-main__arrow_next:after {
    left: 3.38px;
    top: -2.03px;
  }
}

.inner-game {
  position: relative;
}
.inner-game__wrapper {
  position: relative;
  margin: 0px 1px 20px 0px;
}
@media only screen and (max-width: 1599px) {
  .inner-game__wrapper:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 97.6470588235%;
    height: 96%;
    transform: translate(-50%, -50%);
    border: 1.5px solid rgba(255, 255, 255, 0.4);
  }
}
@media only screen and (max-width: 767px) {
  .inner-game__wrapper {
    padding: 0;
    margin: 0px 8px;
  }
  .inner-game__wrapper:after {
    display: none;
  }
}
.inner-game__content {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  background-size: 98.85714% 96.8254%;
  padding: 226px 120px 40px 40px;
}
@media only screen and (max-width: 1599px) {
  .inner-game__content {
    padding: 14.375vw 6.25vw 4.0625vw 4.0625vw;
    background: none;
  }
}
@media only screen and (max-width: 1409px) {
  .inner-game__content {
    padding: 14.375vw 4.0625vw 4.0625vw 4.0625vw;
  }
}
@media only screen and (max-width: 1023px) {
  .inner-game__content {
    padding: 68px 40px 40px 40px;
  }
}
@media only screen and (max-width: 767px) {
  .inner-game__content {
    flex-direction: column;
    padding: 165px 8px 23px 8px;
  }
}
.inner-game__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  clip-path: polygon(11% 0, 100% 0, 100% 69%, 89% 100%, 0 100%, 0 31%);
}
.inner-game__img img {
  width: 100%;
}
.inner-game__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 64.14%, rgba(0, 0, 0, 0.8) 100%);
}
@media only screen and (max-width: 1599px) {
  .inner-game__img {
    clip-path: polygon(9% 0, 100% 0, 100% 81%, 92% 100%, 0 100%, 0 19%);
  }
}
@media only screen and (max-width: 767px) {
  .inner-game__img {
    clip-path: none;
    height: 63.5346756152%;
    width: calc(100% + 16px);
    left: -8px;
  }
  .inner-game__img:after {
    transform: rotate(180deg);
    top: 100%;
  }
}
@media only screen and (max-width: 379px) {
  .inner-game__img {
    background-size: auto 79%;
    background-position: top center;
  }
  .inner-game__img:after {
    top: 79%;
  }
}
.inner-game__img-bg {
  position: absolute;
  right: -1px;
  top: 44px;
  z-index: -1;
  width: calc(100% + 9px);
  height: 100%;
  background-size: cover;
  filter: blur(64px);
}
.inner-game__img-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(22, 24, 37, 0) 35.85%, #161825 100%);
}
.inner-game__img-bg:after {
  content: "";
}
@media only screen and (max-width: 1599px) {
  .inner-game__img-bg {
    display: none;
  }
}
.inner-game__frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 98.8571428571%;
  height: 96.8253968254%;
}
.inner-game__frame .frame-border__corner {
  height: 49%;
}
.inner-game__frame .frame-border__border {
  height: 75.5%;
  bottom: 24.5%;
}
@media only screen and (max-width: 1599px) {
  .inner-game__frame {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .inner-game__frame {
    display: block;
    width: 100%;
  }
  .inner-game__frame .frame-border__border {
    height: 91.5%;
    bottom: 8.5%;
  }
  .inner-game__frame .frame-border__corner {
    height: 17%;
  }
}

.img-game__info {
  max-width: 640px;
}
@media only screen and (max-width: 1023px) {
  .img-game__info {
    max-width: 412px;
  }
}
@media only screen and (max-width: 767px) {
  .img-game__info {
    max-width: none;
  }
}
.img-game__title {
  margin: 0px 0px 14px 0px;
}
@media only screen and (max-width: 1023px) {
  .img-game__title {
    margin: 0px 0px 10px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .img-game__title {
    margin: 0px 0px 0px 0px;
  }
}
.img-game__text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .img-game__text {
    margin: 0px 0px 18px 0px;
    font-size: 12px;
    line-height: 16px;
  }
}
.img-game__btn-block {
  display: flex;
  align-items: flex-end;
}
@media only screen and (max-width: 767px) {
  .img-game__btn-block {
    justify-content: center;
  }
}
.img-game__icons {
  margin: 0px 0px 13px 0px;
}
.img-game__icons li {
  margin: 0px 16px 0px 0px;
}
.img-game__icons .icon-platform {
  width: 32px;
  height: 32px;
}
.our-games {
  padding: 80px 0px 89px 0px;
}
@media only screen and (max-width: 1599px) {
  .our-games {
    padding: 24px 0px 61px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .our-games {
    padding: 24px 0px 51px 0px;
  }
}
.our-games__top {
  padding: 0px 0px 32px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 1599px) {
  .our-games__top {
    padding: 0px 0px 20px 0px;
    border-bottom: none;
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__top {
    padding: 0px 0px 34px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .our-games__top {
    padding: 0px 0px 10px 0px;
  }
}
.our-games__filter-toggle {
  display: none;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  padding: 0px 0px 4px 0px;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.our-games__filter-toggle span {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.our-games__filter-toggle:after {
  content: "";
  width: 14px;
  height: 8px;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translate(0%, -50%) rotate(-180deg);
  background: url("./../img/sprite/arrow-red-sm.svg") 0 0 no-repeat;
}
.our-games__filter-toggle.open:after {
  transform: translate(0%, -50%);
}
@media only screen and (max-width: 767px) {
  .our-games__filter-toggle {
    display: block;
  }
}
.our-games__filter > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1599px) {
  .our-games__filter > ul {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__filter > ul {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  .our-games__filter > ul {
    padding: 16px 0px 22px 0px;
  }
}
.our-games__filter > ul > li {
  position: relative;
}
.our-games__filter > ul > li:after {
  content: "";
  width: 1.5px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 10px;
  right: 0;
}
@media only screen and (max-width: 1599px) {
  .our-games__filter > ul > li:after {
    display: none;
  }
}
.our-games__filter > ul > li:last-child:after {
  display: none;
}
@media only screen and (max-width: 767px) {
  .our-games__filter {
    display: none;
  }
  .our-games__filter.open {
    display: block;
  }
}
.our-games__col-1 {
  flex: 0 0 55%;
}
@media only screen and (max-width: 1599px) {
  .our-games__col-1 {
    flex: 0 0 70%;
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__col-1 {
    flex: 1 1 100%;
    margin: 0px 0px 31px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .our-games__col-1 {
    margin: 0px 0px 20px 0px;
  }
}
.our-games__col-2 {
  flex: 0 0 20%;
  display: flex;
}
@media only screen and (max-width: 1599px) {
  .our-games__col-2 {
    order: 3;
    flex: 0 0 15%;
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__col-2 {
    flex: 0 0 40%;
  }
}
@media only screen and (max-width: 767px) {
  .our-games__col-2 {
    flex: 1 1 100%;
  }
}
.our-games__col-3 {
  flex: 0 0 25%;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1599px) {
  .our-games__col-3 {
    order: 2;
    flex: 0 0 15%;
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__col-3 {
    flex: 0 0 48%;
  }
}
@media only screen and (max-width: 767px) {
  .our-games__col-3 {
    flex: 1 1 100%;
    margin: 0px 0px 20px 0px;
  }
}
.our-games__body {
  padding: 40px 0px 0px 0px;
}
@media only screen and (max-width: 1599px) {
  .our-games__body {
    padding: 20px 0px 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__body {
    padding: 34px 0px 0px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .our-games__body {
    padding: 0px 0px 0px 0px;
  }
}

.filter-games__genre {
  padding: 0px 75px 0px 0px;
}
@media only screen and (max-width: 1599px) {
  .filter-games__genre {
    padding: 0px 20px 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .filter-games__genre {
    padding: 0px 0px 0px 0px;
  }
}
.filter-games__role {
  padding: 0px 30px;
}
@media only screen and (max-width: 1599px) {
  .filter-games__role {
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 1023px) {
  .filter-games__role {
    padding: 0px 0px 0px 0px;
  }
}
.filter-games__platform {
  padding: 0px 0px 0px 30px;
}
@media only screen and (max-width: 1599px) {
  .filter-games__platform {
    padding: 0px 0px 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .filter-games__platform {
    padding: 0px 40px 0px 0px;
  }
}

.filter {
  position: relative;
  width: 100%;
}
.filter__title {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0px 0px 8px 0px;
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .filter__title {
    font-size: 12px;
    line-height: 16px;
  }
}
.filter__list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  margin: 0px -8px;
  display: flex;
}
@media only screen and (max-width: 1599px) {
  .filter__list {
    margin: 0px -4px;
  }
}
.filter__item {
  padding: 0px 4px;
  margin: 0px 0px 8px 0px;
}
.filter__item input[type=checkbox]:checked ~ .filter__label {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.2);
}
.filter__input {
  display: none;
}
.filter__label {
  display: block;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  transition: all 0.5s ease;
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
@media only screen and (min-width: 768px) {
  .filter__label:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
  }
  .filter__label:active {
    background-color: #ffffff;
    color: #161825;
  }
}
@media only screen and (max-width: 767px) {
  .filter__label {
    font-size: 10px;
    padding: 4px 8px;
  }
}
.filter__platform {
  margin: 0px 8px 8px 8px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
@media only screen and (max-width: 1599px) {
  .filter__platform {
    margin: 0px 4px 8px 4px;
  }
}
.filter__platform label {
  display: block;
  cursor: pointer;
  position: relative;
}
.filter__platform .platform-icon {
  transition: all 0.5s ease;
}
.filter__platform .icon-platform {
  width: 32px;
  height: 32px;
}
@media only screen and (min-width: 768px) {
  .filter__platform .icon-platform:hover .platform-icon {
    opacity: 0;
    visibility: hidden;
  }
  .filter__platform .icon-platform:hover .platform-icon-hover {
    opacity: 1;
    visibility: visible;
  }
  .filter__platform .icon-platform:active .platform-icon {
    opacity: 0;
    visibility: hidden;
  }
  .filter__platform .icon-platform:active .platform-icon-hover {
    opacity: 1;
    visibility: visible;
  }
}
.filter__platform .platform-icon-hover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.filter__platform input[type=checkbox]:checked ~ .icon-platform > .platform-icon-hover {
  opacity: 1;
  visibility: visible;
}
.filter__platform input[type=checkbox]:checked ~ .icon-platform > .platform-icon {
  opacity: 0;
  visibility: hidden;
}

.our-games__row {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 230px;
  gap: 40px;
  margin: 0px 0px 40px 0px;
  display: -ms-grid;
  -ms-grid-columns: 1fr 40px 1fr 40px 1fr 40px 1fr;
  -ms-grid-rows: 230px 40px 230px;
}
.our-games__row:nth-child(even) {
  -ms-grid-rows: 230px;
}
.our-games__row:nth-child(even) .our-games__card:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}
.our-games__row:nth-child(even) .our-games__card:nth-child(2) {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
}
.our-games__row:nth-child(even) .our-games__card:nth-child(3) {
  -ms-grid-column: 5;
  -ms-grid-row: 1;
}
.our-games__row:nth-child(even) .our-games__card:nth-child(4) {
  -ms-grid-column: 7;
  -ms-grid-row: 1;
}
@media only screen and (max-width: 1599px) {
  .our-games__row {
    grid-auto-rows: 200px;
    gap: 32px;
    -ms-grid-columns: 1fr 32px 1fr 32px 1fr 32px 1fr;
    -ms-grid-rows: 200px 32px 200px;
    margin: 0px 0px 32px 0px;
  }
  .our-games__row:nth-child(even) {
    -ms-grid-rows: 200px;
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__row {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child {
    grid-column-end: span 2;
    grid-row-end: span 2;
    height: 54vw;
  }
}
@media only screen and (max-width: 1023px) and (max-width: 849px) {
  .our-games__row:nth-child(odd) .our-games__card:last-child {
    height: 50vw;
  }
}
@media only screen and (max-width: 1023px) and (max-width: 767px) {
  .our-games__row:nth-child(odd) .our-games__card:last-child {
    height: 56vw;
  }
}
@media only screen and (max-width: 1023px) and (max-width: 1023px) {
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__img {
    width: 100%;
    transform: rotate(45deg) translate(-19vw, -22vw);
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__img img {
    transform: rotate(-45deg) translate(-21vw, 21vw);
  }
}
@media only screen and (max-width: 1023px) and (max-width: 849px) {
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__img {
    transform: rotate(45deg) translate(-17vw, -18vw);
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__img img {
    transform: rotate(-45deg) translate(-19vw, 18vw);
  }
}
@media only screen and (max-width: 1023px) and (max-width: 767px) {
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__img {
    transform: rotate(45deg) translate(-19vw, -18vw);
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__img img {
    transform: rotate(-45deg) translate(-19vw, 18vw);
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__title,
  .our-games__row:nth-child(odd) .our-games__card:last-child .our-games__btn > .btn-inner {
    font-size: 32px;
    line-height: 40px;
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .our-games__btn {
    padding: 20px;
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__icons > li {
    margin: 0px 20px 0px 0px;
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .icon-platform {
    width: 32px;
    height: 32px;
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__title_hv {
    margin: 0px 0px 20px 0px;
  }
}
@media only screen and (max-width: 1023px) and (max-width: 549px) {
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__title,
  .our-games__row:nth-child(odd) .our-games__card:last-child .our-games__btn > .btn-inner {
    font-size: 16px;
    line-height: 16px;
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .our-games__btn {
    padding: 12px;
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__icons > li {
    margin: 0px 12px 0px 0px;
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .icon-platform {
    width: 24px;
    height: 24px;
  }
  .our-games__row:nth-child(odd) .our-games__card:last-child .image-crop__title_hv {
    margin: 0px 0px 16px 0px;
  }
}
@media only screen and (max-width: 599px) {
  .our-games__row {
    gap: 8px;
    margin: 0px 0px 8px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__card {
    height: 27vw;
  }
}
@media only screen and (max-width: 767px) {
  .our-games__card {
    height: 38vw;
  }
}
@media only screen and (max-width: 549px) {
  .our-games__card .image-crop__content {
    padding: 10px;
  }
  .our-games__card .image-crop__title,
  .our-games__card .our-games__btn > .btn-inner {
    font-size: 16px;
    line-height: 18px;
  }
  .our-games__card .our-games__btn {
    padding: 10px;
  }
  .our-games__card .image-crop__icons > li {
    margin: 0px 8px 0px 0px;
  }
  .our-games__card .icon-platform {
    width: 20px;
    height: 20px;
  }
  .our-games__card .image-crop__title_hv {
    margin: 0px 0px 8px 0px;
  }
}
@media only screen and (max-width: 449px) {
  .our-games__card .image-crop__info {
    padding: 6px;
  }
  .our-games__card .image-crop__content {
    padding: 5px;
  }
  .our-games__card .image-crop__title,
  .our-games__card .our-games__btn > .btn-inner {
    font-size: 12px;
    line-height: 16px;
  }
  .our-games__card .our-games__btn {
    padding: 5px;
  }
  .our-games__card .image-crop__icons > li {
    margin: 0px 5px 0px 0px;
  }
  .our-games__card .icon-platform {
    width: 16px;
    height: 16px;
  }
  .our-games__card .image-crop__title_hv {
    margin: 0px 0px 8px 0px;
  }
}
.our-games__image-crop .image-crop__img {
  transform: rotate(45deg) translate(-81px, -42px);
}
.our-games__image-crop .image-crop__img img {
  transform: rotate(-45deg) translate(-53px, 55px);
}
@media only screen and (max-width: 1599px) {
  .our-games__image-crop .image-crop__img {
    transform: rotate(45deg) translate(-71px, -42px);
  }
  .our-games__image-crop .image-crop__img img {
    transform: rotate(-45deg) translate(-49px, 54px);
  }
}
@media only screen and (max-width: 1023px) {
  .our-games__image-crop .image-crop__img {
    transform: rotate(45deg) translate(-9.5vw, -11vw);
  }
  .our-games__image-crop .image-crop__img img {
    transform: rotate(-45deg) translate(-10.5vw, 11vw);
  }
}
@media only screen and (max-width: 767px) {
  .our-games__image-crop .image-crop__img {
    transform: rotate(45deg) translate(-13.5vw, -11vw);
  }
  .our-games__image-crop .image-crop__img img {
    transform: rotate(-45deg) translate(-11.5vw, 12.3vw);
  }
}
.our-games__more {
  margin: 60px 0px 0px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media only screen and (max-width: 767px) {
  .our-games__more {
    margin: 36px 0px 0px 0px;
  }
}
.our-games__more-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.our-games__more-btn-inner {
  text-align: center;
  margin: 0px 0px 15px 0px;
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .our-games__more-btn-inner {
    margin: 0px 0px 10px 0px;
  }
}
.our-games__more-btn-icon {
  transform: rotate(90deg);
  margin-left: -10px !important;
}

@media only screen and (min-width: 1024px) {
  .card-right .our-games__card:nth-child(3) {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
  .card-right .our-games__card:nth-child(3) .image-crop__info {
    padding: 35px 35px 20px;
  }
  .card-right .our-games__card:nth-child(3) .image-crop__img {
    width: 110%;
    transform: rotate(45deg) translate(-200px, -78px);
  }
  .card-right .our-games__card:nth-child(3) .image-crop__img img {
    transform: rotate(-45deg) translate(-123px, 123px);
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1599px) {
  .card-right .our-games__card:nth-child(3) .image-crop__img {
    width: 100%;
    transform: rotate(45deg) translate(-155px, -170px);
  }
  .card-right .our-games__card:nth-child(3) .image-crop__img img {
    transform: rotate(-45deg) translate(-164px, 167px);
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .card-right .our-games__card:nth-child(3) .image-crop__img {
    width: 50vw;
    transform: rotate(45deg) translate(-175px, -150px);
  }
  .card-right .our-games__card:nth-child(3) .image-crop__img img {
    transform: rotate(-45deg) translate(-147px, 167px);
  }
}
@media only screen and (min-width: 1024px) {
  .card-right .our-games__card:nth-child(3) .image-crop__title,
  .card-right .our-games__card:nth-child(3) .our-games__btn > .btn-inner {
    font-size: 32px;
    line-height: 40px;
  }
  .card-right .our-games__card:nth-child(3) .our-games__btn {
    padding: 20px;
  }
  .card-right .our-games__card:nth-child(3) .image-crop__icons > li {
    margin: 0px 20px 0px 0px;
  }
  .card-right .our-games__card:nth-child(3) .icon-platform {
    width: 32px;
    height: 32px;
  }
  .card-right .our-games__card:nth-child(3) .image-crop__title_hv {
    margin: 0px 0px 20px 0px;
  }
  .card-center .our-games__card:nth-child(2) {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
  .card-center .our-games__card:nth-child(2) .image-crop__info {
    padding: 35px 35px 20px;
  }
  .card-center .our-games__card:nth-child(2) .image-crop__img {
    width: 110%;
    transform: rotate(45deg) translate(-200px, -78px);
  }
  .card-center .our-games__card:nth-child(2) .image-crop__img img {
    transform: rotate(-45deg) translate(-123px, 123px);
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1599px) {
  .card-center .our-games__card:nth-child(2) .image-crop__img {
    width: 100%;
    transform: rotate(45deg) translate(-155px, -170px);
  }
  .card-center .our-games__card:nth-child(2) .image-crop__img img {
    transform: rotate(-45deg) translate(-164px, 167px);
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .card-center .our-games__card:nth-child(2) .image-crop__img {
    width: 50vw;
    transform: rotate(45deg) translate(-175px, -150px);
  }
  .card-center .our-games__card:nth-child(2) .image-crop__img img {
    transform: rotate(-45deg) translate(-147px, 167px);
  }
}
@media only screen and (min-width: 1024px) {
  .card-center .our-games__card:nth-child(2) .image-crop__title,
  .card-center .our-games__card:nth-child(2) .our-games__btn > .btn-inner {
    font-size: 32px;
    line-height: 40px;
  }
  .card-center .our-games__card:nth-child(2) .our-games__btn {
    padding: 20px;
  }
  .card-center .our-games__card:nth-child(2) .image-crop__icons > li {
    margin: 0px 20px 0px 0px;
  }
  .card-center .our-games__card:nth-child(2) .icon-platform {
    width: 32px;
    height: 32px;
  }
  .card-center .our-games__card:nth-child(2) .image-crop__title_hv {
    margin: 0px 0px 20px 0px;
  }
  .card-left .our-games__card:nth-child(1) {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
  .card-left .our-games__card:nth-child(1) .image-crop__info {
    padding: 35px 35px 20px;
  }
  .card-left .our-games__card:nth-child(1) .image-crop__img {
    width: 110%;
    transform: rotate(45deg) translate(-200px, -78px);
  }
  .card-left .our-games__card:nth-child(1) .image-crop__img img {
    transform: rotate(-45deg) translate(-123px, 123px);
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1599px) {
  .card-left .our-games__card:nth-child(1) .image-crop__img {
    width: 100%;
    transform: rotate(45deg) translate(-155px, -170px);
  }
  .card-left .our-games__card:nth-child(1) .image-crop__img img {
    transform: rotate(-45deg) translate(-164px, 167px);
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .card-left .our-games__card:nth-child(1) .image-crop__img {
    width: 50vw;
    transform: rotate(45deg) translate(-175px, -150px);
  }
  .card-left .our-games__card:nth-child(1) .image-crop__img img {
    transform: rotate(-45deg) translate(-147px, 167px);
  }
}
@media only screen and (min-width: 1024px) {
  .card-left .our-games__card:nth-child(1) .image-crop__title,
  .card-left .our-games__card:nth-child(1) .our-games__btn > .btn-inner {
    font-size: 32px;
    line-height: 40px;
  }
  .card-left .our-games__card:nth-child(1) .our-games__btn {
    padding: 20px;
  }
  .card-left .our-games__card:nth-child(1) .image-crop__icons > li {
    margin: 0px 20px 0px 0px;
  }
  .card-left .our-games__card:nth-child(1) .icon-platform {
    width: 32px;
    height: 32px;
  }
  .card-left .our-games__card:nth-child(1) .image-crop__title_hv {
    margin: 0px 0px 20px 0px;
  }
}
.card-right .our-games__card:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}
.card-right .our-games__card:nth-child(2) {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
}
.card-right .our-games__card:nth-child(3) {
  -ms-grid-column: 5;
  -ms-grid-row: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row-span: 3;
}
.card-right .our-games__card:nth-child(4) {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}
.card-right .our-games__card:nth-child(5) {
  -ms-grid-column: 3;
  -ms-grid-row: 3;
}

.card-center .our-games__card:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}
.card-center .our-games__card:nth-child(2) {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row-span: 3;
}
.card-center .our-games__card:nth-child(3) {
  -ms-grid-column: 7;
  -ms-grid-row: 1;
}
.card-center .our-games__card:nth-child(4) {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}
.card-center .our-games__card:nth-child(5) {
  -ms-grid-column: 7;
  -ms-grid-row: 3;
}

.card-left .our-games__card:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row-span: 3;
}
.card-left .our-games__card:nth-child(2) {
  -ms-grid-column: 5;
  -ms-grid-row: 1;
}
.card-left .our-games__card:nth-child(3) {
  -ms-grid-column: 7;
  -ms-grid-row: 1;
}
.card-left .our-games__card:nth-child(4) {
  -ms-grid-column: 5;
  -ms-grid-row: 3;
}
.card-left .our-games__card:nth-child(5) {
  -ms-grid-column: 7;
  -ms-grid-row: 3;
}

.presskit {
  padding: 80px 0px 160px 0px;
}
@media only screen and (max-width: 1599px) {
  .presskit {
    padding: 60px 0px 48px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .presskit {
    padding: 41px 0px 28px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .presskit {
    padding: 24px 0px 26px 0px;
  }
}
.presskit__title {
  margin: 0px 0px 40px 0px;
}
@media only screen and (max-width: 1599px) {
  .presskit__title {
    margin: 0px 0px 32px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .presskit__title {
    margin: 0px 0px 24px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .presskit__title {
    margin: 0px 0px 20px 0px;
  }
}
.presskit__search {
  margin: 0px 0px 40px 0px;
  width: 480px;
}
@media only screen and (max-width: 1023px) {
  .presskit__search {
    width: 448px;
  }
}
@media only screen and (max-width: 767px) {
  .presskit__search {
    width: 100%;
  }
}
.presskit__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -20px;
}
@media only screen and (max-width: 1399px) {
  .presskit__list {
    margin: 0px -16px;
  }
}
@media only screen and (max-width: 599px) {
  .presskit__list {
    margin: 0px -4px;
  }
}
.presskit__item {
  width: 25%;
  padding: 0px 20px;
  margin: 0px 0px 40px 0px;
  height: 230px;
}
.presskit__item .image-crop__img {
  transform: rotate(45deg) translate(-81px, -49px);
}
.presskit__item .image-crop__img img {
  transform: rotate(-45deg) translate(-58.5px, 57px);
}
@media only screen and (max-width: 1599px) {
  .presskit__item .image-crop__img {
    transform: rotate(45deg) translate(-79px, -60px);
  }
  .presskit__item .image-crop__img img {
    transform: rotate(-45deg) translate(-69px, 64px);
  }
}
@media only screen and (max-width: 1399px) {
  .presskit__item {
    padding: 0px 16px;
    height: 200px;
    margin: 0px 0px 32px 0px;
  }
  .presskit__item .image-crop__img {
    transform: rotate(45deg) translate(-68px, -60px);
  }
  .presskit__item .image-crop__img img {
    transform: rotate(-45deg) translate(-65px, 62px);
  }
}
@media only screen and (max-width: 1199px) {
  .presskit__item .image-crop__info {
    padding: 5px 10px 10px;
  }
}
@media only screen and (max-width: 1023px) {
  .presskit__item {
    flex: 0 1 33.333%;
  }
  .presskit__item .image-crop__img {
    transform: rotate(45deg) translate(-70px, -60px);
  }
  .presskit__item .image-crop__img img {
    transform: rotate(-45deg) translate(-63px, 64px);
  }
}
.presskit__item > .image-crop__img {
  top: -16px;
  left: 0px;
}
.presskit__item > .image-crop__img img {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 767px) {
  .presskit__item {
    flex: 0 1 50%;
    height: 29vw;
    max-height: 200px;
    min-height: 120px;
  }
  .presskit__item .image-crop__img {
    transform: rotate(45deg) translate(-10vw, -10vw);
  }
  .presskit__item .image-crop__img img {
    transform: rotate(-45deg) translate(-10.3vw, 10vw);
  }
  .presskit__item .image-crop__info {
    padding: 10px;
  }
}
@media only screen and (max-width: 599px) {
  .presskit__item {
    padding: 0px 4px;
    margin: 0px 0px 8px 0px;
  }
  .presskit__item .image-crop__info {
    padding: 6px;
  }
}
@media only screen and (max-width: 419px) {
  .presskit__item .image-crop__img {
    transform: rotate(45deg) translate(-42px, -42px);
  }
  .presskit__item .image-crop__img img {
    transform: rotate(-45deg) translate(-42.5px, 42.5px);
  }
}
@media only screen and (max-width: 599px) {
  .presskit__content {
    width: 97.1428571429%;
    height: 96.6666666667%;
    padding: 8px;
  }
}
@media only screen and (max-width: 599px) {
  .presskit__image-title {
    font-size: 12px;
    line-height: 16px;
  }
}
.presskit__info {
  display: flex;
  justify-content: space-between;
}
.presskit__btn-about {
  margin: 0px 0px 12px 0px;
}
@media only screen and (max-width: 599px) {
  .presskit__btn-about {
    padding: 6px;
    margin: 0px 0px 5px 0px;
  }
  .presskit__btn-about .btn-inner {
    font-size: 10px;
    line-height: 12px;
  }
}
@media only screen and (max-width: 599px) {
  .presskit__btn-download {
    padding: 5px;
  }
  .presskit__btn-download .download__text_sm {
    font-size: 8px;
    line-height: 10px;
  }
  .presskit__btn-download:after {
    width: 27px;
    height: 27px;
    top: -17px;
    right: -17px;
  }
  .presskit__btn-download:before {
    border: 8px solid transparent;
    border-right: 8px solid #161825;
    top: 5px;
    right: 5px;
  }
}
.join {
  padding: 136px 0px 126px 0px;
  position: relative;
}
@media only screen and (max-width: 1599px) {
  .join {
    padding: 60px 0px 88px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .join {
    padding: 24px 0px 23px 0px;
  }
}
.join__container {
  position: relative;
  z-index: 2;
}
.join__title {
  margin: 0px 0px 16px 0px;
  max-width: 679px;
}
@media only screen and (max-width: 767px) {
  .join__title {
    margin: 0px 0px 8px 0px;
    max-width: none;
  }
}
.join__text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0px 0px 56px 0px;
  max-width: 560px;
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .join__text {
    font-size: 12px;
    line-height: 16px;
    max-width: none;
    margin: 0px 0px 109px 0px;
  }
}
.join__btn {
  width: 305px;
  display: inline-flex;
}
.join__btn:hover {
  width: 315px;
}
.join__btn .btn-arrow-red {
  width: 95.5%;
  justify-content: flex-start;
}
@media only screen and (max-width: 767px) {
  .join__btn {
    width: 285px;
  }
  .join__btn:hover {
    width: 293px;
  }
  .join__btn .btn-arrow-red {
    width: 94%;
  }
}
.join__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.join__bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #161825 1.25%, rgba(22, 24, 37, 0) 81.21%), linear-gradient(0deg, rgba(21, 78, 119, 0.2), rgba(21, 78, 119, 0.2));
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.vacancy {
  margin: 0 auto;
  padding: 80px 0px;
}
@media only screen and (max-width: 1599px) {
  .vacancy {
    padding: 60px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .vacancy {
    padding: 35px 0px;
  }
}
.vacancy__container {
  max-width: 1000px;
}
@media only screen and (max-width: 1599px) {
  .vacancy__container {
    max-width: none;
  }
}
.vacancy__filter {
  margin: 0px 0px 37px 0px;
}
@media only screen and (max-width: 767px) {
  .vacancy__filter {
    margin: 0px 0px 32px 0px;
  }
}
.vacancy__items {
  list-style: none;
  padding: 0;
  margin: 0;
}
.vacancy__item {
  margin: 0px 0px 20px 0px;
  position: relative;
}
.vacancy__item:last-child {
  margin: 0px 0px 0px 0px;
}
.vacancy__item:hover .vacancy__frame {
  top: 0px;
  left: 0px;
}
.vacancy__frame {
  top: -4px;
  left: -4px;
  z-index: 2;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .vacancy__frame .frame-border__corner {
    height: 24px;
  }
  .vacancy__frame .frame-border__border {
    height: calc(100% - 12.25px);
    bottom: 12.3px;
  }
}
.vacancy__top {
  padding: 28px 24px 28px 40px;
  display: flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.1);
}
@media only screen and (max-width: 767px) {
  .vacancy__top {
    padding: 4px 8px 14px 8px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.vacancy__top.active:after {
  width: 0;
  height: 0;
}
.vacancy__top.active ~ .vacancy__frame {
  top: 0px;
  left: 0px;
}
.vacancy__top:after {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #161825;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%) rotate(45deg);
  transition: all 0.3s linear;
}
@media only screen and (max-width: 767px) {
  .vacancy__top:after {
    width: 20px;
    height: 20px;
  }
}
.vacancy__title {
  margin: 0px 0px 32px 0px;
}
@media only screen and (max-width: 767px) {
  .vacancy__title {
    margin: 0px 0px 12px 0px;
  }
}
.vacancy__label {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0;
  user-select: none;
}
@media only screen and (max-width: 767px) {
  .vacancy__label {
    font-size: 14px;
    line-height: 20px;
  }
}
.vacancy__conditions {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  user-select: none;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .vacancy__conditions {
    font-size: 12px;
    line-height: 16px;
  }
}
.vacancy__body {
  position: relative;
  z-index: 3;
}

.body-vacancy {
  display: none;
  overflow: hidden;
  height: auto;
  will-change: height;
  -webkit-transform: translate3d(0, 0, 0);
}
.body-vacancy__content {
  padding: 26px 41px;
}
@media only screen and (max-width: 767px) {
  .body-vacancy__content {
    padding: 12px 9px;
    flex-direction: column;
    align-items: center;
  }
}
.body-vacancy__info {
  max-width: 520px;
  padding: 0px 0px 11px 0px;
}
.body-vacancy__info p,
.body-vacancy__info ul > li {
  color: rgba(255, 255, 255, 0.8);
}
.body-vacancy__info p:before,
.body-vacancy__info ul > li:before {
  background-color: rgba(255, 255, 255, 0.8);
}
@media only screen and (max-width: 767px) {
  .body-vacancy__info {
    padding: 0px 0px 46px 0px;
    max-width: none;
  }
}
.body-vacancy__bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 19px 0px 0px 0px;
}
@media only screen and (max-width: 767px) {
  .body-vacancy__bottom {
    border: none;
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    width: 100%;
  }
}
.body-vacancy__btn {
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
}
.body-vacancy__btn .btn-inner {
  padding: 14px 36px;
}
.body-vacancy__btn_grey {
  display: none;
}
@media only screen and (max-width: 767px) {
  .body-vacancy__btn_grey {
    display: flex;
    margin: 0px 0px 8px 0px;
  }
}
.body-vacancy__social {
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .body-vacancy__social {
    display: none;
  }
}
.body-vacancy__social .social__label {
  text-align: right;
}
.body-vacancy__social .social__icons {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.body-vacancy__social .social__icons li {
  margin: 0px 12px 0px 0px;
}
.body-vacancy__social .social__icons li:last-child {
  margin: 0px 0px 0px 0px;
}
.body-vacancy__icon {
  display: block;
}
.body-vacancy__icon:hover .icon-tw {
  fill: #ffffff;
}

.contacts-join {
  padding: 100px 0px 177px;
  background-color: #1c1f2f;
}
@media only screen and (max-width: 1023px) {
  .contacts-join {
    padding: 20px 0px 72px 0px;
    background-color: #161825;
  }
}
.contacts-join__container {
  max-width: 920px;
}
@media only screen and (max-width: 1599px) {
  .contacts-join__container {
    max-width: none;
  }
}
@media only screen and (max-width: 1599px) {
  .contacts-join__body {
    display: flex;
  }
}
@media only screen and (max-width: 1023px) {
  .contacts-join__body {
    flex-direction: column;
  }
}
.contacts-join__title {
  margin: 0px 0px 24px 0px;
}
@media only screen and (max-width: 767px) {
  .contacts-join__title {
    margin: 0px 0px 7px 0px;
  }
}
.contacts-join__persons {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin: 0px -24px 43px -24px;
}
@media only screen and (max-width: 1599px) {
  .contacts-join__persons {
    flex-direction: column;
    margin: 0px 0px 0px 0px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 1023px) {
  .contacts-join__persons {
    display: block;
    margin: 0px 0px 40px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .contacts-join__persons {
    margin: 0px 0px 60px 0px;
  }
}
.contacts-join__person {
  width: 50%;
  margin: 0px 24px;
}
@media only screen and (max-width: 1599px) {
  .contacts-join__person {
    margin: 0px 0px 25px 0px;
  }
  .contacts-join__person:last-child {
    margin: 0px 0px 0px 0px;
  }
}
@media only screen and (max-width: 1599px) {
  .contacts-join__links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1023px) {
  .contacts-join__links {
    margin: 0px 0px 0px 0px;
    display: flex;
  }
}
@media only screen and (max-width: 767px) {
  .contacts-join__links {
    display: block;
  }
}
.contacts-join__soc_link {
  display: block;
  margin: 0 0 20px;
}
.contacts-join__soc_link .btn-icon {
  margin-top: 3px;
}
.contacts-join__soc_link .btn-inner {
  line-height: 40px;
}
@media only screen and (max-width: 767px) {
  .contacts-join__soc_link .btn-inner {
    font-size: 20px;
  }
}
.contacts-join__links:first-child {
  position: relative;
  margin: 0px 0px 20px 0px;
}
@media only screen and (max-width: 1023px) {
  .contacts-join__links:first-child {
    margin: 0px 83px 0px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .contacts-join__links:first-child {
    margin: 0px 0px 15px 0px;
  }
}
.contacts-join__links:last-child {
  position: relative;
}
.contacts-join__logo {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.person__wrapper {
  min-width: 444px;
  flex-basis: 444px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 17px 15px 24px 16px;
  position: relative;
}
@media only screen and (max-width: 1023px) {
  .person__wrapper {
    display: inline-flex;
  }
}
@media only screen and (max-width: 767px) {
  .person__wrapper {
    padding: 13px 13px 13px 9px;
    min-width: 288px;
    margin: 0px 0px 12px 0px;
  }
}
.person__image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
}
.person__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
@media only screen and (max-width: 767px) {
  .person__image {
    width: 69px;
    height: 69px;
  }
}
.person__info {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 22px;
  justify-content: center;
}
.person__name {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0px 0px 8px 0px;
}
@media only screen and (max-width: 767px) {
  .person__name {
    margin: 0px 0px 4px 0px;
  }
}
.person__proffesion {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px 0px 14px 0px;
}
@media only screen and (max-width: 767px) {
  .person__proffesion {
    margin: 0px 0px 0px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .person__contact {
    display: flex;
  }
}
.person__contact a {
  display: block;
  position: relative;
  z-index: 2;
  user-select: none;
}
.person__frame {
  top: 0;
  left: 0;
}

.person-contact__email {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px 0px 4px 0px;
  padding: 0px 0px 0px 23px;
  background: url("./../img/icons/email.svg") center left no-repeat;
}
@media only screen and (max-width: 767px) {
  .person-contact__email {
    font-size: 12px;
    line-height: 16px;
    margin: 0px 25px 0px 0px;
  }
}
.person-contact__telegram {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  padding: 0px 0px 0px 23px;
  background: url("./../img/icons/telegram-mini.svg") center left no-repeat;
}
@media only screen and (max-width: 767px) {
  .person-contact__telegram {
    font-size: 12px;
    line-height: 16px;
  }
}

.game-detail-main {
  position: relative;
}
.game-detail-main__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 515px;
  overflow: hidden;
}
.game-detail-main__bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}
@media only screen and (max-width: 1599px) {
  .game-detail-main__bg {
    height: 36vw;
  }
}
.game-detail-main__bg-wrapper {
  position: absolute;
  top: 0;
  left: -7.5%;
  width: 115%;
  height: 110%;
  background-size: cover;
  filter: blur(24px);
}
.game-detail-main__logo {
  position: absolute;
  top: 74px;
  left: 50%;
  transform: translate(-50%, 0);
}
@media only screen and (max-width: 1599px) {
  .game-detail-main__logo {
    max-width: 300px;
  }
}
@media only screen and (max-width: 1023px) {
  .game-detail-main__logo {
    display: none;
  }
}
.game-detail-main__frame {
  top: 1%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 99%;
  height: 90%;
}
.game-detail-main__frame .frame-border__corner {
  height: 208px;
}
.game-detail-main__frame .frame-border__border {
  height: calc(100% - 104.25px);
  bottom: 104.25px;
}
@media only screen and (max-width: 1599px) {
  .game-detail-main__frame {
    top: 5.8%;
    height: 88%;
    width: 99%;
    border: 1.5px solid rgba(255, 255, 255, 0.4);
  }
  .game-detail-main__frame .frame-border__corner,
  .game-detail-main__frame .frame-border__border {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .game-detail-main__frame {
    top: 1.2%;
    height: 92%;
    width: 98%;
    left: 50.2%;
  }
}
@media only screen and (max-width: 767px) {
  .game-detail-main__frame {
    top: 1%;
    height: 98%;
    width: 98.2%;
    left: 50%;
  }
}
.game-detail-main__image-crop {
  position: relative;
  z-index: 2;
  padding: 37px 0px 0px 0px;
}
@media only screen and (max-width: 1599px) {
  .game-detail-main__image-crop {
    padding: 20px 0px 0px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .game-detail-main__image-crop {
    padding: 5px 0px 0px 0px;
  }
}
.game-detail-main__image-crop-wrapper {
  height: 602px;
}
@media only screen and (max-width: 1599px) {
  .game-detail-main__image-crop-wrapper {
    height: 42vw;
  }
}
@media only screen and (max-width: 1023px) {
  .game-detail-main__image-crop-wrapper {
    height: 39vw;
  }
}
@media only screen and (max-width: 767px) {
  .game-detail-main__image-crop-wrapper {
    height: 55vw;
  }
}
.game-detail-main__image-crop-wrapper .image-crop__img {
  width: 100%;
  transform: rotate(-45deg) translate(220px, -231px);
}
.game-detail-main__image-crop-wrapper .image-crop__img img {
  height: 47%;
  transform: rotate(45deg) translate(234px, 213px);
}
@media only screen and (max-width: 1599px) {
  .game-detail-main__image-crop-wrapper .image-crop__img {
    transform: rotate(-45deg) translate(15vw, -15vw);
  }
  .game-detail-main__image-crop-wrapper .image-crop__img img {
    max-height: none;
    height: 45%;
    transform: rotate(45deg) translate(16.5vw, 16.5vw);
  }
}
@media only screen and (max-width: 1023px) {
  .game-detail-main__image-crop-wrapper .image-crop__img {
    transform: rotate(-45deg) translate(15vw, -14vw);
  }
  .game-detail-main__image-crop-wrapper .image-crop__img img {
    height: 50%;
    transform: rotate(45deg) translate(13.5vw, 12.5vw);
  }
}
@media only screen and (max-width: 767px) {
  .game-detail-main__image-crop-wrapper .image-crop__img {
    transform: rotate(45deg) translate(-19vw, -18vw);
  }
  .game-detail-main__image-crop-wrapper .image-crop__img img {
    transform: rotate(-45deg) translate(-18.7vw, 18vw);
  }
}

.game-detail-description {
  padding: 116px 0px 27px 0px;
}
@media only screen and (max-width: 1599px) {
  .game-detail-description {
    padding: 56px 0px 27px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .game-detail-description {
    padding: 60px 0px 27px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .game-detail-description {
    padding: 24px 0px 31px 0px;
  }
}
.game-detail-description__info {
  max-width: 920px;
  margin: 0 auto;
}
.game-detail-description__icons-platforms .icon-platform {
  width: 32px;
  height: 32px;
}
.game-detail-description__icons-platforms li {
  margin: 0px 16px 0px 0px;
}
.game-detail-description__icons-platforms li:last-child {
  margin: 0px 0px 0px 0px;
}
.game-detail-description__top {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 16px 0px;
}
@media only screen and (max-width: 767px) {
  .game-detail-description__top {
    display: block;
    margin: 0px 0px 24px 0px;
  }
}
.game-detail-description__title {
  max-width: 439px;
  margin: 0px 0px 16px 0px;
}
.game-detail-description__text {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px 0px 136px 0px;
  position: relative;
  z-index: 2;
}
.game-detail-description__text p {
  margin: 0;
}
@media only screen and (max-width: 1599px) {
  .game-detail-description__text {
    margin: 0px 0px 80px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .game-detail-description__text {
    margin: 0px 0px 60px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .game-detail-description__text {
    font-size: 12px;
    line-height: 16px;
    margin: 0px 0px 34px 0px;
  }
}
.game-detail-description__cards {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin: 0px 0px 128px 0px;
}
.game-detail-description__content {
  max-width: 920px;
  margin: 0 auto 141px auto;
}
.game-detail-description__content ul > li {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  line-height: 28px;
}
.game-detail-description__content ul > li:before {
  background-color: rgba(255, 255, 255, 0.7);
}
.game-detail-description__content span {
  margin: 0px 0px 8px 0px;
}
@media only screen and (max-width: 1599px) {
  .game-detail-description__content ul {
    margin: 0px 0px 30px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .game-detail-description__content {
    max-width: none;
    margin: 0 0 81px 0;
  }
  .game-detail-description__content ul > li {
    line-height: 20px;
  }
  .game-detail-description__content ul {
    margin: 0px 0px 25px 0px;
  }
}
.game-detail-description__btn {
  display: flex;
  justify-content: center;
}

.game-detail-description__cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -20px 88px -20px;
}
@media only screen and (max-width: 1599px) {
  .game-detail-description__cards {
    margin: 0px -12px 76px -12px;
  }
}
@media only screen and (max-width: 767px) {
  .game-detail-description__cards {
    margin: 0px -4px 24px -4px;
  }
}
.game-detail-description__card {
  padding: 0 20px;
  margin: 0px 0px 40px 0px;
  position: relative;
  user-select: none;
}
.game-detail-description__card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-detail-description__card_video span {
  position: absolute;
  width: 120px;
  height: 120px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.game-detail-description__card_video span:before {
  content: "";
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 43px;
  height: 49px;
  transform: translate(-35%, -50%);
  background: url("./..//img/sprite/video-play.svg") 0 0 no-repeat;
}
.game-detail-description__card_video span:after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 66px;
  height: 77px;
  transform: translate(-35%, -50%);
  background: url("./../img/sprite/video-play-frame.svg") 0 0 no-repeat;
}
@media only screen and (max-width: 1023px) {
  .game-detail-description__card_video span {
    width: 80px;
    height: 80px;
  }
  .game-detail-description__card_video span:before, .game-detail-description__card_video span:after {
    width: 38px;
    height: 38px;
  }
}
@media only screen and (max-width: 1599px) {
  .game-detail-description__card {
    padding: 0px 12px;
    margin: 0px 0px 24px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .game-detail-description__card {
    margin: 0px 0px 16px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .game-detail-description__card {
    flex: 0 1 50% !important;
    max-height: 240px !important;
    min-height: 89px !important;
    margin: 0px 0px 8px 0px;
    padding: 0px 4px;
  }
  .game-detail-description__card:first-child {
    flex: 1 1 100% !important;
    max-height: 360px !important;
    min-height: 161px !important;
  }
  .game-detail-description__card:first-child:before {
    width: 95.5%;
    height: 95.5%;
  }
}
.game-detail-description__card:before {
  content: "";
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1.5px solid rgba(255, 255, 255, 0.4);
}
.game-detail-description__card:first-child:after {
  content: "";
  position: absolute;
  background-color: #161825;
  width: 110px;
  height: 110px;
  top: -112px;
  left: -97px;
  transform: translate(50%, 50%) rotate(45deg);
}
@media only screen and (max-width: 1023px) {
  .game-detail-description__card:first-child:after {
    width: 100px;
    height: 100px;
    top: -100px;
    left: -100px;
  }
}
@media only screen and (max-width: 399px) {
  .game-detail-description__card:first-child:after {
    width: 50px;
    height: 50px;
    top: -50px;
    left: -50px;
  }
}

.card-lg {
  width: 50%;
  height: 360px;
}
.card-lg:before {
  width: calc(97.6470588235% - 40px);
  height: 96.1111111111%;
}
@media only screen and (max-width: 1599px) {
  .card-lg:before {
    width: calc(96.5217391304% - 24px);
    height: 93.3333333333%;
  }
}
@media only screen and (max-width: 1023px) {
  .card-lg:before {
    width: calc(97.5903614458% - 24px);
    height: 95.6989247312%;
  }
}
@media only screen and (max-width: 767px) {
  .card-lg:before {
    width: 92.5%;
    height: 93.5%;
  }
}
@media only screen and (max-width: 1599px) {
  .card-lg {
    height: auto;
    max-height: 360px;
    min-height: 240px;
  }
}
@media only screen and (max-width: 767px) {
  .card-lg {
    max-height: 240px;
    min-height: 176px;
  }
}

.card-sm {
  width: 33.333%;
  height: 240px;
}
.card-sm:before {
  width: calc(96.3636363636% - 40px);
  height: 93.3333333333%;
}
@media only screen and (max-width: 1599px) {
  .card-sm:before {
    width: calc(94.6666666667% - 24px);
    height: 91.1111111111%;
  }
}
@media only screen and (max-width: 1023px) {
  .card-sm:before {
    width: calc(96.2441314554% - 24px);
    height: 93.3333333333%;
  }
}
@media only screen and (max-width: 767px) {
  .card-sm:before {
    width: 92.5%;
    height: 93.5%;
  }
}
.card-sm:last-child {
  position: relative;
}
.card-sm:last-child:after {
  content: "";
  position: absolute;
  width: 90px;
  height: 90px;
  background-color: #161825;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%) rotate(45deg);
}
@media only screen and (max-width: 1023px) {
  .card-sm:last-child:after {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 1599px) {
  .card-sm {
    height: auto;
    max-height: 240px;
    min-height: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .card-sm {
    max-height: 180px;
    min-height: 116px;
  }
}

@media only screen and (max-width: 767px) {
  .fancybox-slide video,
  .fancybox-slide img {
    width: 100%;
    height: auto;
  }
}

.another-games {
  padding: 120px 0px 64px 0px;
}
@media only screen and (max-width: 1599px) {
  .another-games {
    padding: 80px 0px 60px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .another-games {
    padding: 60px 0px 80px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .another-games {
    padding: 24px 0px 46px 0px;
  }
}
.another-games__titile {
  margin: 0px 0px 40px 0px;
}
@media only screen and (max-width: 1599px) {
  .another-games__titile {
    margin: 0px 0px 24px 0px;
  }
}
.another-games__body {
  overflow: hidden;
}
.another-games__image-crop-wrapper {
  height: 312px;
}
@media only screen and (max-width: 1599px) {
  .another-games__image-crop-wrapper {
    height: 24vw;
    max-height: 312px;
  }
}
@media only screen and (max-width: 1023px) {
  .another-games__image-crop-wrapper {
    height: 23vw;
    max-height: 250px;
  }
}
@media only screen and (max-width: 766px) {
  .another-games__image-crop-wrapper {
    height: 200px;
    width: 215px;
  }
}
@media only screen and (max-width: 599px) {
  .another-games__image-crop-wrapper {
    height: 156px;
    width: 180px;
  }
  .another-games__image-crop-wrapper .image-crop__title_hv {
    font-size: 16px;
    line-height: 18px;
  }
}
.another-games__img-block .image-crop__img {
  transform: rotate(45deg) translate(-110px, -100px);
}
.another-games__img-block .image-crop__img img {
  transform: rotate(-45deg) translate(-105px, 105px);
}
@media only screen and (max-width: 1319px) {
  .another-games__img-block .image-crop__img {
    transform: rotate(45deg) translate(-8.5vw, -8.5vw);
  }
  .another-games__img-block .image-crop__img img {
    transform: rotate(-45deg) translate(-8.5vw, 8.5vw);
  }
}
@media only screen and (max-width: 1023px) {
  .another-games__img-block .image-crop__img {
    transform: rotate(0deg);
  }
  .another-games__img-block .image-crop__img img {
    transform: rotate(0deg);
    clip-path: polygon(15% 0, 100% 0, 100% 80%, 85% 100%, 0 100%, 0 20%);
  }
}
@media only screen and (max-width: 899px) {
  .another-games__info {
    padding: 10px;
  }
}
@media only screen and (max-width: 899px) {
  .another-games__icons li {
    margin: 0px 12px 0px 0px;
  }
  .another-games__icons li .icon-platform {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 599px) {
  .another-games__icons li {
    margin: 0px 7px 0px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .another-games__btn {
    padding: 10px 0px;
  }
  .another-games__btn .btn-inner {
    font-size: 14px;
  }
}

.policy {
  padding: 48px 0px 80px 0px;
}
@media only screen and (max-width: 1599px) {
  .policy {
    padding: 60px 0px 80px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .policy {
    padding: 48px 0px 64px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .policy {
    padding: 23px 0px 0px 0px;
  }
}
.policy__icon {
  display: none;
  opacity: 0.4;
}
.policy__icon.active {
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .policy__icon {
    display: inline-flex;
  }
}
.policy__nav {
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: 920px;
  padding: 0px 0px 0px 20px;
  margin: 0px auto 16px auto;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .policy__nav {
    padding: 0 0 0px 0px;
    margin: 0px 0px 10px 0px;
  }
}
.policy__nav li {
  margin: 0px 40px 0px 0px;
}
.policy__nav li:last-child {
  margin: 0px 0px 0px 0px;
}
@media only screen and (max-width: 767px) {
  .policy__nav li {
    margin: 0px 15px 0px 0px;
  }
}
.policy__link {
  color: rgba(255, 255, 255, 0.7);
}
.policy__headerlink {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
}
@media only screen and (max-width: 767px) {
  .policy__headerlink {
    display: none;
  }
}
.policy__headerlink:after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #ff1812;
  position: absolute;
  top: -10px;
  box-shadow: 0px 38px 80px rgba(255, 59, 0, 0.24), 0px 15.8755px 33.4221px rgba(255, 59, 0, 0.172525), 0px 8.4878px 17.869px rgba(255, 59, 0, 0.143066), 0px 4.75819px 10.0172px rgba(255, 59, 0, 0.12), 0px 2.52704px 5.32008px rgba(255, 59, 0, 0.0969343), 0px 1.05156px 2.21381px rgba(255, 59, 0, 0.0674749);
  display: none;
}
.policy__headerlink:after:hover {
  filter: none;
}
.policy__headerlink:after:active {
  filter: none;
}
.policy__headerlink.active:after {
  content: "";
  display: block;
}
.policy__info {
  max-width: 920px;
  margin: 0 auto;
  position: relative;
  padding: 7px;
}
.policy__info p, .policy__info li {
  color: rgba(255, 255, 255, 0.5);
}
@media only screen and (max-width: 767px) {
  .policy__info {
    max-width: none;
    padding: 0;
  }
}
.policy__top {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 22px 36px;
}
@media only screen and (max-width: 767px) {
  .policy__top {
    background-color: transparent;
    padding: 0px;
    margin: 0px 0px 10px 0px;
  }
}
.policy__title {
  max-width: 447px;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .policy__title {
    max-width: none;
  }
}
.policy__content {
  padding: 16px 36px 53px 32px;
}
@media only screen and (max-width: 767px) {
  .policy__content {
    padding: 0 0 14px 0px;
  }
}
.policy__frame {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@media only screen and (max-width: 767px) {
  .policy__frame {
    display: none;
  }
}

.contacts {
  padding: 80px 0px 90px 0px;
}
@media only screen and (max-width: 1023px) {
  .contacts {
    padding: 60px 0px 80px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .contacts {
    padding: 25px 0px 40px 0px;
  }
}
.contacts__lang {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  margin: 0px 0px 20px 0px;
}
.contacts__lang li {
  margin: 0px 15px 0px 0px;
}
.contacts__lang li:last-child {
  margin: 0px 0px 0px 0px;
}
@media only screen and (max-width: 767px) {
  .contacts__lang {
    display: flex;
  }
}
.contacts__icon {
  opacity: 0.4;
}
.contacts__icon.active {
  opacity: 1;
}
.contacts__title {
  margin: 0px 0px 24px 0px;
}
@media only screen and (max-width: 767px) {
  .contacts__title {
    margin: 0px 0px 16px 0px;
  }
}
.contacts__form {
  max-width: 680px;
  margin: 0px 0px 120px 0px;
}
@media only screen and (max-width: 1023px) {
  .contacts__form {
    max-width: none;
  }
}
.contacts__offices {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -80px;
}
@media only screen and (max-width: 1599px) {
  .contacts__offices {
    margin: 0 -20px;
  }
}
@media only screen and (max-width: 1023px) {
  .contacts__offices {
    display: block;
  }
}
.contacts__office {
  flex: 0 1 33.333%;
  padding: 0 80px;
}
@media only screen and (max-width: 1599px) {
  .contacts__office {
    padding: 0 20px;
  }
}
.contacts__btn {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px 17px 8px 20px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0px 0px 32px 0px;
  user-select: none;
}
.contacts__btn span {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  padding: 0px 18px 0px 0px;
}
.contacts__btn svg {
  margin: 2px 0px 0px 0px;
  transform: rotate(90deg);
  transition: all 0.3s ease;
}
.contacts__btn.active svg {
  transform: rotate(-90deg);
}
.contacts__other-offices {
  display: none;
}
.contacts__other-offices-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 0 -80px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1023px) {
  .contacts__other-offices-list {
    display: block;
  }
}
.contacts__other-office {
  flex: 0 1 33.333%;
  padding: 0 80px;
  margin: 0px 0px 20px 0px;
}

.form-contacts__select, .form-contacts__input, .form-contacts__textarea {
  margin: 0px 0px 36px 0px;
}
@media only screen and (max-width: 767px) {
  .form-contacts__select, .form-contacts__input, .form-contacts__textarea {
    margin: 0px 0px 28px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .form-contacts__textarea {
    margin: 0px 0px 15px 0px;
  }
}
.office-block {
  margin: 0px 0px 40px 0px;
}
@media only screen and (max-width: 767px) {
  .office-block {
    margin: 0px 0px 60px 0px;
  }
}
.office-block p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
}
.office-block span {
  display: block;
}
.office-block span:last-child {
  color: #ffffff;
  font-weight: 600;
}
.office-block__title {
  margin: 0px 0px 8px 0px;
  white-space: nowrap;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
}
@media only screen and (max-width: 1599px) {
  .office-block__title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .office-block__title {
    font-size: 20px;
    line-height: 28px;
  }
}
.office-block__address {
  margin: 0px 0px 24px 0px;
  max-width: 320px;
}
@media only screen and (max-width: 1599px) {
  .office-block__address {
    max-width: 287px;
  }
}
@media only screen and (max-width: 1023px) {
  .office-block__address {
    max-width: none;
  }
}
.office-block__support, .office-block__proposal, .office-block__resume {
  margin: 0px 0px 12px 0px;
}
@media only screen and (max-width: 1023px) {
  .office-block__support, .office-block__proposal, .office-block__resume {
    display: flex;
  }
  .office-block__support span, .office-block__proposal span, .office-block__resume span {
    flex: 0 1 34%;
  }
}
@media only screen and (max-width: 767px) {
  .office-block__support, .office-block__proposal, .office-block__resume {
    margin: 0px 0px 20px 0px;
    display: block;
  }
}
.office-block__support a, .office-block__proposal a, .office-block__resume a {
  color: inherit;
  text-decoration: none;
}
.office-block__support a:hover, .office-block__proposal a:hover, .office-block__resume a:hover {
  opacity: 0.75;
}
@media only screen and (max-width: 1023px) {
  .office-block-other {
    margin: 0px 0px 40px 0px;
  }
  .office-block-other:last-child {
    margin: 0px 0px 0px 0px;
  }
}
.office-block-other__location {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0px 0px 8px 0px;
}
.office-block-other__address {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.error {
  padding: 174px 0px 259px 0px;
}
@media only screen and (max-width: 1599px) {
  .error {
    padding: 80px 0px 115px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .error {
    padding: 60px 0px 85px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .error {
    padding: 24px 0px 20px 0px;
  }
}
.error__container {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .error__container {
    display: block;
  }
}
.error__info {
  flex: 1 1 auto;
  padding: 0px 168px 0px 0px;
}
@media only screen and (max-width: 1599px) {
  .error__info {
    padding: 0px 25px 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .error__info {
    padding: 0px 37px 0px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .error__info {
    padding: 0px 0px 0px 0px;
  }
}
.error__title {
  font-weight: 600;
  font-size: 80px;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0px 0px 42px 0px;
}
@media only screen and (max-width: 1599px) {
  .error__title {
    margin: 0px 0px 32px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .error__title {
    margin: 0px 0px 16px 0px;
  }
}
.error__text {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0px 0px 83px 0px;
  width: 391px;
}
@media only screen and (max-width: 1599px) {
  .error__text {
    margin: 0px 0px 91px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .error__text {
    width: 259px;
  }
}
@media only screen and (max-width: 767px) {
  .error__text {
    margin: 0px 0px 13px 0px;
  }
}
.error__btn {
  display: inline-flex;
}
.error__btn .btn-arrow-red {
  justify-content: flex-start;
}
.error__number {
  flex: 0 1 1016px;
  display: inline-flex;
  align-items: center;
  margin: -53px -178px -215px 0px;
}
.error__number img {
  width: 100%;
}
@media only screen and (max-width: 1599px) {
  .error__number {
    min-width: 465px;
    margin: 0px calc(-50vw + 50%) 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .error__number {
    min-width: 365px;
  }
}
@media only screen and (max-width: 767px) {
  .error__number {
    min-width: 350px;
    flex: 1 1 100%;
    margin: 0px 0px 84px 0px;
  }
}

.footer {
  padding: 40px 0px;
  border-top: 2.4px solid rgba(255, 255, 255, 0.1);
}
@media only screen and (max-width: 1023px) {
  .footer {
    padding: 25px 0px 28px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .footer {
    padding: 24px 0px 29px 0px;
  }
}
.footer__container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1023px) {
  .footer__container {
    flex-direction: column;
  }
}
.footer__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 1023px) {
  .footer__info {
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 0px 24px 0px;
  }
}
.footer__info nav {
  padding: 0px 0px 23px 0px;
}
@media only screen and (max-width: 1599px) {
  .footer__info nav {
    padding: 0;
  }
}
.footer__info, .footer__copyright {
  flex: 0 1 50%;
}
@media only screen and (max-width: 1599px) {
  .footer__info, .footer__copyright {
    flex: 0 1 33.333%;
  }
}
.footer__logo {
  flex: 0 0 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1599px) {
  .footer__logo {
    justify-content: space-between;
    flex: 0 1 33.333%;
    padding: 0px 0px 0px 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .footer__logo {
    padding: 16px 0px 0px 0px;
    position: relative;
  }
  .footer__logo:before {
    content: "";
    width: 90%;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 0;
    left: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .footer__logo {
    padding: 20px 0px 0px 0px;
  }
}
.footer__logo-img {
  margin: 0px 0 5px 18px;
}
@media only screen and (max-width: 1023px) {
  .footer__logo-img {
    margin: 0px 0px 7.6px 0px;
  }
  .footer__logo-img svg {
    width: 98px;
    height: 52.5px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__logo-img {
    margin: 38px 0px 0px 0px;
  }
}
.footer__logo-img_pc {
  margin: 0px 0px 25px 0px;
}
@media only screen and (max-width: 767px) {
  .footer__logo-img_pc {
    margin: 38px 0px 20px 0px;
  }
}
.footer__copyright {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media only screen and (max-width: 1599px) {
  .footer__copyright {
    padding: 5px 0px 0px 0px;
  }
}
.footer__social {
  margin: 0px 0px 41px 0px;
}
@media only screen and (max-width: 1599px) {
  .footer__social {
    margin: 0px;
  }
}
.footer__icons-social_pc li {
  margin: 0px 11px 0px 0px;
}
.footer__icons-social li {
  margin: 0px 20px 0px 0px;
}
.footer__icons-social li:last-child {
  margin: 0px 0px 0px 0px;
}
@media only screen and (max-width: 1599px) {
  .footer__icons-social li {
    margin: 0px 20px 0px 0px;
  }
}
.footer__icons-social, .footer__icons-platforms {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.footer__icons-platforms {
  justify-content: flex-end;
  padding: 20px 0px 0px 0px;
}
.footer__icons-platforms li {
  margin: 0px 16px 0px 0px;
}
.footer__icons-platforms li:last-child {
  margin: 0px 0px 0px 0px;
}
.footer__icons-platforms li:last-child .footer__icon-social,
.footer__icons-platforms li:last-child .footer__icon-platforms {
  margin: 0px 0px 0px 0px;
}
@media only screen and (max-width: 1919px) {
  .footer__icons-platforms {
    padding: 0;
    margin: 0px 0px 32px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__icons-platforms {
    margin: 0px 0px 20px 0px;
  }
}
.footer__icon-social {
  display: block;
}
.footer__icon-platforms {
  display: block;
}
.footer__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.footer__list li {
  max-width: 140px;
  position: relative;
  padding: 0px 22px;
}
.footer__list li:after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  height: 16px;
  width: 1px;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
}
.footer__list li:first-child {
  padding: 0px 26px 0px 0px;
}
.footer__list li:last-child {
  padding: 0px 0px 0px 35px;
}
.footer__list li:last-child:after {
  width: 0;
  height: 0;
}
@media only screen and (max-width: 1599px) {
  .footer__list {
    flex-direction: column;
  }
  .footer__list li {
    padding: 0px;
    max-width: none;
    margin: 0px 0px 16px 0px;
  }
  .footer__list li:after {
    content: "";
    display: none;
  }
  .footer__list li:first-child {
    padding: 0px;
  }
  .footer__list li:last-child {
    padding: 0px;
    margin: 0px 0px 0px 0px;
  }
}
.footer__icons-social.social-tg img.icon-tg {
  fill-opacity: 1;
}
.footer__link {
  display: block;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: ls;
  color: #ffffff;
  opacity: 0.7;
  text-decoration: none;
  user-select: none;
}
.footer__copyright-text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #ffffff;
  opacity: 0.7;
  margin: 0;
  text-align: center;
  padding: 40px 0px 0px 0px;
  user-select: none;
}
@media only screen and (max-width: 1919px) {
  .footer__copyright-text {
    padding: 0px 0px 0px 0px;
  }
}

.social__label {
  font-size: 12px;
  line-height: 1.3333333333;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0px 0px 8px 0px;
  user-select: none;
}
@media only screen and (max-width: 1599px) {
  .social__label {
    text-align: right;
  }
}
@media only screen and (max-width: 767px) {
  .social__label {
    text-align: center;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
}
@media only screen and (max-width: 767px) {
  .modal {
    padding: 0;
  }
}
.modal--open {
  display: flex;
}

.modal__container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 760px;
  max-width: 100%;
  max-height: 100%;
  padding: 36px 0 32px 0;
  background: #161825;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width: 767px) {
  .modal__container {
    padding: 15px 0 24px;
  }
}

.modal__close {
  position: absolute;
  right: 16px;
  top: 12px;
  z-index: 10;
  width: 30px;
  height: 30px;
  color: #ffffff;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .modal__close {
    position: relative;
    top: 0;
    right: 0;
    flex-shrink: 0;
    align-self: flex-start;
  }
}
.modal__close:after, .modal__close:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 2px;
  color: inherit;
  background-color: currentColor;
}
.modal__close:after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal__close:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.modal__close:hover {
  opacity: 0.8;
}
.modal__close:focus {
  outline: none;
}

.modal__header {
  margin: 0 0 14px;
  padding: 0 44px 0 36px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .modal__header {
    padding: 0 16px;
  }
}
@media only screen and (max-width: 767px) {
  .modal__header {
    margin: 0 0 37px;
  }
}

.modal__title {
  margin: 0;
  max-width: calc(100% - 40px);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  user-select: none;
}
.modal__title--small {
  font-size: 20px;
  line-height: 24px;
}
@media only screen and (max-width: 767px) {
  .modal__title--small {
    font-size: 18px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .modal__title {
    font-size: 20px;
    line-height: 24px;
  }
}

.modal__content {
  width: 100%;
  min-height: 30px;
  padding: 10px 44px 0 36px;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
  .modal__content {
    padding: 0 16px;
  }
}
.modal__content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.modal-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 120;
  display: block;
  width: 100%;
  background-color: #1D2031;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  max-height: 80%;
  overflow-y: auto;
}
.modal-alert--error .modal-alert__icon, .modal-alert--success .modal-alert__icon {
  display: block;
}
.modal-alert--success .modal-alert__icon {
  background-color: #40CD5F;
}
.modal-alert--success .modal-alert__icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 9px;
  border-right: 2px solid #1D2031;
  border-bottom: 2px solid #1D2031;
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal-alert--error .modal-alert__icon {
  background-color: #FF1812;
}
.modal-alert--error .modal-alert__icon::after, .modal-alert--error .modal-alert__icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  background-color: #1D2031;
}
.modal-alert--error .modal-alert__icon::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal-alert--error .modal-alert__icon::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal-alert__container {
  padding-top: 23px;
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1599px) {
  .modal-alert__container {
    max-width: calc(100% - 100px);
  }
}
@media only screen and (max-width: 767px) {
  .modal-alert__container {
    padding: 16px 16px 24px;
    max-width: none;
  }
}

.modal-alert__close {
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 10;
  width: 30px;
  height: 30px;
  color: #ffffff;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .modal-alert__close {
    top: 16px;
    right: 16px;
  }
}
.modal-alert__close:after, .modal-alert__close:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 2px;
  color: inherit;
  background-color: currentColor;
}
.modal-alert__close:after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal-alert__close:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.modal-alert__close:hover {
  opacity: 0.8;
}
.modal-alert__close:focus {
  outline: none;
}

.modal-alert__content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .modal-alert__content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.modal-alert__text {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.modal-alert__icon {
  display: none;
  position: relative;
  width: 32px;
  height: 32px;
  align-self: flex-start;
  flex-shrink: 0;
  margin-right: 16px;
  border-radius: 50%;
}
@media only screen and (max-width: 767px) {
  .modal-alert__icon {
    margin-right: 0;
    margin-bottom: 7px;
  }
}