.contacts-join {
	padding: 100px 0px 177px;
	background-color: $color-grey;
	@include mediaMax($md) {
		padding: 20px 0px 72px 0px;
		background-color: $color-grey-dark;
	}
	&__container {
		max-width: 920px;
		@include mediaMax($lg) {
			max-width: none;
		}
	}

	&__body {
		@include mediaMax($lg) {
			display: flex;
		}
		@include mediaMax($md) {
			flex-direction: column;
		}
	}

	&__title {
		margin: 0px 0px 24px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 7px 0px;
		}
	}

	&__persons {
		@include list-reset();
		display: flex;
		margin: 0px -24px 43px -24px;
		@include mediaMax($lg) {
			flex-direction: column;
			margin: 0px 0px 0px 0px;
			padding-right: 30px;
		}
		@include mediaMax($md) {
			display: block;
			margin: 0px 0px 40px 0px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 60px 0px;
		}
	}

	&__person {
		width: 50%;
		margin: 0px 24px;
		@include mediaMax($lg) {
			margin: 0px 0px 25px 0px;
			&:last-child {
				margin: 0px 0px 0px 0px;
			}
		}
	}

	&__links {
		@include mediaMax($lg) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		@include mediaMax($md) {
			margin: 0px 0px 0px 0px;
			display: flex;
		}
		@include mediaMax($sm) {
			display: block;
		}
	}

	&__soc_link {
		display: block;
		margin: 0 0 20px;
		.btn-icon {
			margin-top: 3px;
		}
		// .btn {
		// 	padding: 0px 0px 0px 58px;
		// }
		.btn-inner {
			line-height: 40px;
			@include mediaMax($sm) {
				font-size: 20px;
			}
		}
	}
	&__links:first-child {
		position: relative;
		margin: 0px 0px 20px 0px;

		@include mediaMax($md) {
			margin: 0px 83px 0px 0px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 15px 0px;
		}
	}

	&__links:last-child {
		position: relative;
	}

	&__logo {
		display: block;
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}
}

.person {
	&__wrapper {
		min-width: 444px;
		flex-basis: 444px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		padding: 17px 15px 24px 16px;
		position: relative;
		@include mediaMax($md) {
			display: inline-flex;
		}
		@include mediaMax($sm) {
			padding: 13px 13px 13px 9px;
			min-width: 288px;
			margin: 0px 0px 12px 0px;
		}
	}
	&__image {
		width: 120px;
		height: 120px;
		border-radius: 50%;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}
		@include mediaMax($sm) {
			width: 69px;
			height: 69px;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		margin: 0px 0px 0px 22px;
		justify-content: center;
	}

	&__name {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-white;
		margin: 0px 0px 8px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 4px 0px;
		}
	}

	&__proffesion {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-grey-6;
		margin: 0px 0px 14px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 0px 0px;
		}
	}

	&__contact {
		@include mediaMax($sm) {
			display: flex;
		}
		a {
			display: block;
			position: relative;
			z-index: 2;
			user-select: none;
		}
	}

	&__frame {
		top: 0;
		left: 0;
	}
}
.person-contact {
	&__email {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-grey-6;
		margin: 0px 0px 4px 0px;
		padding: 0px 0px 0px 23px;
		background: url("./../img/icons/email.svg") center left no-repeat;
		@include mediaMax($sm) {
			font-size: 12px;
			line-height: 16px;
			margin: 0px 25px 0px 0px;
		}
	}

	&__telegram {
		margin: 0;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-grey-6;
		padding: 0px 0px 0px 23px;
		background: url("./../img/icons/telegram-mini.svg") center left no-repeat;
		@include mediaMax($sm) {
			font-size: 12px;
			line-height: 16px;
		}
	}
}
