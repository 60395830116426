.policy {
	padding: 48px 0px 80px 0px;
	@include mediaMax($lg) {
		padding: 60px 0px 80px 0px;
	}
	@include mediaMax($md) {
		padding: 48px 0px 64px 0px;
	}
	@include mediaMax($sm) {
		padding: 23px 0px 0px 0px;
	}
	&__container {
	}

	&__icon {
		display: none;
		opacity: 0.4;
		&.active {
			opacity: 1;
		}
		@include mediaMax($sm) {
			display: inline-flex;
		}
	}

	&__nav {
		@include list-reset();
		max-width: 920px;
		padding: 0px 0px 0px 20px;
		margin: 0px auto 16px auto;
		display: flex;
		@include mediaMax($sm) {
			padding: 0 0 0px 0px;
			margin: 0px 0px 10px 0px;
		}
		li {
			margin: 0px 40px 0px 0px;
			&:last-child {
				margin: 0px 0px 0px 0px;
			}
			@include mediaMax($sm) {
				margin: 0px 15px 0px 0px;
			}
		}
	}

	&__link {
		color: $color-grey-6;
	}

	&__headerlink {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-grey-6;
		position: relative;
		@include mediaMax($sm) {
			display: none;
		}
		&:after {
			content: "";
			width: 100%;
			height: 4px;
			background-color: $color-red-1;
			position: absolute;
			top: -10px;
			@include box-shadow-red();
			display: none;
		}
		&.active {
			&:after {
				content: "";
				display: block;
			}
		}
	}

	&__info {
		max-width: 920px;
		margin: 0 auto;
		position: relative;
		padding: 7px;
		p, li {
			color: $color-grey-3;
		}
		@include mediaMax($sm) {
			max-width: none;
			padding: 0;
		}
	}

	&__top {
		background-color: $color-grey-9;
		padding: 22px 36px;
		@include mediaMax($sm) {
			background-color: transparent;
			padding: 0px;
			margin: 0px 0px 10px 0px;
		}
	}

	&__title {
		max-width: 447px;
		margin: 0;
		@include mediaMax($sm) {
			max-width: none;
		}
	}

	&__content {
		padding: 16px 36px 53px 32px;
		@include mediaMax($sm) {
			padding: 0 0 14px 0px;
		}
	}

	&__frame {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		@include mediaMax($sm) {
			display: none;
		}
	}
}
