.games-release {
	padding: 100px 0px 116px 0px;
	@include mediaMax($lg) {
		padding: 60px 0px 62px 0px;
	}
	@include mediaMax($md) {
		padding: 40px 0px 29px 0px;
	}
	&__container {
	}

	&__title {
		margin: 0px 0px 60px 0px;
		@include mediaMax($lg) {
			margin: 0px 0px 27px 0px;
		}
		@include mediaMax($md) {
			margin: 0px 0px 37px 0px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 24px 0px;
		}
	}

	&__slider {
		overflow: hidden;
	}

	&__slide {
		flex-shrink: 0;
		margin: 0px 0px 5px 0px;
		@include mediaMax($md) {
			height: 252px;
			width: 288px !important;
		}
		@include mediaMax($sm) {
			height: 156px;
			width: 181px !important;
			&:first-child {
				.image-crop__img {
					clip-path: polygon(24% 0, 100% 0, 100% 100%, 0 100%, 0 28%);
				}
			}
		}
	}

	&__body {
		@include list-reset();
		margin: 0 0 35px;
		display: flex;
		@include mediaMax($sm) {
			margin: 0 0 0px;
		}
		&-main {
			.games-slider__slide {
				width: 439px;
				height: 320px;
				margin-right: 40px;

				&.swiper-slide:not(.swiper-slide-visible) {
					filter: grayscale(0.9);

					@include mediaMax($lg) {
						filter: none;
					}
				}

				@include mediaMax($lg) {
					width: calc((100vw - 160px) / 3);
					height: 252px;
					max-height: none;
				}

				@include mediaMax($md) {
					width: 293px;
					height: 252px;
					margin-right: 32px;
				}

				@include mediaMax($sm) {
					width: 181px;
					height: 156px;
					margin-right: 12px;
				}
			}

			.games-release__image-crop-wrapper {
				height: 100%;

				@include mediaMax($lg) {
					max-height: none;
				}
			}

			.games-slider__image-crop {
				margin: 0;
				width: 100%;
			}

			.image-crop__content {
				width: calc(100% - 16px);
				height: calc(100% - 16px);
			}

			.games-release__image-crop-wrapper .image-crop__img {
				transform: none;
				height: 100%;
				position: relative;

				&::after,
				&::before {
					content: "";
					position: absolute;
					width: 90px;
					height: 90px;

					background-color: $color-grey-dark;

					@include mediaMax($lg) {
						width: 60px;
						height: 60px;
					}
				}

				&::after {
					top: 0;
					left: 0;
					transform: translate(-50%, -50%) rotate(45deg);
				}

				&::before {
					bottom: 0;
					right: 0;
					transform: translate(50%, 50%) rotate(45deg);

					@include mediaMax($sm) {
						display: none;
					}
				}
			}

			.games-release__image-crop-wrapper .image-crop__img img {
				transform: none;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__info {
		@include mediaMax($sm) {
			padding: 10px;
		}
	}

	&__image-title {
		@include mediaMax($sm) {
			font-size: 16px;
			line-height: 20px;
		}
	}

	&__image-crop-wrapper {
		height: 230px;
		.image-crop__img {
			transform: rotate(45deg) translate(-80px, -80px);
			img {
				transform: rotate(-45deg) translate(-82px, 80px);
			}
		}
		@include mediaMax($lg) {
			height: 19.5vw;
			max-height: 230px;
			.image-crop__img {
				transform: rotate(45deg) translate(-80px, -60px);
				img {
					transform: rotate(-45deg) translate(-67px, 67px);
				}
			}
		}
		@include mediaMax(1200px) {
			.image-crop__info {
				padding: 24px 16px 16px;
			}
			.image-crop__img {
				transform: rotate(45deg) translate(-7vw, -8vw);
				img {
					transform: rotate(-45deg) translate(-7.8vw, 7.88vw);
				}
			}
		}
		@include mediaMax($md) {
			height: 100%;
			width: 100%;
			max-height: none;
			.image-crop__img {
				transform: rotate(45deg) translate(-88px, -60px);
				img {
					transform: rotate(-45deg) translate(-71px, 69px);
				}
			}
			.image-crop__info {
				padding: 24px 24px 16px;
			}
		}
		> .image-crop__img {
			top: -16px;
			left: 0px;
			img {
				width: 100%;
			}
		}
		@include mediaMax($sm) {
			.image-crop__img {
				transform: none;
				height: 100%;
				img {
					height: 100%;
					transform: none;
				}
			}
			.image-crop__info {
				padding: 10px;
			}
			.icon-app,
			.icon-gp,
			.icon-steam,
			.icon-p1,
			.icon-xbox,
			.icon-playstation {
				svg {
					display: block;
					width: 19px;
					height: 19px;
				}
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	&__icons {
		@include mediaMax(1200px) {
			li {
				margin: 0px 8px 0px 0px;
				.icon-platform {
					width: 22px;
					height: 22px;
				}
			}
		}
		@include mediaMax($md) {
			li {
				margin: 0px 12px 0px 0px;
				.icon-platform {
					width: 24px;
					height: 24px;
				}
			}
		}
		@include mediaMax($sm) {
			li {
				margin: 0px 7px 0px 0px;
				.icon-platform {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
	&__btn {
		@include mediaMax($sm) {
			padding: 10px 0px;
			.btn-inner {
				font-size: 14px;
			}
		}
	}
	&__pagination {
		@include mediaMax($md) {
			display: none;
		}
	}

	&__arrow {
		&_left {
		}

		&_right {
		}
	}

	&__dots {
	}
}
