.offer {
	background-color: $color-grey;
	padding: 100px 0px 55px 0px;
	@include mediaMax($lg) {
		padding: 80px 0px 97px 0px;
		background-color: $color-grey-dark;
	}
	@include mediaMax($md) {
		padding: 40px 0px;
	}
	@include mediaMax($sm) {
		padding: 40px 0px 19px 0px;
	}
	&__container {
	}

	&__title {
		margin: 0px 0px 80px 0px;
		max-width: 540px;
		@include mediaMax($lg) {
			margin: 0px 0px 72px 0px;
			max-width: 520px;
		}
		@include mediaMax($md) {
			margin: 0px 0px 82px 0px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 40px 0px;
		}
	}

	&__content {
	}

	&__items {
		@include list-reset();
		display: flex;
		flex-wrap: wrap;
		margin: 0px -20px;
		@include mediaMax($md) {
			margin: 0px -12px;
		}
	}

	&__item {
		width: 33.333%;
		padding: 0px 20px;
		margin: 0px 0px 94px 0px;
		@include mediaMax($lg) {
			margin: 0px 0px 37px 0px;
			flex: 0 1 50%;
		}
		@include mediaMax($md) {
			margin: 0px 0px 60px 0px;
			padding: 0px 12px;
		}
		@include mediaMax($sm) {
			flex: 1 1 100%;
			margin: 0px 0px 35px 0px;
		}
	}
}
.item-offer {
	display: flex;
	@include mediaMax($md) {
		flex-direction: column;
		align-items: center;
	}
	&__img {
		min-width: 100px;
		display: flex;
		align-items: flex-start;
		user-select: none;
		img {
			width: 100%;
			object-fit: contain;
			object-position: center;
			font-family: "object-fit: contain; object-position: center;";
		}
		@include mediaMax($md) {
			display: block;
			margin: 0px 0px 12px 0px;
		}
	}

	&__info {
		flex: 1 1 auto;
		padding: 0px 0px 0px 20px;
		@include mediaMax($md) {
			padding: 0;
			flex: none;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-white;
		margin: 0px 0px 8px 0px;
		@include mediaMax($md) {
			text-align: center;
		}
	}

	&__text {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-grey-6;
		margin: 0;
		@include mediaMax($md) {
			text-align: center;
		}
		@include mediaMax($sm) {
			font-size: 12px;
			line-height: 16px;
		}
	}
}
