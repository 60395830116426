.apply {
	padding: 120px 0px 128px 0px;
	background-color: $color-grey-dark;
	@include mediaMax($lg) {
		padding: 0px 0px 40px 0px;
	}
	@include mediaMax($md) {
		padding: 0px 0px 0px 0px;
	}
	&__container {
		position: relative;
		@include mediaMax($lg) {
			max-width: 911px;
		}
	}
	&__content {
		display: flex;
		padding: 73px 122px 102px 0px;
		@include mediaMax($lg) {
			flex-direction: column;
			padding: 60px 48px 87px;
		}
		@include mediaMax($md) {
			padding: 40px 0px 107px 0px;
		}
		@include mediaMax($sm) {
			padding: 32px 0px 105px 0px;
		}
	}

	&__info {
		flex: 1 1 auto;
		padding: 0px 155px 0px 0px;

		@include mediaMax($lg) {
			padding: 0px 0px 0px 0px;
			max-width: 460px;
		}
		@include mediaMax($sm) {
			max-width: none;
		}
	}

	&__title {
		margin: 0px 0px 20px 0px;
		@include mediaMax($md) {
			margin: 0px 0px 16px 0px;
		}
	}

	&__text {

	}

	&__form {
		flex: 0 0 680px;
		@include mediaMax($lg) {
			padding: 0px;
			flex: 1 1 auto;
		}
	}

	&__frame {
		top: -16px;
		left: -46px;
		width: 108%;
		user-select: none;
		@include mediaMax($lg) {
			display: none;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		height: 100%;
		left: 0;
		width: 100%;
		background-size: cover;
		@include mediaMin($lg) {
			width: 155%;
			left: -30px;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 64.14%, rgba(0, 0, 0, 0.8) 100%);
				transform: rotate(180deg);
			}
		}
	}
}

.form {
	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin: 0px 0px 40px 0px;

		@include mediaMax($sm) {
			margin: 0px 0px 0px 0px;
			&:nth-child(2) {
				margin: 0px 0px 20px 0px;
			}
			.space-m {
				margin: 0px 0px 12px 0px;
			}
		}
	}

	&__col {
		flex: 0 1 45.5%;
		@include mediaMax($sm) {
			flex: 1 1 100%;
			margin: 0px 0px 20px 0px;
		}
	}

	&__text {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: #e7e7e9;
		user-select: none;
		@include mediaMax($sm) {
			margin: 0px 0px 12px 0px;
		}
	}

	&__bottom {
		position: relative;
	}

	&__agreement-container {
		display: flex;
		align-items: center;
		@include mediaMax($sm) {
			margin: 17px 0px 0px 0px;
		}
	}

	&__agreement {
		font-size: 12px;
		line-height: 16px;
		letter-spacing: $ls;
		color: $color-grey-6;
		padding: 0px 0px 0px 12px;
		max-width: 390px;
		user-select: none;
		@include mediaMax(1740px) {
			max-width: 381px;
		}
		@include mediaMax($lg) {
			max-width: 390px;
		}
		@include mediaMax($sm) {
			font-size: 10px;
			line-height: 14px;
		}
	}

	&__link {
		color: $color-white;
	}

	&__btn {
		position: absolute;
		top: 0;
		left: calc(100% - 200px);
		background: none;
		border: none;
		padding: 0;
		margin: 0;
		cursor: pointer;
		width: 208px;
		text-align: left;
		overflow: visible;
		.btn-arrow-red {
			width: 86%;
		}
		&:hover {
			width: 220px;
		}
		&:focus {
			outline: none;
		}
		@include mediaMax($sm) {
			top: 53px;
			left: 0;
			width: 284px;
			.btn-arrow-red {
				width: 89.5%;
			}
			.btn-arrow_bg {
				box-shadow: none;
			}
			&:hover {
				width: 286px;
				.btn-arrow-red {
					width: 93%;
				}
			}
		}
	}
}
