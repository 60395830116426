.our-games {
	padding: 80px 0px 89px 0px;
	@include mediaMax($lg) {
		padding: 24px 0px 61px 0px;
	}
	@include mediaMax($sm) {
		padding: 24px 0px 51px 0px;
	}

	&__container {
	}

	&__top {
		padding: 0px 0px 32px 0px;
		border-bottom: 1px solid $color-grey-2;
		@include mediaMax($lg) {
			padding: 0px 0px 20px 0px;
			border-bottom: none;
		}
		@include mediaMax($md) {
			padding: 0px 0px 34px 0px;
		}
		@include mediaMax($sm) {
			padding: 0px 0px 10px 0px;
		}
	}

	&__filter-toggle {
		display: none;
		cursor: pointer;
		border-bottom: 1px solid $color-grey-14;
		padding: 0px 0px 4px 0px;
		position: relative;
		@include selectNone();
		span {
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: $ls;
			color: $color-white;
		}
		&:after {
			content: "";
			width: 14px;
			height: 8px;
			position: absolute;
			top: 50%;
			right: 2px;
			transform: translate(0%, -50%) rotate(-180deg);
			background: url("./../img/sprite/arrow-red-sm.svg") 0 0 no-repeat;
		}
		&.open {
			&:after {
				transform: translate(0%, -50%);
			}
		}
		@include mediaMax($sm) {
			display: block;
		}
	}

	&__filter {
		> ul {
			@include list-reset();
			display: flex;
			justify-content: space-between;
			@include mediaMax($lg) {
				justify-content: flex-start;
			}
			@include mediaMax($md) {
				flex-wrap: wrap;
			}
			@include mediaMax($sm) {
				padding: 16px 0px 22px 0px;
			}
			> li {
				position: relative;
				&:after {
					content: "";
					width: 1.5px;
					height: 60px;
					background-color: $color-grey-2;
					position: absolute;
					top: 10px;
					right: 0;
					@include mediaMax($lg) {
						display: none;
					}
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
			}
		}
		@include mediaMax($sm) {
			display: none;
			&.open {
				display: block;
			}
		}
	}

	&__col-1 {
		flex: 0 0 55%;
		@include mediaMax($lg) {
			flex: 0 0 70%;
		}
		@include mediaMax($md) {
			flex: 1 1 100%;
			margin: 0px 0px 31px 0px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 20px 0px;
		}
	}

	&__col-2 {
		flex: 0 0 20%;
		display: flex;
		@include mediaMax($lg) {
			order: 3;
			flex: 0 0 15%;
		}
		@include mediaMax($md) {
			flex: 0 0 40%;
		}
		@include mediaMax($sm) {
			flex: 1 1 100%;
		}
	}

	&__col-3 {
		flex: 0 0 25%;
		display: flex;
		justify-content: center;
		@include mediaMax($lg) {
			order: 2;
			flex: 0 0 15%;
		}
		@include mediaMax($md) {
			flex: 0 0 48%;
		}
		@include mediaMax($sm) {
			flex: 1 1 100%;
			margin: 0px 0px 20px 0px;
		}
	}

	&__body {
		padding: 40px 0px 0px 0px;
		@include mediaMax($lg) {
			padding: 20px 0px 0px 0px;
		}
		@include mediaMax($md) {
			padding: 34px 0px 0px 0px;
		}
		@include mediaMax($sm) {
			padding: 0px 0px 0px 0px;
		}
	}
}

.filter-games {
	&__genre {
		padding: 0px 75px 0px 0px;
		@include mediaMax($lg) {
			padding: 0px 20px 0px 0px;
		}
		@include mediaMax($md) {
			padding: 0px 0px 0px 0px;
		}
	}
	&__role {
		padding: 0px 30px;
		@include mediaMax($lg) {
			padding: 0px 15px;
		}
		@include mediaMax($md) {
			padding: 0px 0px 0px 0px;
		}
	}

	&__platform {
		padding: 0px 0px 0px 30px;
		@include mediaMax($lg) {
			padding: 0px 0px 0px 0px;
		}
		@include mediaMax($md) {
			padding: 0px 40px 0px 0px;
		}
	}
}
.filter {
	position: relative;
	width: 100%;
	&__title {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-white;
		margin: 0px 0px 8px 0px;
		user-select: none;
		@include mediaMax($sm) {
			font-size: 12px;
			line-height: 16px;
		}
	}

	&__list {
		@include list-reset();
		flex-wrap: wrap;
		margin: 0px -8px;
		display: flex;
		@include mediaMax($lg) {
			margin: 0px -4px;
		}
	}

	&__item {
		padding: 0px 4px;
		margin: 0px 0px 8px 0px;
		input[type="checkbox"]:checked ~ .filter__label {
			background: $color-grey-12;
			color: $color-grey-2;
		}
	}

	&__input {
		display: none;
	}

	&__label {
		display: block;
		padding: 8px;
		background-color: $color-grey-9;
		cursor: pointer;
		font-weight: 600;
		font-size: 12px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		transition: all 0.5s ease;
		position: relative;
		color: $color-grey-11;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		@include mediaMin($sm) {
			&:hover {
				background-color: $color-grey-2;
				color: $color-white;
			}
			&:active {
				background-color: $color-white;
				color: $color-grey-dark;
			}
		}
		@include mediaMax($sm) {
			font-size: 10px;
			padding: 4px 8px;
		}
	}

	&__platform {
		margin: 0px 8px 8px 8px;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		@include mediaMax($lg) {
			margin: 0px 4px 8px 4px;
		}
		label {
			display: block;
			cursor: pointer;
			position: relative;
		}
		.platform-icon {
			transition: all 0.5s ease;
		}
		.icon-platform {
			width: 32px;
			height: 32px;
			@include mediaMin($sm) {
				&:hover {
					.platform-icon {
						opacity: 0;
						visibility: hidden;
					}
					.platform-icon-hover {
						opacity: 1;
						visibility: visible;
					}
				}
				&:active {
					.platform-icon {
						opacity: 0;
						visibility: hidden;
					}
					.platform-icon-hover {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
		.platform-icon-hover {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
			transition: all 0.5s ease;
		}
		input[type="checkbox"]:checked {
			~ .icon-platform > .platform-icon-hover {
				opacity: 1;
				visibility: visible;
			}
			~ .icon-platform > .platform-icon {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}

.our-games {
	&__list {
	}

	&__row {
		@include list-reset();
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-auto-rows: 230px;
		gap: 40px;
		margin: 0px 0px 40px 0px;
		display: -ms-grid;
		-ms-grid-columns: 1fr 40px 1fr 40px 1fr 40px 1fr;
		-ms-grid-rows: 230px 40px 230px;
		&:nth-child(even) {
			-ms-grid-rows: 230px;
			.our-games__card {
				&:nth-child(1) {
					-ms-grid-column: 1;
					-ms-grid-row: 1;
				}
				&:nth-child(2) {
					-ms-grid-column: 3;
					-ms-grid-row: 1;
				}
				&:nth-child(3) {
					-ms-grid-column: 5;
					-ms-grid-row: 1;
				}
				&:nth-child(4) {
					-ms-grid-column: 7;
					-ms-grid-row: 1;
				}
			}
		}
		@include mediaMax($lg) {
			grid-auto-rows: 200px;
			gap: 32px;
			-ms-grid-columns: 1fr 32px 1fr 32px 1fr 32px 1fr;
			-ms-grid-rows: 200px 32px 200px;
			margin: 0px 0px 32px 0px;
			&:nth-child(even) {
				-ms-grid-rows: 200px;
			}
		}
		@include mediaMax($md) {
			grid-template-columns: repeat(2, 1fr);
			grid-auto-rows: auto;
			&:nth-child(odd) {
				.our-games__card {
					&:last-child {
						grid-column-end: span 2;
						grid-row-end: span 2;
						height: 54vw;
						@include mediaMax(850px) {
							height: 50vw;
						}
						@include mediaMax($sm) {
							height: 56vw;
						}
						.image-crop__img {
							@include mediaMax($md) {
								width: 100%;
								transform: rotate(45deg) translate(-19vw, -22vw);
								img {
									transform: rotate(-45deg) translate(-21vw, 21vw);
								}
							}
							@include mediaMax(850px) {
								transform: rotate(45deg) translate(-17vw, -18vw);
								img {
									transform: rotate(-45deg) translate(-19vw, 18vw);
								}
							}
							@include mediaMax($sm) {
								transform: rotate(45deg) translate(-19vw, -18vw);
								img {
									transform: rotate(-45deg) translate(-19vw, 18vw);
								}
							}
						}
						.image-crop__title,
						.our-games__btn > .btn-inner {
							font-size: 32px;
							line-height: 40px;
						}
						.our-games__btn {
							padding: 20px;
						}
						.image-crop__icons > li {
							margin: 0px 20px 0px 0px;
						}
						.icon-platform {
							width: 32px;
							height: 32px;
						}
						.image-crop__title_hv {
							margin: 0px 0px 20px 0px;
						}
						@include mediaMax(550px) {
							.image-crop__title,
							.our-games__btn > .btn-inner {
								font-size: 16px;
								line-height: 16px;
							}
							.our-games__btn {
								padding: 12px;
							}
							.image-crop__icons > li {
								margin: 0px 12px 0px 0px;
							}
							.icon-platform {
								width: 24px;
								height: 24px;
							}
							.image-crop__title_hv {
								margin: 0px 0px 16px 0px;
							}
						}
					}
				}
			}
		}
		@include mediaMax(600px) {
			gap: 8px;
			margin: 0px 0px 8px 0px;
		}
	}

	&__card {
		@include mediaMax($md) {
			height: 27vw;
		}
		@include mediaMax($sm) {
			height: 38vw;
		}
		@include mediaMax(550px) {
			.image-crop__content {
				padding: 10px;
			}
			.image-crop__title,
			.our-games__btn > .btn-inner {
				font-size: 16px;
				line-height: 18px;
			}
			.our-games__btn {
				padding: 10px;
			}
			.image-crop__icons > li {
				margin: 0px 8px 0px 0px;
			}
			.icon-platform {
				width: 20px;
				height: 20px;
			}
			.image-crop__title_hv {
				margin: 0px 0px 8px 0px;
			}
		}
		@include mediaMax(450px) {
			.image-crop__info {
				padding: 6px;
			}
			.image-crop__content {
				padding: 5px;
			}
			.image-crop__title,
			.our-games__btn > .btn-inner {
				font-size: 12px;
				line-height: 16px;
			}
			.our-games__btn {
				padding: 5px;
			}
			.image-crop__icons > li {
				margin: 0px 5px 0px 0px;
			}
			.icon-platform {
				width: 16px;
				height: 16px;
			}
			.image-crop__title_hv {
				margin: 0px 0px 8px 0px;
			}
		}
	}

	&__image-crop {
		.image-crop__img {
			transform: rotate(45deg) translate(-81px, -42px);
			img {
				transform: rotate(-45deg) translate(-53px, 55px);
			}
			@include mediaMax($lg) {
				transform: rotate(45deg) translate(-71px, -42px);
				img {
					transform: rotate(-45deg) translate(-49px, 54px);
				}
			}
			@include mediaMax($md) {
				transform: rotate(45deg) translate(-9.5vw, -11vw);
				img {
					transform: rotate(-45deg) translate(-10.5vw, 11vw);
				}
			}
			@include mediaMax($sm) {
				transform: rotate(45deg) translate(-13.5vw, -11vw);
				img {
					transform: rotate(-45deg) translate(-11.5vw, 12.3vw);
				}
			}
		}
	}

	&__image-crop-wrapper {
	}

	&__info {
	}

	&__image-title {
	}

	&__icons {
	}

	&__btn {
	}

	&__more {
		margin: 60px 0px 0px 0px;
		@include selectNone();
		@include mediaMax($sm) {
			margin: 36px 0px 0px 0px;
		}
	}

	&__more-btn {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
	}

	&__more-btn-inner {
		text-align: center;
		margin: 0px 0px 15px 0px;
		user-select: none;
		@include mediaMax($sm) {
			margin: 0px 0px 10px 0px;
		}
	}

	&__more-btn-icon {
		transform: rotate(90deg);
		margin-left: -10px !important;
	}
}

@include mediaMin($md) {
	.card-right {
		.our-games__card {
			&:nth-child(3) {
				grid-column-end: span 2;
				grid-row-end: span 2;
				.image-crop__info {
					padding: 35px 35px 20px;
				}
				.image-crop__img {
					width: 110%;
					transform: rotate(45deg) translate(-200px, -78px);
					img {
						transform: rotate(-45deg) translate(-123px, 123px);
					}
					@include mediaMax($lg) {
						width: 100%;
						transform: rotate(45deg) translate(-155px, -170px);
						img {
							transform: rotate(-45deg) translate(-164px, 167px);
						}
					}
					@include mediaMax(1280px) {
						width: 50vw;
						transform: rotate(45deg) translate(-175px, -150px);
						img {
							transform: rotate(-45deg) translate(-147px, 167px);
						}
					}
				}
				.image-crop__title,
				.our-games__btn > .btn-inner {
					font-size: 32px;
					line-height: 40px;
				}
				.our-games__btn {
					padding: 20px;
				}
				.image-crop__icons > li {
					margin: 0px 20px 0px 0px;
				}
				.icon-platform {
					width: 32px;
					height: 32px;
				}
				.image-crop__title_hv {
					margin: 0px 0px 20px 0px;
				}
			}
		}
	}
	.card-center {
		.our-games__card {
			&:nth-child(2) {
				grid-column-end: span 2;
				grid-row-end: span 2;
				.image-crop__info {
					padding: 35px 35px 20px;
				}
				.image-crop__img {
					width: 110%;
					transform: rotate(45deg) translate(-200px, -78px);
					img {
						transform: rotate(-45deg) translate(-123px, 123px);
					}
					@include mediaMax($lg) {
						width: 100%;
						transform: rotate(45deg) translate(-155px, -170px);
						img {
							transform: rotate(-45deg) translate(-164px, 167px);
						}
					}
					@include mediaMax(1280px) {
						width: 50vw;
						transform: rotate(45deg) translate(-175px, -150px);
						img {
							transform: rotate(-45deg) translate(-147px, 167px);
						}
					}
				}
				.image-crop__title,
				.our-games__btn > .btn-inner {
					font-size: 32px;
					line-height: 40px;
				}
				.our-games__btn {
					padding: 20px;
				}
				.image-crop__icons > li {
					margin: 0px 20px 0px 0px;
				}
				.icon-platform {
					width: 32px;
					height: 32px;
				}
				.image-crop__title_hv {
					margin: 0px 0px 20px 0px;
				}
			}
		}
	}
	.card-left {
		.our-games__card {
			&:nth-child(1) {
				grid-column-end: span 2;
				grid-row-end: span 2;
				.image-crop__info {
					padding: 35px 35px 20px;
				}
				.image-crop__img {
					width: 110%;
					transform: rotate(45deg) translate(-200px, -78px);
					img {
						transform: rotate(-45deg) translate(-123px, 123px);
					}
					@include mediaMax($lg) {
						width: 100%;
						transform: rotate(45deg) translate(-155px, -170px);
						img {
							transform: rotate(-45deg) translate(-164px, 167px);
						}
					}
					@include mediaMax(1280px) {
						width: 50vw;
						transform: rotate(45deg) translate(-175px, -150px);
						img {
							transform: rotate(-45deg) translate(-147px, 167px);
						}
					}
				}
				.image-crop__title,
				.our-games__btn > .btn-inner {
					font-size: 32px;
					line-height: 40px;
				}
				.our-games__btn {
					padding: 20px;
				}
				.image-crop__icons > li {
					margin: 0px 20px 0px 0px;
				}
				.icon-platform {
					width: 32px;
					height: 32px;
				}
				.image-crop__title_hv {
					margin: 0px 0px 20px 0px;
				}
			}
		}
	}
}
.card-right {
	.our-games__card {
		&:nth-child(1) {
			-ms-grid-column: 1;
			-ms-grid-row: 1;
		}
		&:nth-child(2) {
			-ms-grid-column: 3;
			-ms-grid-row: 1;
		}
		&:nth-child(3) {
			-ms-grid-column: 5;
			-ms-grid-row: 1;
			-ms-grid-column-span: 3;
			-ms-grid-row-span: 3;
		}
		&:nth-child(4) {
			-ms-grid-column: 1;
			-ms-grid-row: 3;
		}
		&:nth-child(5) {
			-ms-grid-column: 3;
			-ms-grid-row: 3;
		}
	}
}
.card-center {
	.our-games__card {
		&:nth-child(1) {
			-ms-grid-column: 1;
			-ms-grid-row: 1;
		}
		&:nth-child(2) {
			-ms-grid-column: 3;
			-ms-grid-row: 1;
			-ms-grid-column-span: 3;
			-ms-grid-row-span: 3;
		}
		&:nth-child(3) {
			-ms-grid-column: 7;
			-ms-grid-row: 1;
		}
		&:nth-child(4) {
			-ms-grid-column: 1;
			-ms-grid-row: 3;
		}
		&:nth-child(5) {
			-ms-grid-column: 7;
			-ms-grid-row: 3;
		}
	}
}
.card-left {
	.our-games__card {
		&:nth-child(1) {
			-ms-grid-column: 1;
			-ms-grid-row: 1;
			-ms-grid-column-span: 3;
			-ms-grid-row-span: 3;
		}
		&:nth-child(2) {
			-ms-grid-column: 5;
			-ms-grid-row: 1;
		}
		&:nth-child(3) {
			-ms-grid-column: 7;
			-ms-grid-row: 1;
		}
		&:nth-child(4) {
			-ms-grid-column: 5;
			-ms-grid-row: 3;
		}
		&:nth-child(5) {
			-ms-grid-column: 7;
			-ms-grid-row: 3;
		}
	}
}
