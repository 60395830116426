.game-detail-description {
	padding: 116px 0px 27px 0px;
	@include mediaMax($lg) {
		padding: 56px 0px 27px 0px;
	}
	@include mediaMax($md) {
		padding: 60px 0px 27px 0px;
	}
	@include mediaMax($sm) {
		padding: 24px 0px 31px 0px;
	}
	&__container {
	}

	&__info {
		max-width: 920px;
		margin: 0 auto;
	}

	&__icons-platforms {
		.icon-platform {
			width: 32px;
			height: 32px;
		}
		li {
			margin: 0px 16px 0px 0px;
			&:last-child {
				margin: 0px 0px 0px 0px;
			}
		}
	}

	&__top {
		display: flex;
		justify-content: space-between;
		margin: 0px 0px 16px 0px;
		@include mediaMax($sm) {
			display: block;
			margin: 0px 0px 24px 0px;
		}
	}

	&__title {
		max-width: 439px;
		margin: 0px 0px 16px 0px;
	}

	&__text {
		font-size: 20px;
		line-height: 28px;
		letter-spacing: $ls;
		color: $color-grey-6;
		margin: 0px 0px 136px 0px;
		position: relative;
		z-index: 2;
		p {
			margin: 0;
		}
		@include mediaMax($lg) {
			margin: 0px 0px 80px 0px;
		}
		@include mediaMax($md) {
			margin: 0px 0px 60px 0px;
		}
		@include mediaMax($sm) {
			font-size: 12px;
			line-height: 16px;
			margin: 0px 0px 34px 0px;
		}
	}

	&__cards {
		@include list-reset();
		display: flex;
		margin: 0px 0px 128px 0px;
	}

	&__card {
	}

	&__video {
	}

	&__image {
	}

	&__content {
		max-width: 920px;
		margin: 0 auto 141px auto;
		ul > li {
			color: $color-grey-6;
			margin: 0;
			line-height: 28px;
			&:before {
				background-color: $color-grey-6;
			}
		}
		span {
			margin: 0px 0px 8px 0px;
		}
		@include mediaMax($lg) {
			ul {
				margin: 0px 0px 30px 0px;
			}
		}
		@include mediaMax($md) {
			max-width: none;
			margin: 0 0 81px 0;
			ul > li {
				line-height: 20px;
			}
			ul {
				margin: 0px 0px 25px 0px;
			}
		}
	}

	&__btn {
		display: flex;
		justify-content: center;
	}
}

.game-detail-description {
	&__cards {
		display: flex;
		flex-wrap: wrap;
		margin: 0px -20px 88px -20px;
		@include mediaMax($lg) {
			margin: 0px -12px 76px -12px;
		}
		@include mediaMax($sm) {
			margin: 0px -4px 24px -4px;
		}
	}

	&__card {
		padding: 0 20px;
		margin: 0px 0px 40px 0px;
		position: relative;
		user-select: none;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&_video {
			span {
				position: absolute;
				width: 120px;
				height: 120px;
				z-index: 2;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background: $color-grey-15;
				border-radius: 50%;

				&:before {
					content: "";
					position: absolute;
					z-index: 2;
					top: 50%;
					left: 50%;
					width: 43px;
					height: 49px;
					transform: translate(-35%, -50%);
					background: url("./..//img/sprite/video-play.svg") 0 0 no-repeat;
				}
				&:after {
					content: "";
					position: absolute;
					z-index: 2;
					top: 50%;
					left: 50%;
					width: 66px;
					height: 77px;
					transform: translate(-35%, -50%);
					background: url("./../img/sprite/video-play-frame.svg") 0 0 no-repeat;
				}
				@include mediaMax($md) {
					width: 80px;
					height: 80px;
					&:before,
					&:after {
						width: 38px;
						height: 38px;
					}
				}
			}
		}
		@include mediaMax($lg) {
			padding: 0px 12px;
			margin: 0px 0px 24px 0px;
		}
		@include mediaMax($md) {
			margin: 0px 0px 16px 0px;
		}
		@include mediaMax($sm) {
			flex: 0 1 50% !important;
			max-height: 240px !important;
			min-height: 89px !important;
			margin: 0px 0px 8px 0px;
			&:first-child {
				flex: 1 1 100% !important;
				max-height: 360px !important;
				min-height: 161px !important;
				&:before {
					width: 95.5%;
					height: 95.5%;
				}
			}
			padding: 0px 4px;
		}
		&:before {
			content: "";
			position: absolute;
			z-index: 2;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include border-white();
		}
		&:first-child {
			&:after {
				content: "";
				position: absolute;
				background-color: $color-grey-dark;
				width: 110px;
				height: 110px;
				top: -112px;
				left: -97px;
				transform: translate(50%, 50%) rotate(45deg);
				@include mediaMax($md) {
					width: 100px;
					height: 100px;
					top: -100px;
					left: -100px;
				}
				@include mediaMax(400px) {
					width: 50px;
					height: 50px;
					top: -50px;
					left: -50px;
				}
			}
		}
	}
}

.card-lg {
	width: 50%;
	height: 360px;
	&:before {
		width: calc(calc(664 / 680) * 100% - 40px);
		height: calc(346 / 360) * 100%;
		@include mediaMax($lg) {
			width: calc(calc(444 / 460) * 100% - 24px);
			height: calc(224 / 240) * 100%;
		}
		@include mediaMax($md) {
			width: calc(calc(324 / 332) * 100% - 24px);
			height: calc(178 / 186) * 100%;
		}
		@include mediaMax($sm) {
			width: 92.5%;
			height: 93.5%;
		}
	}
	@include mediaMax($lg) {
		height: auto;
		max-height: 360px;
		min-height: 240px;
	}
	@include mediaMax($sm) {
		max-height: 240px;
		min-height: 176px;
	}
}
.card-sm {
	width: 33.333%;
	height: 240px;
	&:before {
		width: calc(calc(424 / 440) * 100% - 40px);
		height: calc(224 / 240) * 100%;
		@include mediaMax($lg) {
			width: calc(calc(284 / 300) * 100% - 24px);
			height: calc(164 / 180) * 100%;
		}
		@include mediaMax($md) {
			width: calc(calc(205 / 213) * 100% - 24px);
			height: calc(112 / 120) * 100%;
		}
		@include mediaMax($sm) {
			width: 92.5%;
			height: 93.5%;
		}
	}
	&:last-child {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			width: 90px;
			height: 90px;
			background-color: $color-grey-dark;
			bottom: 0;
			right: 0;
			transform: translate(50%, 50%) rotate(45deg);
			@include mediaMax($md) {
				width: 50px;
				height: 50px;
			}
		}
	}
	@include mediaMax($lg) {
		height: auto;
		max-height: 240px;
		min-height: 180px;
	}
	@include mediaMax($sm) {
		max-height: 180px;
		min-height: 116px;
	}
}

.fancybox-slide {
	video,
	img {
		@include mediaMax($sm) {
			width: 100%;
			height: auto;
		}
	}
}

// .fancybox-image-wrap {
// 	display: flex !important;
// 	justify-content: center !important;
// 	img {
// 		object-fit: cover !important;
// 	}
// }
