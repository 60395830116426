.game-detail-main {
	position: relative;
	&__container {
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 515px;
		overflow: hidden;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
		}
		@include mediaMax($lg) {
			height: 36vw;
		}
	}

	&__bg-wrapper {
		position: absolute;
		top: 0;
		left: -7.5%;
		width: 115%;
		height: 110%;
		background-size: cover;
		filter: blur(24px);
	}

	&__logo {
		position: absolute;
		top: 74px;
		left: 50%;
		transform: translate(-50%, 0);
		@include mediaMax($lg) {
			max-width: 300px;
		}
		@include mediaMax($md) {
			display: none;
		}
	}

	&__frame {
		top: 1%;
		left: 50%;
		transform: translate(-50%, 0);
		width: 99%;
		height: 90%;
		.frame-border__corner {
			height: 208px;
		}
		.frame-border__border {
			height: calc(100% - 104.25px);
			bottom: 104.25px;
		}
		@include mediaMax($lg) {
			top: 5.8%;
			height: 88%;
			width: 99%;
			@include border-white();
			.frame-border__corner,
			.frame-border__border {
				display: none;
			}
		}
		@include mediaMax($md) {
			top: 1.2%;
			height: 92%;
			width: 98%;
			left: 50.2%;
		}
		@include mediaMax($sm) {
			top: 1%;
			height: 98%;
			width: 98.2%;
			left: 50%;
		}
	}

	&__image-crop {
		position: relative;
		z-index: 2;
		padding: 37px 0px 0px 0px;
		@include mediaMax($lg) {
			padding: 20px 0px 0px 0px;
		}
		@include mediaMax($sm) {
			padding: 5px 0px 0px 0px;
		}
	}

	&__image-crop-wrapper {
		height: 602px;
		@include mediaMax($lg) {
			// height: 424px;
			height: 42vw;
		}
		@include mediaMax($md) {
			height: 39vw;
		}
		@include mediaMax($sm) {
			height: 55vw;
		}
		.image-crop__img {
			width: 100%;
			transform: rotate(-45deg) translate(220px, -231px);
			img {
				height: 47%;
				transform: rotate(45deg) translate(234px, 213px);
			}
			@include mediaMax($lg) {
				transform: rotate(-45deg) translate(15vw, -15vw);
				img {
					max-height: none;
					height: 45%;
					transform: rotate(45deg) translate(16.5vw, 16.5vw);
				}
			}
			@include mediaMax($md) {
				transform: rotate(-45deg) translate(15vw, -14vw);
				img {
					height: 50%;
					transform: rotate(45deg) translate(13.5vw, 12.5vw);
				}
			}
			@include mediaMax($sm) {
				transform: rotate(45deg) translate(-19vw, -18vw);
				img {
					transform: rotate(-45deg) translate(-18.7vw, 18vw);
				}
			}
		}
	}
}

.image-crop {
	&__wrapper {
	}

	&__img-block {
	}

	&__img {
	}
}
