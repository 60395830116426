.games-main {
	background-color: transparent;
	padding: 120px 0px 199px 0px;

	@include mediaMax($lg) {
		padding: 60px 0 199px 0;
	}

	@include mediaMax($md) {
		padding: 40px 0 107px 0;
	}

	@include mediaMax($sm) {
		padding: 80px 0 32px 0;
	}

	&__body {
		margin: 0 0 31px 0;
	}

	&__title {
		margin: 0 0 16px 0;
	}

	&__text {
		margin: 0 0 95px 0;
		@include mediaMax($lg) {
			margin-bottom: 60px;
		}
		@include mediaMax($md) {
			margin-bottom: 52px;
		}
		@include mediaMax($sm) {
			margin-bottom: 32px;
		}
	}
}
.games-slider {
	&__image-crop-wrapper {
		height: 320px;
		> .image-crop__img {
			top: -29px;
			left: -33px;
			img {
				margin-top: 115px;
			}
		}
	}

	&__btn {
		position: absolute;
		bottom: 0;
		left: 0;
		@include mediaMax($md) {
			position: static;
		}
	}

	&__pagination {
		padding: 0 130px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include mediaMax($md) {
			display: none;
		}
	}

	&__arrow {
		display: flex;
		outline: none;
		&_left {
		}

		&_right {
			transform: rotate(-180deg);
		}
	}

	&__dots {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0px 16px;
		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			border: 2px solid $color-border-light;
			background-color: transparent;
			border-radius: 50%;
			margin: 0px 24px 0px 0px;
			cursor: pointer;
			outline: none;
			&:last-child {
				margin: 0px 0px 0px 0px;
			}
		}
		.swiper-pagination-bullet-active {
			width: 10px;
			height: 10px;
			background-color: $color-white;
		}
	}
}

.games-slider {
	position: relative;
}

.image-crop {
	&__wrapper {
	}

	&__content {
	}

	&__title {
		&_front {
		}

		&_hv {
		}
	}

	&__img {
	}

	&__info {
	}

	&__icons {
	}

	&__icon {
	}

	&__btn {
	}
}
