// Reset
@mixin list-reset() {
	list-style: none;
	padding: 0;
	margin: 0;
}

@mixin heading-reset() {
	margin: 0;
	font-size: 0;
}

@mixin margin-padding-reset() {
	padding: 0;
	margin: 0;
}

@mixin button-reset() {
	background-color: transparent;
	border: 0;
	padding: 0;
}

@mixin selectNone() {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Buttons
@mixin btn-inner() {
	font-weight: 600;
	letter-spacing: 0.04em;
	transition: all 0.5s ease;
}

@mixin border-white() {
	border: 1.5px solid $color-border-light;
}

@mixin border-grey() {
	border: 1.5px solid $color-grey-5;
}

@mixin border-red() {
	border: 1.5px solid $color-red-1;
}

@mixin btn-icon-arrow() {
	.btn-icon-arrow {
		position: absolute;
		left: 100%;
		top: 50%;
		width: auto;
		height: calc(100% + 1.5px);
		transform: translateY(-50%);
		transition: fill 0.5s ease;
	}
}

@mixin btn-red() {
	transition: all 0.5s ease;
	&:hover {
		background-color: $color-red-2;
		border-color: $color-red-2;
	}
	&:active {
		background-color: $color-red-3;
		border-color: $color-red-3;
	}
}

@mixin btn-white() {
	transition: all 0.5s ease;
	&:hover {
		background-color: $color-btn-white-hover;
	}
	&:active {
		background-color: $color-btn-white-press;
	}
}

@mixin btn-white-rect() {
	overflow: hidden;
	&:after {
		box-shadow: 0 0 0 1900px $color-white;
		transform: rotate(45deg);
		transition: all 0.3s ease;
	}
	&:hover {
		&:after {
			box-shadow: 0 0 0 1900px $color-btn-white-hover;
		}
	}
	&:active {
		&:after {
			box-shadow: 0 0 0 1900px $color-btn-white-press;
		}
	}
}
@mixin box-shadow-red() {
	box-shadow: 0px 38px 80px rgba(255, 59, 0, 0.24), 0px 15.8755px 33.4221px rgba(255, 59, 0, 0.172525),
		0px 8.4878px 17.869px rgba(255, 59, 0, 0.143066), 0px 4.75819px 10.0172px rgba(255, 59, 0, 0.12),
		0px 2.52704px 5.32008px rgba(255, 59, 0, 0.0969343), 0px 1.05156px 2.21381px rgba(255, 59, 0, 0.0674749);

	&:hover {
		filter: none;
	}
	&:active {
		filter: none;
	}
}

@mixin box-shadow-white() {
	box-shadow: 0px 15.8755px 33.4221px rgba(255, 255, 255, 0.172525),
		0px 8.4878px 17.869px rgba(255, 255, 255, 0.143066), 0px 4.75819px 10.0172px rgba(255, 255, 255, 0.12),
		0px 2.52704px 5.32008px rgba(255, 255, 255, 0.0969343), 0px 1.05156px 2.21381px rgba(255, 255, 255, 0.0674749);
	&:hover {
		box-shadow: none;
	}
	&:active {
		box-shadow: none;
	}
}

// Media querries
@mixin mediaMax($mediaWidth) {
	@media only screen and (max-width: $mediaWidth - 1) {
		@content;
	}
}

@mixin mediaMin($mediaWidth) {
	@media only screen and (min-width: $mediaWidth) {
		@content;
	}
}

@mixin mediaDistance($mediaWidthMin, $mediaWidthMax) {
	@media only screen and (min-width: $mediaWidthMin) and (max-width: $mediaWidthMax - 1) {
		@content;
	}
}
