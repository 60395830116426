@use "sass:math";

.our-games-main {
	padding: 80px 0px 116px 0px;
	background-color: $color-grey;
	@include mediaMax($lg) {
		padding: 60px 0px 52px 0px;
		background-color: $color-grey-dark;
	}
	@include mediaMax($sm) {
		padding: 25px 0 37px;
	}
	&__container {
		@include mediaMax($sm) {
			padding: 0 !important;
		}
	}

	&__title {
		margin: 0px 0px 35px 0px;
		@include mediaMax($lg) {
			margin: 0px 0px 32px 0px;
		}
		@include mediaMax($sm) {
			padding: 0px 16px;
			margin: 0 0 10px;
		}
	}

	&__slider {
		margin: 0px -1px 0px 0px;
	}

	&__body {
		margin: 0px 0px 24px 0px;
		// overflow: hidden;
	}

	&__slide {
		position: relative;
		opacity: 0;
		visibility: hidden;
		transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

		&.swiper-slide-active {
			opacity: 1;
			visibility: visible;
		}

		@include mediaMax($sm) {
			width: 100% !important;
		}
	}

	&__inner {
	}

	&__control {
		display: flex;
		justify-content: flex-end;
		&.disabled {
			display: none;
		}
		.currentNumber {
			font-weight: 600;
			font-size: 48px;
			line-height: 60px;
			letter-spacing: $ls;
			text-transform: uppercase;
			color: $color-white;
			@include mediaMax($sm) {
				font-size: 35.8919px;
				line-height: 45px;
			}
		}

		.slash {
			display: inline-block;
			width: 3px;
			height: 48px;
			transform: skew(-30deg);
			background-color: $color-border-light;
			margin: 0px 10px -10px 10px;
			@include mediaMax($sm) {
				height: 35px;
				transform: skew(-25deg);
				background-color: rgba(255, 255, 255, 0.4);
				margin: 0 7px -6px 7px;
			}
		}

		.totalNumber {
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: $ls;
			color: $color-border-light;
			@include mediaMax($sm) {
				font-size: 14.955px;
				line-height: 18px;
			}
		}
		@include mediaMax($sm) {
			justify-content: space-between;
			padding: 0px 16px;
		}
	}

	&__pagination {
		margin: 0px 25px 0px 0px;
		@include mediaMax($sm) {
			margin: 0px 0px 0px 0px;
		}
	}

	&__arrow {
		outline: none;
		@include mediaMax($sm) {
			.btn-slider-bg {
				width: 43.32px;
				height: 40.3px;
			}
			&:after {
				width: 43.32px;
				height: 40.3px;
			}
		}
		&_prev {
			transform: rotate(-180deg);
			&:after {
				top: 3px;
				left: 5px;
			}
			@include mediaMax($sm) {
				&:after {
					left: 3.38px;
					top: 2.03px;
				}
			}
		}

		&_next {
			margin: 0px 0px 0px 8px;
			&:after {
				top: -3px;
				left: 5px;
			}
			@include mediaMax($sm) {
				margin: 0px 0px 0px 0px;
				&:after {
					left: 3.38px;
					top: -2.03px;
				}
			}
		}
	}
}

.inner-game {
	position: relative;

	&__wrapper {
		position: relative;
		margin: 0px 1px 20px 0px;
		@include mediaMax($lg) {
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 2;
				width: calc(664 / 680) * 100%;
				height: math.div(384, 400) * 100%;
				transform: translate(-50%, -50%);
				border: 1.5px solid $color-border-light;
			}
		}
		@include mediaMax($sm) {
			padding: 0;
			margin: 0px 8px;
			&:after {
				display: none;
			}
		}
	}

	&__content {
		position: relative;
		z-index: 3;
		display: flex;
		justify-content: space-between;
		background-size: 98.85714% 96.8254%;
		padding: 226px 120px 40px 40px;
		@include mediaMax(1600px) {
			padding: calc(230 / 1600) * 100vw calc(100 / 1600) * 100vw calc(65 / 1600) * 100vw calc(65 / 1600) * 100vw;
			background: none;
		}
		@include mediaMax(1410px) {
			padding: calc(230 / 1600) * 100vw calc(65 / 1600) * 100vw calc(65 / 1600) * 100vw calc(65 / 1600) * 100vw;
		}
		@include mediaMax($md) {
			padding: 68px 40px 40px 40px;
		}
		@include mediaMax($sm) {
			flex-direction: column;
			padding: 165px 8px 23px 8px;
		}
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		clip-path: polygon(11% 0, 100% 0, 100% 69%, 89% 100%, 0 100%, 0 31%);
		img {
			width: 100%;
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 64.14%, rgba(0, 0, 0, 0.8) 100%);
		}
		@include mediaMax($lg) {
			clip-path: polygon(9% 0, 100% 0, 100% 81%, 92% 100%, 0 100%, 0 19%);
		}
		@include mediaMax($sm) {
			clip-path: none;
			height: calc(284 / 447) * 100%;
			width: calc(100% + 16px);
			left: -8px;
			&:after {
				transform: rotate(180deg);
				top: 100%;
			}
		}
		@include mediaMax(380px) {
			background-size: auto 79%;
			background-position: top center;
			&:after {
				top: 79%;
			}
		}
	}

	&__img-bg {
		position: absolute;
		right: -1px;
		top: 44px;
		z-index: -1;
		width: calc(100% + 9px);
		height: 100%;
		background-size: cover;
		filter: blur(64px);
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(22, 24, 37, 0) 35.85%, #161825 100%);
		}
		&:after {
			content: "";
		}
		@include mediaMax($lg) {
			display: none;
		}
	}

	&__frame {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: calc(1384 / 1400) * 100%;
		height: math.div(488, 504) * 100%;
		.frame-border__corner {
			height: 49%;
		}
		.frame-border__border {
			height: 75.5%;
			bottom: 24.5%;
		}
		@include mediaMax($lg) {
			display: none;
		}
		@include mediaMax($sm) {
			display: block;
			width: 100%;
			.frame-border__border {
				height: 91.5%;
				bottom: 8.5%;
			}
			.frame-border__corner {
				height: 17%;
			}
		}
	}
}

.img-game {
	&__info {
		max-width: 640px;
		@include mediaMax($md) {
			max-width: 412px;
		}
		@include mediaMax($sm) {
			max-width: none;
		}
	}

	&__title {
		margin: 0px 0px 14px 0px;
		@include mediaMax($md) {
			margin: 0px 0px 10px 0px;
		}
		@include mediaMax($md) {
			margin: 0px 0px 0px 0px;
		}
	}

	&__text {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: $ls;
		color: $color-grey-6;
		margin: 0;
		@include mediaMax($sm) {
			margin: 0px 0px 18px 0px;
			font-size: 12px;
			line-height: 16px;
		}
	}

	&__btn-block {
		display: flex;
		align-items: flex-end;
		@include mediaMax($sm) {
			justify-content: center;
		}
	}

	&__icons {
		margin: 0px 0px 13px 0px;
		li {
			margin: 0px 16px 0px 0px;
		}
		.icon-platform {
			width: 32px;
			height: 32px;
		}
	}

	&__btn {
	}
}
