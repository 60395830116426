.footer {
	padding: 40px 0px;
	border-top: 2.4px solid $color-grey-9;
	@include mediaMax($md) {
		padding: 25px 0px 28px 0px;
	}
	@include mediaMax($sm) {
		padding: 24px 0px 29px 0px;
	}
	&__container {
		display: flex;
		justify-content: space-between;
		@include mediaMax($md) {
			flex-direction: column;
		}
	}
	&__info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include mediaMax($md) {
			flex-direction: row;
			justify-content: space-between;
			padding: 0px 0px 24px 0px;
		}
		nav {
			padding: 0px 0px 23px 0px;
			@include mediaMax($lg) {
				padding: 0;
			}
		}
	}
	&__info,
	&__copyright {
		flex: 0 1 50%;
		@include mediaMax($lg) {
			flex: 0 1 33.333%;
		}
	}
	&__logo {
		flex: 0 0 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@include mediaMax($lg) {
			justify-content: space-between;
			flex: 0 1 33.333%;
			padding: 0px 0px 0px 0px;
		}
		@include mediaMax($md) {
			padding: 16px 0px 0px 0px;
			position: relative;
			&:before {
				content: "";
				width: 90%;
				height: 1px;
				background: $color-grey-9;
				position: absolute;
				top: 0;
				left: 5%;
			}
		}
		@include mediaMax($sm) {
			padding: 20px 0px 0px 0px;
		}
	}

	&__logo-img {
		margin: 0px 0 5px 18px;
		@include mediaMax($md) {
			margin: 0px 0px 7.6px 0px;
			svg {
				width: 98px;
				height: 52.5px;
			}
		}
		@include mediaMax($sm) {
			margin: 38px 0px 0px 0px;
		}
		&_pc {
			margin: 0px 0px 25px 0px;
			@include mediaMax($sm) {
				margin: 38px 0px 20px 0px;
			}
		}
	}

	&__copyright {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		@include mediaMax($lg) {
			padding: 5px 0px 0px 0px;
		}
	}

	&__social {
		margin: 0px 0px 41px 0px;
		@include mediaMax($lg) {
			margin: 0px;
		}
	}

	&__icons-social {
		&_pc {
			li {
				margin: 0px 11px 0px 0px;
			}
		}
		li {
			margin: 0px 20px 0px 0px;
			&:last-child {
				margin: 0px 0px 0px 0px;
			}
			@include mediaMax($lg) {
				margin: 0px 20px 0px 0px;
			}
		}
	}

	&__icons-social,
	&__icons-platforms {
		@include list-reset();
		display: flex;
	}

	&__icons-platforms {
		justify-content: flex-end;
		padding: 20px 0px 0px 0px;
		li {
			margin: 0px 16px 0px 0px;
			&:last-child {
				margin: 0px 0px 0px 0px;
				.footer__icon-social,
				.footer__icon-platforms {
					margin: 0px 0px 0px 0px;
				}
			}
		}
		@include mediaMax($xl) {
			padding: 0;
			margin: 0px 0px 32px 0px;
		}
		@include mediaMax($sm) {
			margin: 0px 0px 20px 0px;
		}
	}

	&__icon-social {
		display: block;
	}
	&__icon-platforms {
		display: block;
	}

	&__list {
		@include list-reset();
		display: flex;
		li {
			max-width: 140px;
			position: relative;
			padding: 0px 22px;
			&:after {
				content: "";
				position: absolute;
				background-color: $color-grey-9;
				height: 16px;
				width: 1px;
				top: 50%;
				right: 0;
				transform: translate(0%, -50%);
			}
			&:first-child {
				padding: 0px 26px 0px 0px;
			}
			&:last-child {
				padding: 0px 0px 0px 35px;
				&:after {
					width: 0;
					height: 0;
				}
			}
		}
		@include mediaMax($lg) {
			flex-direction: column;
			li {
				padding: 0px;
				max-width: none;
				margin: 0px 0px 16px 0px;
				&:after {
					content: "";
					display: none;
				}
				&:first-child {
					padding: 0px;
				}
				&:last-child {
					padding: 0px;
					margin: 0px 0px 0px 0px;
				}
			}
		}
	}

	&__icons-social.social-tg img.icon-tg {
		fill-opacity: 1;
	}

	&__link {
		display: block;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: ls;
		color: $color-white;
		opacity: 0.7;
		text-decoration: none;
		user-select: none;
	}

	&__icon-steam {
	}

	&__copyright-text {
		font-size: 12px;
		line-height: 16px;
		letter-spacing: $ls;
		color: $color-white;
		opacity: 0.7;
		margin: 0;
		text-align: center;
		padding: 40px 0px 0px 0px;
		user-select: none;
		@include mediaMax($xl) {
			padding: 0px 0px 0px 0px;
		}
	}
}

.social {
	&__label {
		font-size: 12px;
		line-height: calc(16 / 12) * 1;
		letter-spacing: $ls;
		color: $color-white;
		margin: 0px 0px 8px 0px;
		user-select: none;
		@include mediaMax($lg) {
			text-align: right;
		}
		@include mediaMax($sm) {
			text-align: center;
		}
	}

	&__icons {
	}
}
