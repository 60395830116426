.another-games {
	padding: 120px 0px 64px 0px;
	@include mediaMax($lg) {
		padding: 80px 0px 60px 0px;
	}
	@include mediaMax($md) {
		padding: 60px 0px 80px 0px;
	}
	@include mediaMax($md) {
		padding: 24px 0px 46px 0px;
	}
	&__container {
	}

	&__titile {
		margin: 0px 0px 40px 0px;
		@include mediaMax($lg) {
			margin: 0px 0px 24px 0px;
		}
	}

	&__slider {
	}

	&__body {
		overflow: hidden;
	}

	&__slide {
	}

	&__image-crop-wrapper {
		height: 312px;
		@include mediaMax($lg) {
			height: 24vw;
			max-height: 312px;
		}
		@include mediaMax($md) {
			height: 23vw;
			max-height: 250px;
		}
		@include mediaMax(767px) {
			height: 200px;
			width: 215px;
		}
		@include mediaMax(600px) {
			height: 156px;
			width: 180px;
			.image-crop__title_hv {
				font-size: 16px;
				line-height: 18px;
			}
		}
	}

	&__img-block {
		.image-crop__img {
			transform: rotate(45deg) translate(-110px, -100px);
			img {
				transform: rotate(-45deg) translate(-105px, 105px);
			}
			@include mediaMax(1320px) {
				transform: rotate(45deg) translate(-8.5vw, -8.5vw);
				img {
					transform: rotate(-45deg) translate(-8.5vw, 8.5vw);
				}
			}
			@include mediaMax($md) {
				transform: rotate(0deg);
				img {
					transform: rotate(0deg);
					clip-path: polygon(15% 0, 100% 0, 100% 80%, 85% 100%, 0 100%, 0 20%);
				}
			}
		}
	}

	&__info {
		@include mediaMax(900px) {
			padding: 10px;
		}
	}

	&__image-title {
	}

	&__icons {
		li {
			@include mediaMax(900px) {
				margin: 0px 12px 0px 0px;
				.icon-platform {
					width: 20px;
					height: 20px;
				}
			}
			@include mediaMax(600px) {
				margin: 0px 7px 0px 0px;
			}
		}
	}

	&__btn {
		@include mediaMax($sm) {
			padding: 10px 0px;
			.btn-inner {
				font-size: 14px;
			}
		}
	}
}
