.main-section {
	position: relative;
	background: linear-gradient(180deg, rgba(47, 49, 63, 0.2) 78.76%, rgba(#000000, 0.2) 100%);

	@include mediaMax($sm) {
		position: relative;
		background: none;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background: linear-gradient(
				180deg,
				#161825 0%,
				rgba(22, 24, 37, 0) 29.87%,
				rgba(22, 24, 37, 0) 68.18%,
				#161825 88.94%
			);
		}
	}
	&__container {
		display: flex;
		justify-content: space-between;
	}

	&__body {
		padding: 200px 0px 202px 0px;
		position: relative;
		width: 100%;
		@include mediaMax($lg) {
			padding: calc(200 / 1600) * 100vw 0px calc(182 / 1600) * 100vw 0px;
		}
		@include mediaMax($md) {
			padding: calc(107 / 1024) * 100vw 0px calc(95 / 1024) * 100vw 0px;
		}
		@include mediaMax($sm) {
			padding: 35px 0px 9px 0px;
			// padding: calc(85 / 767) * 100vw 0px 9px 0px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		z-index: 2;
	}

	&__logo {
		@include heading-reset();
		margin: 0px 0px 10px 0px;
	}

	&__text {
		font-size: 32px;
		line-height: calc(40/32) * 1;
		letter-spacing: $ls;
		color: $color-grey-6;
		margin: 0px 0px 127px 0px;
		max-width: 441px;
		@include mediaMax($lg) {
			font-size: 24px;
			line-height: calc(32 / 24) * 1;
			margin: 0px 0px calc(113 / 1600) * 100vw 0px;
		}
		@include mediaMax($md) {
			font-size: 20px;
			line-height: calc(28 / 20) * 1;
			margin: 0px 0px calc(47 / 1024) * 100vw 0px;
		}
		@include mediaMax($sm) {
			// font-size: calc(40 / 768) * 100vw;
			// line-height: calc(40 / 768) * 100vw;
			font-size: 16px;
			line-height: 1;
			margin: 0px 0px 340px 0px;
			max-width: calc(450 / 768) * 100vw;
		}
	}

	&__btn {
		display: inline-flex;
		@include mediaMax($sm) {
			width: 284px;
			.btn__arrow {
				position: absolute;
				top: 0;
				right: 3px;
			}
			.btn-arrow {
				width: 96%;
			}
			.btn-inner {
				display: flex;
				justify-content: center;
				border-color: $color-red-1;
				background-color: $color-red-1;
				min-height: 52px;
			}
			.btn__arrow-fill {
				fill: $color-red-1;
			}
			.btn__arrow-stroke {
				stroke: $color-red-1;
			}
		}
	}

	&__bg {
		@include mediaMin($sm) {
			display: flex;
		}
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		justify-content: flex-end;
		display: none;

		img {
			height: 100%;
			width: auto;
			object-fit: contain;
			object-position: top right;
		}
		&_mobile {
			display: none;
			@include mediaMax($sm) {
				display: flex;
			}
		}
	}
}
